import Select from 'react-select'

export default function MerchantInfoSection({
  accountTypes,
  productTypes,
  businessLines,
  accountInfo,
  onAccountTypeChange,
  onBusinessLineChange,
  onProductTypeChange,
  onUpdateAccountInfo,
  currencyTypes,
  onSettlementCurrencyChange,
  isEdit,
}) {
  function findSelectedEnum(selectedField, allEnums) {
    return allEnums.find(({ value }) => value === selectedField)
  }

  const isBrazilian = accountInfo.address?.countryCode === '076'

  return (
    <>
      <tr>
        <td colSpan="2">
          <h4>Merchant info:</h4>
        </td>
      </tr>
      <tr>
        <td>Account Type</td>
        <td>
          <Select
            name="accountType"
            id="accountType"
            required
            className="select-register-input"
            onChange={onAccountTypeChange}
            options={accountTypes}
            placeholder="Account Type"
            classNamePrefix="Account Type"
            value={[findSelectedEnum(accountInfo.type, accountTypes)]}
          />
        </td>
      </tr>
      <tr>
        <td>Business Line</td>
        <td>
          <Select
            name="businessLine"
            id="businessLine"
            required
            isDisabled={isEdit}
            className="select-register-input"
            onChange={onBusinessLineChange}
            options={businessLines}
            placeholder="Business Line"
            classNamePrefix="Business Line"
            value={[findSelectedEnum(accountInfo.businessLine, businessLines)]}
          />
        </td>
      </tr>
      <tr>
        <td>Name</td>
        <td>
          <input
            className="account-register-input-text"
            name="name"
            id="name"
            maxLength="100"
            required
            defaultValue={accountInfo.name}
            onChange={onUpdateAccountInfo}
            type="text"
            placeholder="Name"
          />
        </td>
      </tr>
      <tr>
        <td>Trade Name</td>
        <td>
          <input
            className="account-register-input-text"
            name="tradeName"
            id="tradeName"
            maxLength="100"
            defaultValue={accountInfo.tradeName}
            required
            onChange={onUpdateAccountInfo}
            type="text"
            placeholder="Trade Name"
          />
        </td>
      </tr>
      <tr>
        <td>Soft Descriptor</td>
        <td>
          <input
            className="account-register-input-text"
            name="softDescriptor"
            id="softDescriptor"
            defaultValue={accountInfo.softDescriptor}
            maxLength="14"
            required
            onChange={onUpdateAccountInfo}
            type="text"
            placeholder="Soft Descriptor"
          />
        </td>
      </tr>
      <tr>
        <td>Website</td>
        <td>
          <input
            className="account-register-input-text"
            name="website"
            id="website"
            defaultValue={accountInfo.website}
            maxLength="100"
            onChange={onUpdateAccountInfo}
            type="text"
            placeholder="Website"
          />
        </td>
      </tr>
      <tr>
        <td>MCC</td>
        <td>
          <input
            className="account-register-input-text"
            name="mcc"
            defaultValue={accountInfo.mcc}
            min="0"
            maxLength="20"
            onChange={onUpdateAccountInfo}
            id="mcc"
            type="number"
            placeholder="MCC"
          />
        </td>
      </tr>
      <tr>
        <td>Product Type</td>
        <td>
          <Select
            name="productType"
            id="productType"
            required
            className="select-register-input"
            onChange={onProductTypeChange}
            options={productTypes}
            placeholder="Product Type"
            classNamePrefix="Product Type"
            value={[findSelectedEnum(accountInfo.productType, productTypes)]}
          />
        </td>
      </tr>
      <tr>
        <td>Product Type Description</td>
        <td>
          <textarea
            className="account-register-input-text"
            name="productTypeDescription"
            id="productTypeDescription"
            maxLength="100"
            defaultValue={accountInfo.productTypeDescription}
            required
            onChange={onUpdateAccountInfo}
            type="text"
            placeholder="Product Type Description"
          />
        </td>
      </tr>
      <tr>
        <td>Business Model Description</td>
        <td>
          <textarea
            className="account-register-input-text"
            name="businessModelDescription"
            id="businessModelDescription"
            maxLength="100"
            defaultValue={accountInfo.businessModelDescription}
            onChange={onUpdateAccountInfo}
            placeholder="Business Model Description"
          />
        </td>
      </tr>
      <tr>
        <td>Cnae</td>
        <td>
          <input
            className="account-register-input-text"
            name="cnae"
            maxLength="20"
            required={isBrazilian}
            onChange={onUpdateAccountInfo}
            defaultValue={accountInfo.cnae}
            id="cnae"
            type="text"
            placeholder="Cnae"
          />
        </td>
      </tr>
      <tr>
        <td>Tax regime</td>
        <td>
          <input
            className="account-register-input-text"
            name="taxRegime"
            maxLength="20"
            required={isBrazilian}
            onChange={onUpdateAccountInfo}
            defaultValue={accountInfo.taxRegime}
            id="taxRegime"
            type="text"
            placeholder="Tax regime"
          />
        </td>
      </tr>
      <tr>
        <td>Legal nature</td>
        <td>
          <input
            className="account-register-input-text"
            name="legalNature"
            maxLength="20"
            required={isBrazilian}
            onChange={onUpdateAccountInfo}
            defaultValue={accountInfo.legalNature}
            id="legalNature"
            type="text"
            placeholder="Legal nature"
          />
        </td>
      </tr>
      <tr>
        <td>Branch activity</td>
        <td>
          <input
            className="account-register-input-text"
            name="branchActivity"
            maxLength="20"
            required={isBrazilian}
            onChange={onUpdateAccountInfo}
            defaultValue={accountInfo.branchActivity}
            id="branchActivity"
            type="text"
            placeholder="Branch activity"
          />
        </td>
      </tr>
      <tr>
        <td>Billing</td>
        <td>
          <input
            className="account-register-input-text"
            name="billing"
            maxLength="20"
            required={isBrazilian}
            onChange={onUpdateAccountInfo}
            defaultValue={accountInfo.billing}
            id="billing"
            type="number"
            placeholder="Billing"
          />
        </td>
      </tr>

      <tr>
        <td>Settlement currency</td>
        <td>
          <Select
            name="settlementCurrency"
            id="settlementCurrency"
            required
            isDisabled={isEdit}
            className="select-register-input"
            onChange={onSettlementCurrencyChange}
            options={currencyTypes}
            placeholder="Settlement currency"
            value={[findSelectedEnum(accountInfo.settlementCurrency, currencyTypes)]}
          />
        </td>
      </tr>
    </>
  )
}
