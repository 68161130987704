import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'

export default function PixProviderChargeInsert({ item, onChange, chargeValueTypes }) {
  const [chargeBeingInserted, setChargeBeingInserted] = useState({
    chargeValue: null,
    chargeValueType: null,
    minimumFixedChargeValue: 0,
    maximumFixedChargeValue: null,
    serviceType: 'PIX',
    ...item,
  })

  const onChangeProperty = (propertyName, value) => {
    chargeBeingInserted[propertyName] = value
    setChargeBeingInserted({ ...chargeBeingInserted })
    onChange(chargeBeingInserted)
  }

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">Charge value:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="chargeValue"
                  onChange={(event) => onChangeProperty('chargeValue', event.target.value)}
                  id="chargeValue"
                  type="number"
                  placeholder="charge value"
                  defaultValue={chargeBeingInserted.chargeValue}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Charge value type:</td>
              <td>
                <Select
                  name="chargeValueType"
                  id="chargeValueType"
                  onChange={(event) => onChangeProperty('chargeValueType', event.value)}
                  options={chargeValueTypes}
                  placeholder="charge value type"
                  value={chargeValueTypes.filter((option) => option.value === chargeBeingInserted.chargeValueType)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Minimum fixed price</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="minimumFixedChargeValue"
                  onChange={(event) => onChangeProperty('minimumFixedChargeValue', event.target.value)}
                  id="minimumFixedChargeValue"
                  type="number"
                  placeholder="Minimum fixed price"
                  defaultValue={chargeBeingInserted.minimumFixedChargeValue}
                  required
                />
              </td>
            </tr>
            {chargeBeingInserted.chargeValueType === 'RATE' && (
              <>
                <tr>
                  <td className="column-table">Maximum fixed price</td>
                  <td>
                    <input
                      className="accounting-register-input-text"
                      name="maximumFixedChargeValue"
                      onChange={(event) => onChangeProperty('maximumFixedChargeValue', event.target.value)}
                      id="maximumFixedChargeValue"
                      type="number"
                      placeholder="Maximum fixed price"
                      defaultValue={chargeBeingInserted.maximumFixedChargeValue}
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </BTable>
      </Container>
    </>
  )
}
