import Customers from './Customers'
import CustomerDetailsPage from './CustomerDetailsPage'

const CustomerRoutes = [
  {
    path: '/customers',
    element: <Customers />,
  },
  {
    path: '/customers/:id',
    element: <CustomerDetailsPage />,
  },
]

export default CustomerRoutes
