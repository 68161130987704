import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { convertToBrazilianTimeZone } from '../../../services/FormatterService'
import AccountButtonsToApprove from './AccountButtonsToApprove'
import { SimpleCard } from '../../../../matx'
import { accountDetails } from '../../../services/APIClient'
import { countryCodeToCountryName } from '../../../services/CountryUtils'

class AccountToApproveDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      details: null,
      id: this.props.id,
    }
  }

  componentDidMount() {
    accountDetails(this.state.id).then((resp) => {
      this.setState({ details: resp })
    })
  }

  render() {
    return (
      <div className="First card">
        <SimpleCard title="Account Details">
          <div>
            {this.state.details === null ? (
              ''
            ) : (
              <Container>
                <h5>Account:</h5>
                <BTable striped bordered hover>
                  <tbody>
                    <tr>
                      <td>Id</td>
                      <td>{this.state.details.id}</td>
                    </tr>
                    <tr>
                      <td>tradeName</td>
                      <td>{this.state.details.tradeName}</td>
                    </tr>
                    <tr>
                      <td>softDescriptor</td>
                      <td>{this.state.details.softDescriptor}</td>
                    </tr>
                    <tr>
                      <td>Type</td>
                      <td>{this.state.details.type}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{this.state.details.status}</td>
                    </tr>
                    <tr>
                      <td>Payment method</td>
                      <td>{this.state.details.primaryDocument}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{this.state.details.name}</td>
                    </tr>
                    <tr>
                      <td>MCC</td>
                      <td>{this.state.details.mcc}</td>
                    </tr>
                    <tr>
                      <td>Website</td>
                      <td>{this.state.details.website}</td>
                    </tr>
                    <tr>
                      <td>Product Type Description</td>
                      <td>{this.state.details.productTypeDescription}</td>
                    </tr>
                    <tr>
                      <td>Business Line</td>
                      <td>{this.state.details.businessLine}</td>
                    </tr>
                    <tr>
                      <td>Business Model Description</td>
                      <td>{this.state.details.businessModelDescription}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>{convertToBrazilianTimeZone(this.state.details.createdAt)}</td>
                    </tr>
                    <tr>
                      <td>Comments</td>
                      <td>{this.state.details.comments}</td>
                    </tr>
                  </tbody>
                </BTable>
                <AddressDetails address={this.state.details.address} />
                <BankDetails bank={this.state.details.bank} bankType={this.state.details.bank.type} />
                {this.state.details.documents.map((document, index) => (
                  <DocumentInfo key={index} document={document} />
                ))}
                {this.state.details.contacts.map((contact, index) => (
                  <ContactInfo key={index} contact={contact} />
                ))}
                {this.state.details.partners.map((partner, index) => (
                  <PartnerInfo key={index} partner={partner} />
                ))}
                {this.state.details.ubos.map((ubo, index) => (
                  <UboInfo key={index} ubo={ubo} />
                ))}
                <AccountButtonsToApprove accountId={this.state.id} />
              </Container>
            )}
          </div>
        </SimpleCard>
      </div>
    )
  }
}

class AddressDetails extends Component {
  render() {
    const { address } = this.props
    if (typeof address === 'undefined') return <React.Fragment />

    return (
      <React.Fragment>
        <h5>Address:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Address</td>
              <td>
                {address.street},&nbsp;
                {address.neighbourhood},&nbsp;
                {address.number}&nbsp;
                {address.complement} -&nbsp;
                {address.city}-&nbsp;
                {address.zipCode},&nbsp;
                {address.state}----&nbsp; ({countryCodeToCountryName(address.countryCode)})
              </td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class BankDetails extends Component {
  render() {
    const { bank, bankType } = this.props
    if (typeof bank === 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    if (bankType === 'BRAZIL') {
      return <BrazilBank bank={bank.brazil} />
    } else {
      return <GlobalBank bank={bank.global} />
    }
  }
}

class GlobalBank extends Component {
  render() {
    const { bank } = this.props
    if (typeof bank == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Global bank info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Bank Code</td>
              <td>{bank.bankName}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{bank.accountNumber}</td>
            </tr>
            <tr>
              <td>Swift Code</td>
              <td>{bank.swiftCode}</td>
            </tr>
            <tr>
              <td>Beneficiary name</td>
              <td>{bank.beneficiaryName}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class BrazilBank extends Component {
  render() {
    const { bank } = this.props
    if (typeof bank == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Brazil Bank info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Bank Code</td>
              <td>{bank.bankCode}</td>
            </tr>
            <tr>
              <td>Branch</td>
              <td>{bank.agency}</td>
            </tr>
            <tr>
              <td>Verifying Digit</td>
              <td>{bank.verifyingDigit}</td>
            </tr>
            <tr>
              <td>Account Number</td>
              <td>{bank.accountNumber}</td>
            </tr>
            <tr>
              <td>Pix</td>
              <td>{bank.pix}</td>
            </tr>
            <tr>
              <td>Beneficiary name</td>
              <td>{bank.beneficiaryName}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class DocumentInfo extends Component {
  render() {
    const { document } = this.props
    if (typeof document == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Document Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Document Id</td>
              <td>{document.id}</td>
            </tr>
            <tr>
              <td>Document Number</td>
              <td>{document.document}</td>
            </tr>
            <tr>
              <td>Document Type</td>
              <td>{document.type}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class ContactInfo extends Component {
  render() {
    const { contact } = this.props
    if (typeof contact == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Contact Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>name</td>
              <td>{contact.name}</td>
            </tr>
            <tr>
              <td>email</td>
              <td>{contact.email}</td>
            </tr>
            <tr>
              <td>telephone</td>
              <td>{contact.telephone}</td>
            </tr>
            <tr>
              <td>position</td>
              <td>{contact.position}</td>
            </tr>
            <tr>
              <td>sector</td>
              <td>{contact.sector}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class PartnerInfo extends Component {
  render() {
    const { partner } = this.props
    if (typeof partner == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Partner Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>pep</td>
              <td>{partner.pep ? 'True' : 'False'}</td>
            </tr>
            <tr>
              <td>document</td>
              <td>{partner.document}</td>
            </tr>
            <tr>
              <td>documentType</td>
              <td>{partner.documentType}</td>
            </tr>
            <tr>
              <td>participation</td>
              <td>{partner.participation}</td>
            </tr>
            <tr>
              <td>documentIssuer</td>
              <td>{partner.documentIssuer}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

class UboInfo extends Component {
  render() {
    const { ubo } = this.props
    if (typeof ubo == 'undefined') {
      return <React.Fragment></React.Fragment>
    }
    return (
      <React.Fragment>
        <h5>Partner Info:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>pep</td>
              <td>{ubo.pep ? 'True' : 'False'}</td>
            </tr>
            <tr>
              <td>name</td>
              <td>{ubo.name}</td>
            </tr>
            <tr>
              <td>documentType</td>
              <td>{ubo.documentType}</td>
            </tr>
            <tr>
              <td>document</td>
              <td>{ubo.document}</td>
            </tr>
            <tr>
              <td>documentIssuer</td>
              <td>{ubo.documentIssuer}</td>
            </tr>
          </tbody>
        </BTable>
      </React.Fragment>
    )
  }
}

export default AccountToApproveDetails
