import React, { Component } from 'react'
import { Breadcrumb } from '../../../matx'
import { withRouter } from '../../../utils'
import PayeeMerchantDetails from './PayeeMerchantDetails'

class PayeeMerchantDetailsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.params.id,
    }
  }

  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Payee', path: '/payee' }, { name: 'Details' }]} />
        </div>
        <PayeeMerchantDetails payeeMerchantId={this.state.id} />
      </div>
    )
  }
}

export default withRouter(PayeeMerchantDetailsPage)
