import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

function WebhookDetails({ webhooks }) {
  if (typeof webhooks === 'undefined') return <></>

  const columns = ['Type', 'URL']

  return (
    <div className="my-4">
      <h5>Webhooks:</h5>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index} className="px-0">
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {webhooks.length > 0 ? (
            webhooks.map((webhook) => <WebhookRow key={webhook.webhookType} webhook={webhook} />)
          ) : (
            <TableRow>
              <TableCell>No result</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

function WebhookRow({ webhook }) {
  const content = [webhook.webhookType, webhook.webhook]

  return (
    <TableRow key={`webhook-${webhook.webhookType}`}>
      {content.map((column, index) => (
        <TableCell key={index} className="px-0 capitalize" align="left">
          {column}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default WebhookDetails
