import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '@mui/material'
import TouchRipple from '@mui/material/ButtonBase'
import MatxVerticalNavExpansionPanel from './MatxVerticalNavExpansionPanel'
import { withStyles } from 'tss-react/mui'

class MatxVerticalNav extends Component {
  state = {
    collapsed: true,
  }

  renderLevels = (data) => {
    return data.map((item, index) => {
      if (item.children) {
        return (
          <MatxVerticalNavExpansionPanel item={item} key={index}>
            {this.renderLevels(item.children)}
          </MatxVerticalNavExpansionPanel>
        )
      } else {
        return (
          <NavLink key={index} to={item.path} className="nav-item">
            <TouchRipple key={item.name} name="child" className="w-100">
              {(() => {
                if (item.icon) {
                  return <Icon className="item-icon text-middle">{item.icon}</Icon>
                } else {
                  return <span className="item-icon icon-text">{item.iconText}</span>
                }
              })()}
              <span className="text-middle pl-20 item-text">{item.name}</span>
              <div className="mx-auto"></div>
              {item.badge && <div className={`badge bg-${item.badge.color}`}>{item.badge.value}</div>}
            </TouchRipple>
          </NavLink>
        )
      }
    })
  }

  handleClick = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    return <div className="navigation">{this.renderLevels(this.props.navigation)}</div>
  }
}

export default withStyles(MatxVerticalNav, {
  expandIcon: {
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'rotate(90deg)',
  },
  collapseIcon: {
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'rotate(0deg)',
  },
})
