import layout1Settings from './Layout1/Layout1Settings'
import { themeColors } from './MatxTheme/themeColors'
import { createTheme } from '@mui/material/styles'
import { forEach, merge } from 'lodash'
import themeOptions from './MatxTheme/themeOptions'

function createMatxThemes() {
  let themes = {}

  forEach(themeColors, (value, key) => {
    themes[key] = createTheme(merge({}, themeOptions, value))
  })
  return themes
}
const themes = createMatxThemes()

export const MatxLayoutSettings = {
  activeLayout: 'layout1',
  activeTheme: 'white', // View all valid theme colors inside MatxTheme/themeColors.js
  perfectScrollbar: true,

  themes: themes,
  layout1Settings, // open Layout1/Layout1Settings.js
}
