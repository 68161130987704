import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid'

export default function MultipleItemsInput(props) {
  const { title, onChange } = props
  const items = props.items.map((item) => {
    return { key: uuid(), ...item }
  })

  function deleteByKey(key) {
    let newArray = [...items]
    const index = newArray.findIndex((item) => item.key === key)
    newArray.splice(index, 1)
    onChange(newArray)
  }

  function createNewField() {
    onChange([...items, { removable: true, key: uuid() }])
  }

  function onItemChange(item, key) {
    let newArray = items
    const index = newArray.findIndex((item) => item.key === key)
    newArray[index] = item
    onChange(newArray)
  }

  return (
    <div>
      {items != null ? (
        items.map((item) => (
          <div className="card-account-register-field center" key={item.key}>
            <div className="row">
              <div className="card-title-position">
                <h4>{title}</h4>
              </div>
            </div>
            {!item.removable ? (
              <React.Fragment />
            ) : (
              <div
                className="card-btn-remove"
                onClick={() => {
                  deleteByKey(item.key)
                }}
              >
                {' '}
                Remove <FontAwesomeIcon icon={faTrash} />
              </div>
            )}
            <div id="input">
              {React.cloneElement(props.children, {
                item: item,
                index: item.key,
                onChange: (changes) => {
                  onItemChange(changes, item.key)
                },
              })}
            </div>
          </div>
        ))
      ) : (
        <React.Fragment />
      )}
      <div className="card-btn-register" onClick={createNewField}>
        Add New {title} <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  )
}

export const buildItem = (content, removable) => {
  return { ...content, removable, key: uuid() }
}
