import React, { Component } from 'react'
import { Breadcrumb } from '../../../../matx'
import AccountRegistrationPage from './AccountRegistrationPage'

class AccountRegister extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Account Register' }]} />
        </div>
        <AccountRegistrationPage />
      </div>
    )
  }
}

export default AccountRegister
