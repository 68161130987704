import Select from 'react-select'
import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'

class ContactInput extends Component {
  constructor(props) {
    super(props)

    this.state = props.item || {}
    this.sectors = props.sectors
    this.onChange = props.onChange
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangeSector = (event) => {
    this.onAnyChanged({ sector: event.value })
  }

  onChangeName = (event) => {
    this.onAnyChanged({ name: event.target.value })
  }

  onChangeTelephone = (event) => {
    this.onAnyChanged({ telephone: event.target.value })
  }

  onChangePosition = (event) => {
    this.onAnyChanged({ position: event.target.value })
  }

  onChangeEmail = (event) => {
    this.onAnyChanged({ email: event.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td className="column-table">Sector:</td>
                <td>
                  <Select
                    name="sector"
                    id="sector"
                    className="select-register-input"
                    onChange={this.onChangeSector}
                    options={this.sectors}
                    classNamePrefix="sector"
                    placeholder="Sector"
                    value={this.sectors.filter((option) => option.value === this.state.sector)}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td className="column-table">Name:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="name"
                    onChange={this.onChangeName}
                    id="name"
                    type="text"
                    placeholder="Name"
                    defaultValue={this.state.name}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td className="column-table">Telephone:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="telephone"
                    onChange={this.onChangeTelephone}
                    id="telephone"
                    type="text"
                    placeholder="Telephone"
                    defaultValue={this.state.telephone}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Position:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="position"
                    onChange={this.onChangePosition}
                    id="position"
                    type="text"
                    placeholder="Position"
                    defaultValue={this.state.position}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Email:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="email"
                    onChange={this.onChangeEmail}
                    id="email"
                    type="email"
                    placeholder="Email"
                    defaultValue={this.state.email}
                    required
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default ContactInput
