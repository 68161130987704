import { CircularProgress, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'
import { SimpleCard } from '../../../../matx'
import { convertToBrazilianTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useEffect, useState } from 'react'
import { approveSettleAmounts, getBalanceAmountsToSettle } from '../../../services/APIClient'
import Loading from '../../../components/Loading'
import Select from 'react-select'

const columns = [
  'Merchant soft descriptor',
  'Merchant ID',
  'FX Banks able to settle',
  'Balance date time',
  'Local currency amount',
  'Foreing currency amount',
]

export default function SettlementBatchToApprove() {
  const [isLoading, setIsLoading] = useState(true)
  const [isApproving, setIsApproving] = useState(false)
  const [amountsToSettleContent, setAmountsToSettleContent] = useState([])
  const [allPossibleFxBanks, setAllPossibleFxBanks] = useState([])
  const [fxBankToMakeSettlement, setFxBankToMakeSettlement] = useState(null)
  const [amountsToSettleByFxBank, setAmountsToSettleByFxBank] = useState([])
  const [settlementsToShow, setSettlementsToShow] = useState([])

  useEffect(() => {
    const getBalancesToSettle = async () => {
      const balancesToSettle = await getBalanceAmountsToSettle()

      const allFxBanksThatCanSettle = balancesToSettle.flatMap((item) => item.fxBanksAbleToSettle)
      const allFxBanks = [...new Set(allFxBanksThatCanSettle)]
      const amountsToSettleContentByFxBank = balancesToSettle.reduce((acc, item) => {
        allFxBanks.forEach((fxBank) => {
          if (acc[fxBank] === undefined) {
            acc[fxBank] = []
          }
          if (item.fxBanksAbleToSettle.includes(fxBank)) {
            acc[fxBank].push(item)
          }
        })
        return acc
      }, {})

      setAmountsToSettleByFxBank(amountsToSettleContentByFxBank)
      setAllPossibleFxBanks(allFxBanks)
      setAmountsToSettleContent(balancesToSettle)
      setIsLoading(false)
    }

    getBalancesToSettle()
  }, [])

  useEffect(() => {
    if (fxBankToMakeSettlement !== null) {
      setSettlementsToShow(amountsToSettleByFxBank[fxBankToMakeSettlement])
    } else {
      setSettlementsToShow(amountsToSettleContent)
    }
  }, [fxBankToMakeSettlement, amountsToSettleContent, amountsToSettleByFxBank])

  const allPossibleFxBanksOptions = allPossibleFxBanks.map((fxBank) => {
    return {
      value: fxBank,
      label: `${fxBank} (${amountsToSettleByFxBank[fxBank].length})`,
    }
  })

  function approveAmounts() {
    if (settlementsToShow.length < amountsToSettleContent.length) {
      if (window.confirm('You are not settling all merchants. Are you sure you want to continue?')) {
        approveSettlements()
      }
    } else {
      approveSettlements()
    }
  }

  const approveSettlements = async () => {
    setIsApproving(true)

    const balances = settlementsToShow.map((balance) => {
      return {
        merchantId: balance.merchantId,
        foreignCurrencyAmount: balance.foreignCurrencyAmount,
        localCurrencyAmount: balance.amount,
      }
    })

    await approveSettleAmounts({ merchantsAndAmounts: balances, filterFxBank: fxBankToMakeSettlement })
    setIsApproving(false)
  }

  return (
    <div className="m-sm-30">
      <SimpleCard title="Settlement Batch B2B - To approve">
        {isLoading ? (
          <Loading />
        ) : amountsToSettleContent.length > 0 ? (
          <div>
            <FxBanksAbleToSettle
              amountsToSettleContent={amountsToSettleContent}
              amountsToSettleByFxBank={amountsToSettleByFxBank}
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Select
                defaultValue={fxBankToMakeSettlement}
                name="fxBankToMakeSettlement"
                id="fxBankToMakeSettlement"
                required
                onChange={(event) => setFxBankToMakeSettlement(event.value)}
                options={allPossibleFxBanksOptions}
                placeholder="FX bank to make settlement"
              />
              <button
                disabled={!fxBankToMakeSettlement}
                type="button"
                className="btn btn-success"
                onClick={() => approveAmounts()}
              >
                {isApproving ? <CircularProgress size={30} /> : 'Approve'}
              </button>
            </div>
            <SettlementsTable
              amountsToSettleContent={settlementsToShow}
              totalAmountInForeignCurrency={getTotalAmountInForeignCurrency(amountsToSettleContent)}
              totalAmountInLocalCurrency={getTotalAmountInLocalCurrency(amountsToSettleContent)}
            />
          </div>
        ) : (
          <TableRow>
            <TableCell>There is no balance to settle</TableCell>
          </TableRow>
        )}
      </SimpleCard>
    </div>
  )
}

function SettlementsTable({ amountsToSettleContent, totalAmountInForeignCurrency, totalAmountInLocalCurrency }) {
  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell className="px-0">{column}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {amountsToSettleContent.map((item) => (
          <BalancesToApproveRow balance={item} />
        ))}
      </TableBody>
      <TableFooter>
        <div style={{ marginTop: 30 }}>
          {`Total amount in local currency: ${currencyWithTwoDecimals(getTotalAmountInLocalCurrency(amountsToSettleContent))} / ${currencyWithTwoDecimals(totalAmountInLocalCurrency)}`}
        </div>
        <div style={{ marginTop: 30 }}>
          {`Total amount in foreign currency: ${currencyWithTwoDecimals(getTotalAmountInForeignCurrency(amountsToSettleContent), '$')} / ${currencyWithTwoDecimals(totalAmountInForeignCurrency, '$')}`}
        </div>
      </TableFooter>
    </Table>
  )
}

const BalancesToApproveRow = ({ balance }) => {
  let columnContent = [
    balance.merchantSoftDescriptor,
    balance.merchantId,
    balance.fxBanksAbleToSettle.join(', '),
    convertToBrazilianTimeZone(balance.balanceDateTime),
    `${currencyWithTwoDecimals(balance.amount, '')} BRL`,
    `${currencyWithTwoDecimals(balance.foreignCurrencyAmount, '')} ${balance.foreignCurrency}`,
  ]

  let cells = columnContent.map((content) => (
    <TableCell className="px-0 capitalize" align="left">
      {content}
    </TableCell>
  ))

  return <TableRow>{cells}</TableRow>
}

function FxBanksAbleToSettle({ amountsToSettleContent, amountsToSettleByFxBank }) {
  return (
    <>
      <h6>Quantity of merchants that can be settled by FX Bank:</h6>
      <p>All merchants: {amountsToSettleContent.length}</p>
      {Object.entries(amountsToSettleByFxBank).map(([fxBank, possibleSettlements]) => (
        <p>{`${fxBank}: ${possibleSettlements.length}`}</p>
      ))}
    </>
  )
}

function getTotalAmountInForeignCurrency(amountsToSettle) {
  return amountsToSettle.reduce((init, element) => init + element.foreignCurrencyAmount, 0)
}

function getTotalAmountInLocalCurrency(amountsToSettle) {
  return amountsToSettle.reduce((init, element) => init + element.amount, 0)
}
