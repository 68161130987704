import { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from './Button'

export default function UploadFile({ fileDescription, uploadFunction }) {
  const [file, setFile] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [csvError, setCsvError] = useState(undefined)

  const onSubmit = (event) => {
    event.preventDefault()
    setCsvError(undefined)
    setIsLoading(true)
    uploadFunction(file, errorCallback)
      .catch((e) => setCsvError({ errorMessage: 'unexpected error', errorCauses: [] }))
      .finally(() => {
        setIsLoading(false)
      })
  }

  async function errorCallback(response) {
    const responseBody = await response.json()
    setCsvError(responseBody)
  }

  return (
    <>
      <div className="card-modal-report">
        <div style={{ paddingLeft: '30px' }}>
          <div>
            <p>{fileDescription}</p>
          </div>
          <form method="post" encType="multipart/form-data" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6 col-4">
                <input
                  type="file"
                  className="form-control-file"
                  id="file"
                  name="file"
                  accept=".csv"
                  onChange={(event) => setFile(event.target.files[0])}
                />
              </div>
              <div className="col-md-6 col-4">
                <Button isLoading={isLoading} disabled={file === undefined || isLoading}>
                  Upload file
                </Button>
              </div>
            </div>
          </form>
          {csvError === undefined ? (
            <></>
          ) : (
            <>
              <h3>{csvError.errorMessage}</h3>
              <ul style={{ color: '#e53935' }}>
                {csvError.errorCauses.map((error) => (
                  <li key={error.cause}>{error.cause}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  )
}
