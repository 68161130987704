const config = {
  TRANSACTION_BASE_URL: process.env.REACT_APP_TRANSACTION_BASE_URL || 'http://localhost:8081',
  ACCOUNTING_BASE_URL: process.env.REACT_APP_ACCOUNTING_BASE_URL || 'http://localhost:8085',
  AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL || 'http://localhost:8089',
  RECEIVABLE_BASE_URL: process.env.REACT_APP_RECEIVABLE_BASE_URL || 'http://localhost:8090',
  ACCOUNT_BASE_URL: process.env.REACT_APP_ACCOUNT_BASE_URL || 'http://localhost:8087',
  KYC_BASE_URL: process.env.REACT_APP_KYC_BASE_URL || 'http://localhost:8086',
}

export default config
