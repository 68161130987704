import React, { Component } from 'react'
import { Breadcrumb } from '../../../matx'
import CercReceivablePaginationTable from './CercReceivablePaginationTable'

class CercReceivablePage extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'CERC' }, { name: 'Receivables' }]} />
          <CercReceivablePaginationTable />
        </div>
      </div>
    )
  }
}

export default CercReceivablePage
