import ChargebackTable from './ChargebackTable'
import ChargebackDetails from './ChargebackDetails'

const chargebackRoutes = [
  {
    path: '/payin/chargeback',
    element: <ChargebackTable />,
    exact: true,
  },
  {
    path: '/payin/chargeback/:id',
    element: <ChargebackDetails />,
  },
]

export default chargebackRoutes
