import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material'
import { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { convertToBrazilianTimeZone } from '../../../services/FormatterService'
import { accountToApprove } from '../../../services/APIClient'
import { withNavigate } from '../../../../utils'

class AccountToApprovePaginationTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountsList: [],
      accountsFiltered: [],
      filters: [],
      query: '',
    }
  }

  componentDidMount() {
    this.updateRowsPerPage()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters !== prevState.filters || this.state.accountsList !== prevState.accountsList)
      this.filterAccounts(this.state.filters, this.state.accountsList)
  }

  updateRowsPerPage() {
    accountToApprove().then((resp) => {
      if (typeof resp !== 'undefined') {
        this.setState({
          accountsList: resp.result,
        })
      }
    })
  }

  handleChangeRowsPerPage = (event) => {
    this.updateRowsPerPage(this.state.page, +event.target.value)
  }

  handleChangePage = (event, newPage) => {
    this.updateRowsPerPage(newPage, this.state.rowsPerPage)
  }

  handleClick = (id) => {
    this.props.navigate(`/account_to_approve/${id}`)
  }

  inputValue = ''

  changeHandler = (event) => {
    this.inputValue = event.target.value
  }

  removeFilterHandler = (event, filter) => {
    let newArray = [...this.state.filters]
    let index = newArray.indexOf(filter)
    if (index !== -1) {
      newArray.splice(index, 1)
      this.setState({ filters: newArray })
    }
  }

  saveFilter = (event) => {
    let query = this.inputValue
    let newFilters = [...this.state.filters, query]
    this.setState({ filters: newFilters })
  }

  renderFilterList() {
    return this.state.filters.map((filter) => {
      return (
        <button
          key={`buttonFilters ${filter}`}
          className={`buttonFilter border-radius-4 px-10 py-10px`}
          onClick={(event) => this.removeFilterHandler(event, filter)}
        >
          {filter}
        </button>
      )
    })
  }

  fieldNullable(value, currentValue) {
    if (typeof value === 'undefined' || value === '') {
      return false
    }
    return value.toLowerCase().includes(currentValue)
  }

  filterAccounts(filters, allAccounts) {
    if (filters.length === 0) {
      this.setState({ accountsFiltered: allAccounts })
      return
    }

    let reducer = (previousValue, currentValue) =>
      previousValue.filter((account) => {
        return (
          this.fieldNullable(account.accountId, currentValue) ||
          this.fieldNullable(account.tradeName, currentValue) ||
          this.fieldNullable(account.softDescriptor, currentValue) ||
          this.fieldNullable(account.status, currentValue)
        )
      })

    let filtered = filters.map((filter) => filter.toLowerCase()).reduce(reducer, allAccounts)
    if (filtered.length > 0) {
      this.setState({ accountsFiltered: filtered })
    } else {
      toast.info('Nenhuma account encontrado com esses filtros nesta página, voltamos com todas as accounts!', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      this.setState({ accountsFiltered: allAccounts })
    }
  }

  render() {
    if (typeof this.state.accountsFiltered === 'undefined') {
      this.props.navigate('/login/signin')
      return ''
    }
    if (this.state.accountsFiltered.length === 0) {
      return ''
    }
    return (
      <div>
        <div
          className="MuiToolbar-root MuiToolbar-regular jss8044 MuiToolbar-gutters"
          role="toolbar"
          aria-label="Table Toolbar"
        >
          <div className="jss8046 titleWithSearchBox">
            <div className="jss8050" aria-hidden="true">
              <h6 className="MuiTypography-root jss8051 MuiTypography-h6">Accounts</h6>
            </div>
          </div>
          <div className="main_search">
            {this.renderFilterList()}
            <input className="main_search_input" onChange={this.changeHandler} placeholder="Buscar!" />
            <button className="search_button" onClick={this.saveFilter}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <div className="w-100 overflow-auto">
          <Table style={{ whiteSpace: 'pre' }}>
            <TableHead>
              <TableRow>
                <TableCell className="px-0">ApproveId</TableCell>
                <TableCell className="px-0">TradeName</TableCell>
                <TableCell className="px-0">SoftDescriptor</TableCell>
                <TableCell className="px-0">Status</TableCell>
                <TableCell className="px-0">Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.accountsFiltered.length > 0 ? (
                this.state.accountsFiltered.map((account, index) => (
                  <TableRow key={index} onClick={() => this.handleClick(account.account.id)}>
                    <TableCell className="px-0 capitalize" align="left">
                      {account.id}
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left">
                      {account.account.tradeName}
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left">
                      {account.account.softDescriptor}
                    </TableCell>
                    <TableCell className="px-0 capitalize">
                      <div>
                        <small className={`capitalize text-white bg-account-active border-radius-4 px-10 py-10px`}>
                          {account.status}
                        </small>
                      </div>
                    </TableCell>
                    <TableCell>{convertToBrazilianTimeZone(account.createAt)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>Sem resultados</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <TablePagination
            className="px-16"
            rowsPerPageOptions={[
              200,
              parseInt(this.state.listLength / 4),
              parseInt(this.state.listLength / 2),
              parseInt(this.state.listLength),
            ]}
            component="div"
            count={this.state.listLength}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima página',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    )
  }
}

export default withNavigate(AccountToApprovePaginationTable)
