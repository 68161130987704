import { Component } from 'react'
import { SimpleCard } from '../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import React from 'react'
import PayinTransactionTableContent from '../payin/payin-transactions/PayinTransactionTableContent'
import 'bootstrap/dist/css/bootstrap.min.css'
import { cercReceivableDetails, cercReceivableStatusUpdate } from '../../services/APIClient'
import { currencyWithTwoDecimals } from '../../services/FormatterService'

export default class ReceivableDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { details: null, id: this.props.id }
  }

  componentDidMount() {
    this.updateInformation()
  }

  updateInformation() {
    cercReceivableDetails(this.state.id).then((resp) => {
      this.setState({ details: resp })
    })
  }

  renderFragment(title, items) {
    let tableContent = Object.entries(items).map(([title, value]) => (
      <tr key={title}>
        <td>{title}</td>
        <td>{value}</td>
      </tr>
    ))

    return (
      <SimpleCard title={title}>
        <Container>
          <BTable striped bordered hover>
            <tbody>{tableContent}</tbody>
          </BTable>
        </Container>
      </SimpleCard>
    )
  }

  renderReceivable(receivable) {
    return this.renderFragment('Receivable', {
      'Receivable Id': receivable.id,
      Reference: receivable.reference,
      'Document Number': receivable.documentNumber,
      'Arrangement Code': receivable.arrangementCode,
      Date: receivable.date,
      Status: receivable.status,
      'Account Id': receivable.account.id,
      'Account Name': receivable.account.name,
      'Gross Amount': currencyWithTwoDecimals(receivable.grossAmount),
      'Liquid Amount': currencyWithTwoDecimals(receivable.liquidAmount),
    })
  }

  renderPaymentSettlement(settlement) {
    return this.renderFragment('Payment', {
      Compe: settlement.compe,
      ISPB: settlement.ispb,
      Branch: settlement.agency,
      'Account Number': settlement.accountNumber,
      'Account Type': settlement.accountType,
      'Holder Document': settlement.holderDocument,
      'Holder Name': settlement.holderName,
    })
  }

  markAsPaid() {
    cercReceivableStatusUpdate(this.state.id).then(() => {
      this.updateInformation()
    })
  }

  renderPaymentButton() {
    return (
      <SimpleCard title="Payment">
        <Button onClick={() => this.markAsPaid()} variant="primary" color="primary">
          Mark as Paid
        </Button>
      </SimpleCard>
    )
  }

  render() {
    if (!this.state.details) return null

    return (
      <>
        {this.renderReceivable(this.state.details)}
        {this.renderPaymentSettlement(this.state.details.paymentSettlement)}
        <SimpleCard title="Transactions">
          <Container>
            <PayinTransactionTableContent items={this.state.details.transactions.map((item) => item.transaction)} />
          </Container>
        </SimpleCard>
        {this.renderPaymentButton()}
      </>
    )
  }
}
