import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal } from 'react-bootstrap'
import { Fragment } from 'react'
import { ViewCredentials } from './ViewCredentials'

export function ViewCredentialsModalsFragment({ accountId }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <Button className="btn-success" onClick={handleOpen}>
        View Credentials
      </Button>
      <Modal size="xl" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Credentials Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewCredentials accountId={accountId} />
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
