import React, { Component } from 'react'
import { Breadcrumb, SimpleCard } from '../../../matx'
import PayoutTransactionPaginationTable from './PayoutTransactionPaginationTable'

class PayoutTransaction extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Payout Transactions' }]} />
        </div>
        <div className="m-sm-30">
          <div className="py-12" />
          <SimpleCard>
            <PayoutTransactionPaginationTable />
          </SimpleCard>
        </div>
      </div>
    )
  }
}

export default PayoutTransaction
