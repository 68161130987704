import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../utils'
import { cercReceivablePagination } from '../../services/APIClient'

class CercReceivablePaginationTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      page: 0,
      length: 0,
      totalPages: 0,
      rowsPerPage: 15,
    }
  }

  componentDidMount() {
    this.updateRowsPerPage(this.state.page, this.state.rowsPerPage)
  }

  updateRowsPerPage(page, rowsPerPage) {
    cercReceivablePagination(page, rowsPerPage).then((resp) => {
      if (typeof resp !== 'undefined') {
        this.setState({
          data: resp.content,
          page: page,
          rowsPerPage: rowsPerPage,
          length: resp.totalElements,
          totalPages: resp.totalPages,
        })
      }
    })
  }

  handleChangeRowsPerPage = (event) => {
    this.updateRowsPerPage(this.state.page, +event.target.value)
  }

  handleChangePage = (event, newPage) => {
    this.updateRowsPerPage(newPage, this.state.rowsPerPage)
  }

  handleClick = (event, id) => {
    this.props.navigate(`/cerc/receivable/${id}`)
  }

  render() {
    if (typeof this.state.data === 'undefined') {
      this.props.navigate('/login/signin')
      return ''
    }
    if (this.state.data.length === 0) {
      return ''
    }
    return (
      <div>
        <div
          className="MuiToolbar-root MuiToolbar-regular jss8044 MuiToolbar-gutters"
          role="toolbar"
          aria-label="Table Toolbar"
        >
          <div className="jss8046 titleWithSearchBox">
            <div className="jss8050" aria-hidden="true">
              <h6 className="MuiTypography-root jss8051 MuiTypography-h6">CERC Receivables</h6>
            </div>
          </div>
        </div>
        <div className="w-100 overflow-auto">
          <Table style={{ whiteSpace: 'pre' }}>
            <TableHead>
              <TableRow>
                <TableCell className="px-0">Receivable Id</TableCell>
                <TableCell className="px-0">Reference</TableCell>
                <TableCell className="px-0">Account</TableCell>
                <TableCell className="px-0">Date</TableCell>
                <TableCell className="px-0">Arrangement Code</TableCell>
                <TableCell className="px-0">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((item, index) => (
                <TableRow key={index} onClick={(event) => this.handleClick(event, item.id)}>
                  <TableCell className="px-0 capitalize" align="left">
                    {item.id}
                  </TableCell>
                  <TableCell className="px-0 capitalize" align="left">
                    {item.reference}
                  </TableCell>
                  <TableCell className="px-0 capitalize" align="left">
                    {item.account.name}
                  </TableCell>
                  <TableCell className="px-0 capitalize" align="left">
                    {item.date}
                  </TableCell>
                  <TableCell className="px-0 capitalize" align="left">
                    {item.arrangementCode}
                  </TableCell>
                  <TableCell className="px-0 capitalize">
                    <div>
                      {(() => {
                        var status = item.status
                        var bgColor = 'bg-green'
                        if (status === 'CREATED') {
                          bgColor = 'bg-secondary'
                        }
                        return (
                          <small className={`capitalize text-white ${bgColor} border-radius-4 px-10 py-10px`}>
                            {status}
                          </small>
                        )
                      })()}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            className="px-16"
            rowsPerPageOptions={[15, 25, 50]}
            component="div"
            count={this.state.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{ 'aria-label': 'Página anterior' }}
            nextIconButtonProps={{ 'aria-label': 'Próxima página' }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    )
  }
}

export default withNavigate(CercReceivablePaginationTable)
