import localStorageService from './LocalStorageService'
import jwtDecode from 'jwt-decode'
import config from '../../config'

class JwtAuthService {
  user = {
    token: '',
    dateTime: '',
    expiresIn: '',
  }

  userInfo = {
    email: '',
  }

  loginWithEmailAndPassword = (emailForm, passwordForm) => {
    const authBaseUrl = config.AUTH_BASE_URL
    return fetch(`${authBaseUrl}/user/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: emailForm, password: passwordForm }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Something went wrong ...')
        }
      })
      .then((data) => {
        this.setSession(data.token)
        this.setUser(data)
        return data
      })
  }

  getToken = () => {
    return localStorage.getItem('jwt_token')
  }

  getOperatorId = () => {
    return localStorage.getItem('operator_id')
  }

  setSession = (token) => {
    if (token) {
      localStorage.setItem('jwt_token', token)
    } else {
      localStorage.removeItem('jwt_token')
    }
  }

  logout = () => {
    this.setSession(null)
    this.removeUser()
  }

  setUser = (data) => {
    let decode = jwtDecode(data.token)
    localStorageService.setItem('operator_id', decode.operator_id)
  }

  removeUser = () => {
    localStorage.removeItem('auth_user')
  }
}

export default new JwtAuthService()
