import Select from 'react-select'
import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'

class WebhookInput extends Component {
  constructor(props) {
    super(props)

    this.state = props.item || {}
    this.webhookTypes = props.webhookTypes
    this.onChange = props.onChange
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangeWebhook = (event) => {
    this.onAnyChanged({ webhook: event.target.value })
  }

  onChangeWebhookType = (event) => {
    this.onAnyChanged({ webhookType: event.value })
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td className="column-table">Webhook Type:</td>
                <td>
                  <Select
                    name="webhookType"
                    id="webhookType"
                    className="select-register-input"
                    onChange={this.onChangeWebhookType}
                    options={this.webhookTypes}
                    classNamePrefix="webhookType"
                    placeholder="Webhook Type"
                    value={this.webhookTypes.filter((option) => option.value === this.state.webhookType)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Webhook:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="webhook"
                    onChange={this.onChangeWebhook}
                    id="webhook"
                    type="text"
                    placeholder="Webhook URL"
                    defaultValue={this.state.webhook}
                    required
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default WebhookInput
