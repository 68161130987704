import { Route, Routes } from 'react-router-dom'

const renderRoutes = (routes) => (
  <Routes>
    {routes.map((route, i) => (
      <Route path={route.path} key={i} element={route.element} />
    ))}
  </Routes>
)

export default renderRoutes
