import PayeeMerchantPage from './PayeeMerchantPage'
import PayeeMerchantDetailsPage from './PayeeMerchantDetailsPage'

const payeeMerchantRoutes = [
  {
    path: '/payee-merchant',
    element: <PayeeMerchantPage />,
    exact: true,
  },
  {
    path: '/payee-merchant/:id',
    element: <PayeeMerchantDetailsPage />,
  },
]

export default payeeMerchantRoutes
