import React from 'react'
import { accountDetails, accountUpdate } from '../../../services/APIClient'
import { Component } from 'react'
import AccountUpdateForm from './form/AccountUpdateForm'
import { withRouter } from '../../../../utils'
import { buildItem } from './inputs/MultipleItemsInput'

class AccountUpdatePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: this.props.params.id,
      accountInfo: null,
    }
  }

  componentDidMount() {
    accountDetails(this.state.accountId).then((resp) => {
      const respChanged = {
        ...resp,
        fees: resp.fees.map((content) => buildItem(content, false)),
        settlementPeriods: resp.settlementPeriods.map((content) => buildItem(content, false)),
        contacts: resp.contacts.map((content) => buildItem(content, false)),
        documents: resp.documents.map((content) => buildItem(content, false)),
        webhooks: resp.webhooks.map((content) => buildItem(content, false)),
        partners: resp.partners.map((content) => buildItem(content, false)),
        ubos: resp.ubos.map((content) => buildItem(content, false)),
      }
      this.setState({ accountInfo: respChanged })
    })
  }

  updateAccount = async () => {
    let body = this.state.accountInfo
    accountUpdate(body, this.state.accountId)
  }

  render() {
    return (
      <AccountUpdateForm
        accountInfo={this.state.accountInfo}
        onSubmit={this.updateAccount}
        onChange={(changes) => this.setState({ accountInfo: changes })}
        isEdit={true}
      />
    )
  }
}

export default withRouter(AccountUpdatePage)
