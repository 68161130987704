import React from 'react'
import { SimpleCard } from '../../../../matx'
import { accountPagination } from '../../../services/APIClient'
import PaginationTable from '../../../../matx/components/PaginationTable'
import AccountTableContent from './AccountTableContent'

const AccountTable = () => {
  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        <PaginationTable
          queryItems={accountPagination}
          tableName="Accounts"
          tableContent={(filteredItems) => <AccountTableContent items={filteredItems} />}
          attributesToFilter={['softDescriptor', 'tradeName', 'status']}
        />
      </SimpleCard>
    </div>
  )
}

export default AccountTable
