import React from 'react'
import { Breadcrumb, SimpleCard } from '../../../matx'
import ProviderTableRow from './ProviderTableRow'
import { getAllProviders } from '../../services/APIClient'
import PaginationTable from '../../../matx/components/PaginationTable'
import TableContent from '../../components/TableContent'

export default function Providers() {
  const columns = ['Provider id', 'Name', 'Service type']

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Providers' }]} />
      </div>
      <div className="m-sm-30">
        <SimpleCard>
          <PaginationTable
            queryItems={getAllProviders}
            tableName="Providers"
            tableContent={(filteredItems) => (
              <TableContent items={filteredItems} columns={columns} Row={ProviderTableRow} />
            )}
            attributesToFilter={['name', 'type']}
            hasSailorSwitch={false}
          />
        </SimpleCard>
      </div>
    </div>
  )
}
