import { Component } from 'react'
import { SimpleCard } from '../../../matx'
import { Container } from 'react-bootstrap'
import _ from 'lodash'
import { cercReceivableCalendar } from '../../services/APIClient'
import { currencyWithTwoDecimals } from '../../services/FormatterService'
import { withNavigate } from '../../../utils'

class CercReceivablesCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = { calendar: [] }
  }

  componentDidMount() {
    cercReceivableCalendar().then((resp) => {
      if (typeof resp !== 'undefined') {
        this.setState({ calendar: resp.result })
      }
    })
  }

  render() {
    if (typeof this.state.calendar === 'undefined') {
      this.props.navigate.push('/login/signin')
      return ''
    }
    if (this.state.calendar.length === 0) {
      return ''
    }

    let { calendar } = this.state
    let groupedByDate = _.orderBy(
      Object.entries(_.groupBy(calendar, (calendarItem) => calendarItem.date)),
      ([key]) => key,
      'desc',
    )

    return (
      <SimpleCard>
        {' '}
        {groupedByDate.map(([date, values]) => (
          <>
            <Container className="m-30">
              <h2>{date}</h2>
              <h6>Total - {currencyWithTwoDecimals(values.reduce((acc, value) => acc + value.content.total, 0))}</h6>
              {values.map((value) => (
                <p key={`${date}-${value.key.accountId}-${value.key.arrangementCode}`}>
                  {value.key.accountId} - {value.key.arrangementCode} - {currencyWithTwoDecimals(value.content.total)}
                </p>
              ))}
              <hr />
            </Container>
          </>
        ))}
      </SimpleCard>
    )
  }
}

export default withNavigate(CercReceivablesCalendar)
