import React from 'react'
import { Breadcrumb, SimpleCard } from '../../../matx'
import CustomerTableRow from './CustomerTableRow'
import { customersWithFilters } from '../../services/APIClient'
import PaginationTable from '../../../matx/components/PaginationTable'
import TableContent from '../../components/TableContent'

export default function Customers() {
  const columns = [
    'Document id',
    'Name',
    'Last Kyc Status',
    'First Paid Transaction Date',
    'Last Paid Transaction Date',
    'Number Of payin transactions',
    'Number of payout transactions',
  ]

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Customers' }]} />
      </div>
      <div className="m-sm-30">
        <SimpleCard>
          <PaginationTable
            queryItems={customersWithFilters}
            tableName="Customers"
            tableContent={(filteredItems) => (
              <TableContent items={filteredItems} columns={columns} Row={CustomerTableRow} />
            )}
            attributesToFilter={['name', 'documentId']}
            hasSailorSwitch={false}
          />
        </SimpleCard>
      </div>
    </div>
  )
}
