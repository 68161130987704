import React, { Component } from 'react'
import { Fragment } from 'react'
import { createReceivableDiffCsv } from '../../../services/APIClient'

class ReceivableDiffBySettlementDate extends Component {
  handleDownloadProvidersTransactionClick = (event) => {
    var startDate = document.getElementById('startDateReceivableDiff').value
    var endDate = document.getElementById('endDateReceivableDiff').value
    createReceivableDiffCsv(startDate, endDate)
  }

  render() {
    return (
      <Fragment>
        <div className="card-modal-report">
          <div style={{ paddingLeft: '30px' }}>
            <div>
              <label>All transactions recorded and compared to previous days</label>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <label className="labelCalendarDistance">Initial date:</label>
                <input type="date" id="startDateReceivableDiff" name="startDateReceivableDiff" />
              </div>
              <div className="col-md-4 col-4">
                <label className="labelCalendarDistance">End date:</label>
                <input type="date" id="endDateReceivableDiff" name="endDateReceivableDiff" />
              </div>
              <div className="col-md-4 col-4">
                <button className="buttonSendDate" onClick={this.handleDownloadProvidersTransactionClick}>
                  Download csv
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ReceivableDiffBySettlementDate
