import history from '../../../history.js'
import jwtAuthService from '../../services/JwtAuthService'
import { setUserData } from './UserActions'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export function loginWithEmailAndPassword({ email, password }) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
    })

    jwtAuthService
      .loginWithEmailAndPassword(email, password)
      .then((user) => {
        dispatch(setUserData(user))
        history.push({
          pathname: '/payin/transaction',
        })
        window.location.reload()

        return dispatch({
          type: LOGIN_SUCCESS,
        })
      })
      .catch((error) => {
        alert('email ou senha inválidos!')
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        })
      })
  }
}
