import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

export default function BankTransferProviderChargeDetails({ bankTransferProviderCharges }) {
  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          <TableCell className="px-0">Charge value</TableCell>
          <TableCell className="px-0">Charge value type</TableCell>
          <TableCell className="px-0">Minimum fixed charge value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bankTransferProviderCharges.map((charge, index) => (
          <TableRow key={index}>
            <TableCell>{charge.chargeValue}</TableCell>
            <TableCell>{charge.chargeValueType}</TableCell>
            <TableCell>{charge.minimumFixedChargeValue}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
