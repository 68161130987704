import React, { Component } from 'react'
import { Breadcrumb, SimpleCard } from '../../../matx'
import PayeeMerchantPaginationTable from './PayeeMerchantPaginationTable'

class PayeeMerchantPage extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Payee' }]} />
        </div>
        <div className="m-sm-30">
          <SimpleCard>
            <PayeeMerchantPaginationTable />
          </SimpleCard>
        </div>
      </div>
    )
  }
}

export default PayeeMerchantPage
