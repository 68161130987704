import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal } from 'react-bootstrap'
import IntermediaryTable from './IntermediaryTable'
import { Fragment } from 'react'

export function IntermediaryModalsFragment(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const accountId = props.accountId

  return (
    <Fragment>
      <Button className="btn-info" onClick={handleOpen}>
        Manage Intermediaries
      </Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Intermediaries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <IntermediaryTable accountId={accountId} />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
