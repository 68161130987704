import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

function CreditPeriods({ creditPeriods }) {
  const columns = ['Payment method', 'Days', 'Is business day', 'Accounting entry type', 'Effective date']

  return (
    <>
      <h5>Credit Periods:</h5>
      <div className="my-4">
        <Table style={{ whiteSpace: 'pre' }}>
          <TableHead>
            <TableRow>
              {columns.map((item) => (
                <TableCell key={item} className="px-0">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {creditPeriods.length > 0 ? (
              creditPeriods.map((creditPeriod) => (
                <CreditPeriodRow
                  key={`creditPeriod-${creditPeriod.paymentMethod}-${creditPeriod.effectiveDate}-${creditPeriod.accountingEntryType}`}
                  creditPeriod={creditPeriod}
                />
              ))
            ) : (
              <TableRow>
                <TableCell>No result</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

function CreditPeriodRow({ creditPeriod }) {
  return (
    <TableRow>
      <TableCell className="px-0 capitalize">{creditPeriod.paymentMethod}</TableCell>
      <TableCell className="px-0 capitalize">{creditPeriod.days}</TableCell>
      <TableCell className="px-0 capitalize">{creditPeriod.isBusinessDay.toString()}</TableCell>
      <TableCell className="px-0 capitalize">{creditPeriod.accountingEntryType}</TableCell>
      <TableCell className="px-0 capitalize">{creditPeriod.effectiveDate}</TableCell>
    </TableRow>
  )
}

export default CreditPeriods
