import moment from 'moment'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches
  }
  return false
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches
  }
  return false
}

export function getTimeDifference(date) {
  let difference = moment(new Date(), 'DD/MM/YYYY HH:mm:ss').diff(moment(date, 'DD/MM/YYYY HH:mm:ss')) / 1000

  if (difference < 60) return `${Math.floor(difference)} seconds`
  else if (difference < 3600) return `${Math.floor(difference / 60)} minutes`
  else if (difference < 86400) return `${Math.floor(difference / 3660)} hours`
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} days`
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} months`
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} years`
}

export function getQueryParam(prop) {
  var params = {}
  var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1))
  var definitions = search.split('&')
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2)
    params[parts[0]] = parts[1]
  })
  return prop && prop in params ? params[prop] : params
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ')
}

export const withNavigate = (Component) => {
  return (props) => {
    const navigate = useNavigate()
    return <Component navigate={(location) => navigate({ pathname: location })} {...props} />
  }
}

export const withRouter = (Component) => {
  return (props) => {
    const location = useLocation()
    const params = useParams()
    return <Component location={location} params={params} {...props} />
  }
}

export const withTheme = (Component) => {
  return (props) => {
    const theme = useTheme()
    return <Component theme={theme} {...props} />
  }
}
