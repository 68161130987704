import React from 'react'
import { SimpleCard } from '../../../../matx'
import AccountToApprovePaginationTable from './AccountToApprovePaginationTable'

const AccountToApproveTable = () => {
  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        <AccountToApprovePaginationTable />
      </SimpleCard>
    </div>
  )
}

export default AccountToApproveTable
