import React from 'react'
import { Breadcrumb } from '../../../../matx'
import AccountDetails from './accountDetails/AccountDetails'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useParams } from 'react-router-dom'

const AccountDetailsPage = () => {
  const { id } = useParams()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Account', path: '/account' }, { name: 'Details' }]} />
      </div>
      <AccountDetails id={id} />
    </div>
  )
}

export default AccountDetailsPage
