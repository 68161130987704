import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip, IconButton, ThemeProvider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { withStyles } from 'tss-react/mui'
import { connect } from 'react-redux'
import Sidenav from '../SharedComponents/Sidenav'
import Brand from '../SharedComponents/Brand'
import SidenavTheme from '../MatxTheme/SidenavTheme'
import { logoutUser } from '../../redux/actions/UserActions'

const IconButtonWhite = styled(IconButton)({
  root: {
    backgroundColor: 'transparent',
    padding: '5px',
  },
  color: '#FFFFFF',
})

const IconSmall = styled(Icon)({ root: { fontSize: '1rem' } })

class Layout1Sidenav extends Component {
  state = {
    sidenavToggleChecked: false,
    hidden: true,
  }

  handleSignOut = () => {
    this.props.logoutUser()
  }

  renderUser = () => {
    return (
      <div className="sidenav__user">
        <div className="username-photo">
          <IconSmall>person</IconSmall>
        </div>
        <div className="ml-8">
          <span className="username">User Name</span>
          <div className="user__menu">
            <Tooltip title="Sign out">
              <IconButtonWhite aria-label="Delete" className="" size="small" onClick={this.handleSignOut}>
                <IconSmall>exit_to_app</IconSmall>
              </IconButtonWhite>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }

  renderLogoSwitch = () => <Brand />

  render() {
    let { theme, settings } = this.props
    const sidenavTheme = settings.themes[settings.layout1Settings.leftSidebar.theme] || theme
    return (
      <ThemeProvider theme={sidenavTheme}>
        <SidenavTheme theme={sidenavTheme} settings={settings} />

        <div className="sidenav">
          <div className="sidenav__hold">
            {
              <Fragment>
                {this.renderLogoSwitch()}
                <Sidenav>{this.renderUser()}</Sidenav>
              </Fragment>
            }
          </div>
        </div>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  settings: state.layout.settings,
  logoutUser: PropTypes.func.isRequired,
})

export default withStyles(connect(mapStateToProps, { logoutUser })(Layout1Sidenav), () => ({}), { withTheme: true })
