import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

function FeesDetails({ fees }) {
  if (typeof fees === 'undefined') return <></>

  const columns = [
    'Fee Selector',
    'Fee Category',
    'Flow type',
    'Fee Cost Type',
    'Fee Currency',
    'Fee',
    'Minimum Fixed Fee Currency',
    'Minimum Fixed Fee',
    'Effective Date',
    'Comment',
  ]

  return (
    <div className="my-4">
      <h5>Fees:</h5>
      <Table style={{ whiteSpace: 'pre' }}>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <TableCell className="px-0">{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {fees.length > 0 ? (
            fees.map((fee) => <FeeRow key={fee.id} fee={fee} />)
          ) : (
            <TableRow>
              <TableCell>No result</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

function FeeRow({ fee }) {
  const getTransactionType = () => {
    if (fee.feeSelector === 'CREDIT_CARD_CHARGEBACK') {
      return fee.chargeback.chargeType + '/' + fee.chargeback.brand
    }
    return [fee.feeSelector, fee.creditCard?.installmentsCategory].filter((item) => item != null).join('/')
  }

  const getFeeCategory = () => {
    if (fee.feeSelector === 'CREDIT_CARD_CHARGEBACK') {
      return fee.chargeback.activeOn + '/' + fee.feeCategory
    }
    return fee.feeCategory
  }

  const content = [
    getTransactionType(),
    getFeeCategory(),
    fee.flowType,
    fee.feeCostType,
    fee.feeCurrency,
    fee.fee,
    fee.minimumFixedFeeCurrency,
    fee.minimumFixedFee,
    fee.effectiveDate,
    fee.comment,
  ]

  return (
    <TableRow key={`fee-${fee.id}`}>
      {content.map((column) => (
        <TableCell className="px-0 capitalize" align="left">
          {column}
        </TableCell>
      ))}
    </TableRow>
  )
}

export default FeesDetails
