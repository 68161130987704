import React from 'react'
import { Breadcrumb } from '../../../../matx'
import AccountTable from './AccountTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const Account = () => {
  let navigate = useNavigate()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Account' }]} />
      </div>
      <AccountTable />
      <div className="fab-main-btn" variant="extended" onClick={() => navigate(`/register/account`)}>
        <div className="fab-icon">
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
    </div>
  )
}

export default Account
