import Select from 'react-select'

export default function AddressSection({
  countryList,
  countryCode,
  state,
  city,
  zipCode,
  street,
  complement,
  number,
  neighbourhood,
  onChangeCountry,
  onUpdateAddress,
}) {
  function findSelectedEnum(selectedField, allEnums) {
    return allEnums.find(({ value }) => value === selectedField)
  }

  return (
    <>
      <tr>
        <td colSpan="2">
          <h4>Address:</h4>
        </td>
      </tr>
      <tr>
        <td>Country</td>
        <td>
          <Select
            name="country"
            id="country"
            required
            className="select-register-input"
            options={countryList}
            onChange={onChangeCountry}
            placeholder="Country"
            value={[findSelectedEnum(countryCode, countryList)]}
          />
        </td>
      </tr>
      <tr>
        <td>State</td>
        <td>
          <input
            className="account-register-input-text"
            name="state"
            maxLength="20"
            defaultValue={state}
            required
            onChange={onUpdateAddress}
            id="state"
            type="text"
            placeholder="State"
          />
        </td>
      </tr>
      <tr>
        <td>City</td>
        <td>
          <input
            className="account-register-input-text"
            name="city"
            required
            defaultValue={city}
            maxLength="20"
            onChange={onUpdateAddress}
            id="city"
            type="text"
            placeholder="City"
          />
        </td>
      </tr>
      <tr>
        <td>Zip Code</td>
        <td>
          <input
            className="account-register-input-text"
            name="zipCode"
            required
            defaultValue={zipCode}
            maxLength="20"
            onChange={onUpdateAddress}
            id="zipCode"
            type="text"
            placeholder="Zip Code"
          />
        </td>
      </tr>
      <tr>
        <td>Street</td>
        <td>
          <input
            className="account-register-input-text"
            name="street"
            maxLength="100"
            defaultValue={street}
            required
            onChange={onUpdateAddress}
            id="street"
            type="text"
            placeholder="Street"
          />
        </td>
      </tr>
      <tr>
        <td>Number</td>
        <td>
          <input
            className="account-register-input-text"
            name="number"
            maxLength="20"
            defaultValue={number}
            required
            onChange={onUpdateAddress}
            id="number"
            type="text"
            placeholder="Number"
          />
        </td>
      </tr>
      <tr>
        <td>Complement</td>
        <td>
          <input
            className="account-register-input-text"
            name="complement"
            maxLength="50"
            required
            defaultValue={complement}
            onChange={onUpdateAddress}
            id="complement"
            type="text"
            placeholder="Complement"
          />
        </td>
      </tr>
      <tr>
        <td>Neighbourhood</td>
        <td>
          <input
            className="account-register-input-text"
            name="neighbourhood"
            defaultValue={neighbourhood}
            maxLength="20"
            required
            onChange={onUpdateAddress}
            id="neighbourhood"
            type="text"
            placeholder="Neighbourhood"
          />
        </td>
      </tr>
    </>
  )
}
