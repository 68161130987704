import React, { useEffect, useState } from 'react'
import { getProvider, createProviderCharge, getAllAccountingEnums } from '../../../services/APIClient'
import { useParams } from 'react-router-dom'
import MultipleItemsInput from '../../accounts/accounts-manager/inputs/MultipleItemsInput'
import { Container, Form } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'
import PixProviderChargeInsert from './PixProviderChargeInsert'
import BoletoProviderChargeInsert from './BoletoProviderChargeInsert'
import IdentityProviderChargeInsert from './IdentityProviderChargeInsert'
import Loading from '../../../components/Loading'
import BankTransferProviderChargeInsert from './BankTransferProviderChargeInsert'

export default function ProviderChargeEdit() {
  const { providerId } = useParams()

  const [provider, setProvider] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [providerChargeBeingInserted, setProviderChargeBeingInserted] = useState({
    chargeType: null,
    chargeInDays: null,
    isChargeBusinessDays: null,
    chargeRelativeOf: null,
    effectiveDate: null,
    minimumTotalChargeAmount: 0,
    charges: [],
  })

  useEffect(() => {
    getProvider(providerId).then((response) => {
      setProvider(response)
    })
  }, [providerId])

  const onChangeProviderChargeProperty = (propertyName, value) => {
    providerChargeBeingInserted[propertyName] = value
    setProviderChargeBeingInserted({ ...providerChargeBeingInserted })
  }

  const onSave = (event) => {
    event.preventDefault()
    setIsLoading(true)
    createProviderCharge(providerId, providerChargeBeingInserted)
    setIsLoading(false)
  }

  return (
    <div className="m-sm-30">
      <div className="m-sm-30">
        <Form onSubmit={onSave}>
          <ProviderCharge
            provider={provider}
            providerChargeBeingInserted={providerChargeBeingInserted}
            onChangeProviderChargeProperty={onChangeProviderChargeProperty}
          />
          <div>
            <button
              type="submit"
              className="btn-submit center"
              disabled={isLoading || providerChargeBeingInserted.charges.length === 0}
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}

function ProviderCharge({ provider, providerChargeBeingInserted, onChangeProviderChargeProperty }) {
  const toEnumSelector = (items) => items.map((item) => ({ value: item, label: item }))
  const [enums, setEnums] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAllAccountingEnums().then((response) => {
      setEnums({
        serviceTypes: toEnumSelector(response.serviceTypes),
        chargeTypes: toEnumSelector(response.chargeTypes),
        queryTypes: toEnumSelector(response.queryTypes),
        chargeValueTypes: toEnumSelector(response.chargeValueTypes),
        chargesRelativeOf: toEnumSelector(response.chargesRelativeOf),
        boletoChargedStatuses: toEnumSelector(response.boletoChargedStatuses),
      })
      setIsLoading(false)
    })
  }, [])

  const createNewCharge = (chargeToCreate) => {
    onChangeProviderChargeProperty('charges', chargeToCreate)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">Provider:</td>
              <td>{provider.serviceType}</td>
            </tr>
            <tr>
              <td className="column-table">Charge type</td>
              <td>
                <Select
                  name="chargeType"
                  id="chargeType"
                  onChange={(event) => onChangeProviderChargeProperty('chargeType', event.value)}
                  options={enums.chargeTypes}
                  placeholder="type"
                  value={enums.chargeTypes.filter((option) => option.value === providerChargeBeingInserted.chargeType)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Charge in days:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="days"
                  maxLength="2"
                  onChange={(event) => onChangeProviderChargeProperty('chargeInDays', event.target.value)}
                  id="days"
                  type="number"
                  placeholder="Days"
                  defaultValue={providerChargeBeingInserted.chargeInDays}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Business Day:</td>
              <td>
                <div>
                  <input
                    type="radio"
                    value="true"
                    name={`isBusinessDay`}
                    defaultChecked={providerChargeBeingInserted.isChargeBusinessDays}
                    onChange={(event) => onChangeProviderChargeProperty('isChargeBusinessDays', event.target.value)}
                    required
                  />{' '}
                  Yes
                  <input
                    type="radio"
                    value="false"
                    name={`isBusinessDay`}
                    defaultChecked={!providerChargeBeingInserted.isChargeBusinessDays}
                    onChange={(event) => onChangeProviderChargeProperty('isChargeBusinessDays', event.target.value)}
                    required
                  />{' '}
                  No
                </div>
              </td>
            </tr>
            <tr>
              <td className="column-table">Charge relative of</td>
              <td>
                <Select
                  name="chargeRelativeOf"
                  id="chargeRelativeOf"
                  onChange={(event) => onChangeProviderChargeProperty('chargeRelativeOf', event.value)}
                  options={enums.chargesRelativeOf}
                  placeholder="Charge relative of"
                  value={enums.chargesRelativeOf.filter(
                    (option) => option.value === providerChargeBeingInserted.chargeRelativeOf,
                  )}
                  required
                />
              </td>
            </tr>
            <tr>
              <td>Minimum total chargeAmount</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="minimumTotalChargeAmount"
                  onChange={(event) => onChangeProviderChargeProperty('minimumTotalChargeAmount', event.target.value)}
                  id="minimumTotalChargeAmount"
                  type="number"
                  placeholder="minimumTotalChargeAmount"
                  defaultValue={providerChargeBeingInserted.minimumTotalChargeAmount}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Effective date:</td>
              <td>
                <input
                  name="effectiveDate"
                  onChange={(event) => onChangeProviderChargeProperty('effectiveDate', event.target.value)}
                  id="effectiveDate"
                  type="date"
                  placeholder="Effective Date"
                  defaultValue={providerChargeBeingInserted.effectiveDate}
                  required
                />
              </td>
            </tr>
          </tbody>
        </BTable>
        <MultipleItemsInput
          items={providerChargeBeingInserted.charges}
          title="Charges"
          onChange={(charges) => createNewCharge(charges)}
        >
          {provider.serviceType === 'PIX' ? (
            <PixProviderChargeInsert chargeValueTypes={enums.chargeValueTypes} />
          ) : provider.serviceType === 'BOLETO' ? (
            <BoletoProviderChargeInsert
              chargeValueTypes={enums.chargeValueTypes}
              boletoChargedStatuses={enums.boletoChargedStatuses}
            />
          ) : provider.serviceType === 'BANK_TRANSFER' ? (
            <BankTransferProviderChargeInsert chargeValueTypes={enums.chargeValueTypes} />
          ) : provider.serviceType === 'IDENTITY' ? (
            <IdentityProviderChargeInsert queryTypes={enums.queryTypes} />
          ) : (
            <></>
          )}
        </MultipleItemsInput>
      </Container>
    </>
  )
}
