import { useEffect, useState } from 'react'
import { SimpleCard } from '../../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import { getChargebackDetails } from '../../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { convertToBrazilianTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../../matx'
import { CircularProgress } from '@mui/material'

export default function ChargebackDetails() {
  const { id } = useParams()

  const [chargebackDetails, setChargebackDetails] = useState(null)
  const [processes, setProcesses] = useState([])

  useEffect(() => {
    async function update() {
      const details = await getChargebackDetails(id)
      setChargebackDetails(details)
      setProcesses(details.processes)
    }
    update()
  }, [id])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Refunds', path: '/payin/refund' }, { name: 'Details' }]} />
      </div>
      <SimpleCard title="Chargeback Details">
        {chargebackDetails === null ? <CircularProgress /> : <Details chargebackDetails={chargebackDetails} />}
        {processes.map((item) => (
          <ProcessDetails
            key={item.process.id}
            process={item.process}
            daysAfterReleaseDate={item.daysAfterReleaseDate}
          />
        ))}
      </SimpleCard>
    </div>
  )
}

function Details({ chargebackDetails }) {
  return (
    <div>
      <Container>
        <h5>Chargeback information:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Chargeback Id</td>
              <td>{chargebackDetails.chargeback.id}</td>
            </tr>
            <tr>
              <td>Chargeback NSU</td>
              <td>{chargebackDetails.chargeback.providerNsu}</td>
            </tr>
            <tr>
              <td>First process number</td>
              <td>{chargebackDetails.chargeback.firstProcessNumber}</td>
            </tr>
            <tr>
              <td>Opening date</td>
              <td>{chargebackDetails.chargeback.openingDate}</td>
            </tr>
            <tr>
              <td>Incoming date</td>
              <td>{chargebackDetails.chargeback.incomingDate}</td>
            </tr>
            <tr>
              <td>Merchant dispute date</td>
              <td>{chargebackDetails.merchantDisputeDate}</td>
            </tr>
            <tr>
              <td>Deadline date</td>
              <td>{chargebackDetails.chargeback.deadlineDate}</td>
            </tr>
            <tr>
              <td>Reason code</td>
              <td>{chargebackDetails.chargeback.reasonCode}</td>
            </tr>
            <tr>
              <td>Issuer name</td>
              <td>{chargebackDetails.chargeback.issuerName}</td>
            </tr>
            <tr>
              <td>Issuer message</td>
              <td>{chargebackDetails.chargeback.issuerMessage}</td>
            </tr>
            <tr>
              <td>Suggested document</td>
              <td>{chargebackDetails.chargeback.suggestedDocument}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{chargebackDetails.chargeback.status}</td>
            </tr>
            <tr>
              <td>Process cycle</td>
              <td>{chargebackDetails.chargeback.processCycle}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{currencyWithTwoDecimals(chargebackDetails.chargeback.amount)}</td>
            </tr>
            <tr>
              <td>Resolution chargeback process number</td>
              <td>{chargebackDetails.chargeback.resolutionChargebackProcessNumber}</td>
            </tr>
            <tr>
              <td>Solution date</td>
              <td>{chargebackDetails.chargeback.solutionDate}</td>
            </tr>
            <tr>
              <td>Solution description</td>
              <td>{chargebackDetails.chargeback.solutionDescription}</td>
            </tr>
            <tr>
              <td>Solution net amount</td>
              <td>{currencyWithTwoDecimals(chargebackDetails.chargeback.solutionNetAmount)}</td>
            </tr>
            <tr>
              <td>Payment provider chargeback fee</td>
              <td>{currencyWithTwoDecimals(chargebackDetails.chargeback.paymentProviderChargebackFee)}</td>
            </tr>
            <tr>
              <td>Merchant chargeback fee</td>
              <td>{currencyWithTwoDecimals(chargebackDetails.chargeback.merchantChargebackFee)}</td>
            </tr>
            <tr>
              <td>Merchant chargeback additional fee</td>
              <td>{currencyWithTwoDecimals(chargebackDetails.chargeback.merchantChargebackAdditionalFee)}</td>
            </tr>
            <tr>
              <td>Updated at</td>
              <td>{convertToBrazilianTimeZone(chargebackDetails.chargeback.updatedAt)}</td>
            </tr>
            <tr>
              <td>Created at</td>
              <td>{convertToBrazilianTimeZone(chargebackDetails.chargeback.createdAt)}</td>
            </tr>
          </tbody>
        </BTable>

        <h5>Transaction information:</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Merchant soft descriptor</td>
              <td>
                <NavLink to={`/account/${chargebackDetails.chargeback.paymentTransaction.receiverAccount}`} end>
                  {chargebackDetails.merchantSoftDescriptor}
                </NavLink>
              </td>
            </tr>
            <tr>
              <td>Transaction id</td>
              <td>
                <NavLink to={`/payin/transaction/${chargebackDetails.chargeback.paymentTransaction.id}`} end>
                  {chargebackDetails.chargeback.paymentTransaction.id}
                </NavLink>
              </td>
            </tr>
            <tr>
              <td>Provider TID</td>
              <td>{chargebackDetails.chargeback.providerTid}</td>
            </tr>
            <tr>
              <td>ARN</td>
              <td>{chargebackDetails.chargeback.arn}</td>
            </tr>
            <tr>
              <td>Authorization code</td>
              <td>{chargebackDetails.chargeback.authorizationCode}</td>
            </tr>
            <tr>
              <td>Brand</td>
              <td>{chargebackDetails.brand}</td>
            </tr>
            <tr>
              <td>Number of installments</td>
              <td>{chargebackDetails.installments}</td>
            </tr>
            <tr>
              <td>Transaction created date</td>
              <td>{chargebackDetails.transactionCreatedDate}</td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </div>
  )
}

function ProcessDetails({ process, daysAfterReleaseDate }) {
  return (
    <div>
      <Container>
        <h5>{`Chargeback process ${process.processNumber} information:`}</h5>
        <BTable striped bordered hover>
          <tbody>
            <tr>
              <td>Process Id</td>
              <td>{process.id}</td>
            </tr>
            <tr>
              <td>Process number</td>
              <td>{process.processNumber}</td>
            </tr>
            <tr>
              <td>Situation description</td>
              <td>{process.situationDescription}</td>
            </tr>
            <tr>
              <td>Debit reason description</td>
              <td>{process.debitReasonDescription}</td>
            </tr>
            <tr>
              <td>Solution</td>
              <td>{process.solution}</td>
            </tr>
            <tr>
              <td>Solution date</td>
              <td>{process.solutionDate}</td>
            </tr>
            <tr>
              <td>Release date</td>
              <td>{process.releaseDate}</td>
            </tr>
            <tr>
              <td>Days after release date</td>
              <td>{daysAfterReleaseDate}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>{currencyWithTwoDecimals(process.amount)}</td>
            </tr>
            <tr>
              <td>Last document situation</td>
              <td>{process.lastDocumentSituation}</td>
            </tr>
            <tr>
              <td>Updated at</td>
              <td>{convertToBrazilianTimeZone(process.updatedAt)}</td>
            </tr>
            <tr>
              <td>Created at</td>
              <td>{convertToBrazilianTimeZone(process.createdAt)}</td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </div>
  )
}
