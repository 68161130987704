import SignIn from './SignIn'

const settings = {
  activeLayout: 'layout1',
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: 'close',
    },
  },
  layout2Settings: {
    mode: 'full',
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
}

const loginRoutes = [
  {
    path: '/login/signin',
    element: <SignIn />,
    settings,
  },
]

export default loginRoutes
