import React, { Component } from 'react'
import { Breadcrumb } from '../../../../matx'
import AccountToApproveTable from './AccountToApproveTable'

class AccountToApprove extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'AccountsToApprove' }]} />
        </div>
        <AccountToApproveTable />
      </div>
    )
  }
}

export default AccountToApprove
