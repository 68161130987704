import PayoutTransactionTableContent from './PayoutTransactionTableContent'
import { payoutTransactionWithFilters } from '../../services/APIClient'
import PaginationTable from '../../../matx/components/PaginationTable'

function PayoutTransactionPaginationTable() {
  return (
    <PaginationTable
      queryItems={payoutTransactionWithFilters}
      tableName="Payout Transactions"
      tableContent={(filteredItems) => <PayoutTransactionTableContent items={filteredItems} />}
      attributesToFilter={['customerName', 'merchantSoftDescriptor']}
    />
  )
}

export default PayoutTransactionPaginationTable
