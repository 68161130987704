import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { setLayoutSettings } from '../../redux/actions/LayoutActions'
import { withStyles } from 'tss-react/mui'
import Scrollbar from 'react-perfect-scrollbar'
import { isMdScreen, classList, withTheme } from '../../../utils'
import { renderRoutes } from '../../../matx/react-router-config'
import Layout1Sidenav from './Layout1Sidenav'
import AppContext from '../../appContext'

class Layout1 extends Component {
  componentDidMount() {
    if (isMdScreen()) {
      this.updateSidebarMode({ mode: 'close' })
    }
  }

  updateSidebarMode = (sidebarSettings) => {
    let { settings, setLayoutSettings } = this.props
    setLayoutSettings({
      ...settings,
      layout1Settings: {
        ...settings.layout1Settings,
        leftSidebar: {
          ...settings.layout1Settings.leftSidebar,
          ...sidebarSettings,
        },
      },
    })
  }

  render() {
    let { settings, classes, theme } = this.props

    let { layout1Settings } = settings
    let layoutClasses = {
      [classes.layout]: true,
      [`${settings.activeLayout} sidenav-${layout1Settings.leftSidebar.mode} theme-${theme.palette.type} flex`]: true,
    }
    return (
      <AppContext.Consumer>
        {({ routes }) => (
          <div className={classList(layoutClasses)}>
            {layout1Settings.leftSidebar.show && <Layout1Sidenav />}

            <div className="content-wrap position-relative">
              {settings.perfectScrollbar && (
                <Scrollbar className="scrollable-content">
                  <div className="content">{renderRoutes(routes)}</div>
                  <div className="my-auto" />
                </Scrollbar>
              )}
            </div>
          </div>
        )}
      </AppContext.Consumer>
    )
  }
}

Layout1.propTypes = {
  settings: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  setLayoutSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
})

export default withTheme(
  withStyles(connect(mapStateToProps, { setLayoutSettings })(Layout1), (theme) => ({
    layout: { backgroundColor: theme.palette.background.default },
  })),
)
