import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

export default function IdentityProviderChargeDetails({ identityProviderCharges }) {
  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          <TableCell className="px-0">Charge value</TableCell>
          <TableCell className="px-0">Tier minimum</TableCell>
          <TableCell className="px-0">Tier maximum</TableCell>
          <TableCell className="px-0">Query type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {identityProviderCharges.map((charge, index) => (
          <TableRow key={index}>
            <TableCell>{charge.chargeValue}</TableCell>
            <TableCell>{charge.tierMin}</TableCell>
            <TableCell>{charge.tierMax}</TableCell>
            <TableCell>{charge.queryType}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
