import { useEffect, useState, useCallback } from 'react'
import { Grid, Switch, TablePagination } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import Loading from '../../app/components/Loading'

function PaginationTable({ queryItems, tableName, tableContent, attributesToFilter, hasSailorSwitch = true }) {
  const [itemsList, setItemsList] = useState([])
  const [listLength, setListLength] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(200)
  const [page, setPage] = useState(0)
  const [itemsFiltered, setItemsFiltered] = useState(null)
  const [filters, setFilters] = useState([])
  const [query, setQuery] = useState('')
  const [isSailor, setIsSalor] = useState(false)
  const [queryHasFailed, setQueryHasFailed] = useState(false)

  const filterItems = useCallback(
    (filters, allItems) => {
      if (filters.length === 0) {
        setItemsFiltered(allItems)
        return
      }
      let reducer = (previousValue, currentValue) =>
        previousValue.filter((items) => {
          return attributesToFilter.some((attributeToFilter) => filterNullable(items[attributeToFilter], currentValue))
        })

      let filtered = filters.map((filter) => filter.toLowerCase()).reduce(reducer, allItems)
      if (filtered.length > 0) {
        setItemsFiltered(filtered)
      } else {
        toast.info(`No item found with these filters on this page, we're back with all items!`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setItemsFiltered(allItems)
      }
    },
    [attributesToFilter],
  )

  const updateRowsPerPage = useCallback(
    (page, rowsPerPage, isSailor) => {
      setPage(page)
      setRowsPerPage(rowsPerPage)
      setListLength(0)
      setItemsList([])
      filterItems([], null)

      queryItems(page, rowsPerPage, { isSailor })
        .then((resp) => {
          if (typeof resp !== 'undefined') {
            setListLength(resp.totalElements)
            setItemsList(resp.content)
            filterItems(filters, resp.content)
          }
        })
        .catch(() => setQueryHasFailed(true))
    },
    [filterItems, filters, queryItems],
  )

  useEffect(() => {
    updateRowsPerPage(page, rowsPerPage, isSailor)
  }, [page, rowsPerPage, isSailor, updateRowsPerPage])

  const handleChangeRowsPerPage = (event) => {
    updateRowsPerPage(page, +event.target.value, isSailor)
  }

  const handleChangePage = (_, newPage) => {
    updateRowsPerPage(newPage, rowsPerPage, isSailor)
  }

  const changeHandler = (event) => {
    setQuery(event.target.value)
  }

  const removeFilterHandler = (_, filter) => {
    let newArray = [...filters]
    let index = newArray.indexOf(filter)
    if (index !== -1) {
      newArray.splice(index, 1)
      setFilters(newArray)
    }
    filterItems(newArray, itemsList)
  }

  const saveFilter = () => {
    let newFilters = [...filters, query]
    setQuery('')
    setFilters(newFilters)
    filterItems(newFilters, itemsList)
  }

  function renderFilterList() {
    return filters.map((filter) => (
      <button
        key={`buttonFilters ${filter}`}
        className={`buttonFilter border-radius-4 px-10 py-10px`}
        onClick={(event) => removeFilterHandler(event, filter)}
      >
        {filter}
      </button>
    ))
  }

  function filterNullable(value, currentValue) {
    if (typeof value === 'undefined' || value === '') {
      return false
    }
    return value.toLowerCase().includes(currentValue)
  }

  function hanleIsSailorChange() {
    setIsSalor(!isSailor)
  }

  if (queryHasFailed) {
    return <div>Something went wrong</div>
  }

  return (
    <div>
      <div
        className="MuiToolbar-root MuiToolbar-regular jss8044 MuiToolbar-gutters"
        role="toolbar"
        aria-label="Table Toolbar"
      >
        <div className="jss8046 titleWithSearchBox">
          <div className="jss8050" aria-hidden="true">
            <h6 className="MuiTypography-root jss8051 MuiTypography-h6">{tableName}</h6>
          </div>
        </div>
        {hasSailorSwitch ? (
          <div>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Not sailor</Grid>
              <Grid item>
                <Switch checked={isSailor} onChange={hanleIsSailorChange} color="secondary" />
              </Grid>
              <Grid item>Sailor</Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ height: '30px' }}></div>
        )}
        <div className="main_search">
          {renderFilterList()}
          <input value={query} className="main_search_input" onChange={changeHandler} placeholder="Buscar!" />
          <button className="search_button" onClick={saveFilter}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      {itemsFiltered === null ? (
        <Loading />
      ) : (
        <div className="w-100 overflow-auto">
          {tableContent(itemsFiltered)}

          <TablePagination
            className="px-16"
            rowsPerPageOptions={[200, parseInt(listLength / 4), parseInt(listLength / 2), parseInt(listLength)]}
            component="div"
            count={listLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima página',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  )
}

export default PaginationTable
