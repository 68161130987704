import Select from 'react-select'
import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import { booleanChoice } from '../../../../services/SelectEnumRepository'

class PartnerInput extends Component {
  constructor(props) {
    super(props)

    this.state = props.item || {}
    this.documentTypes = props.documentTypes
    this.showParticipation = props.showParticipation
    this.pepOptions = booleanChoice
    this.onChange = props.onChange
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangePep = (event) => {
    this.onAnyChanged({ pep: event.value })
  }

  onChangeName = (event) => {
    this.onAnyChanged({ name: event.target.value })
  }

  onChangeDocumentType = (event) => {
    this.onAnyChanged({ documentType: event.value })
  }

  onChangeDocument = (event) => {
    this.onAnyChanged({ document: event.target.value })
  }

  onChangeParticipation = (event) => {
    this.onAnyChanged({ participation: event.target.value })
  }

  onChangeDocumentIssuer = (event) => {
    this.onAnyChanged({ documentIssuer: event.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td className="column-table">PEP:</td>
                <td>
                  <Select
                    name="pep"
                    className="select-register-input"
                    id="pep"
                    onChange={this.onChangePep}
                    options={this.pepOptions}
                    classNamePrefix="pep"
                    placeholder="PEP"
                    value={this.pepOptions.filter((option) => option.value === this.state.pep)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Name:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="name"
                    onChange={this.onChangeName}
                    id="name"
                    type="text"
                    placeholder="Name"
                    defaultValue={this.state.name}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Document Type:</td>
                <td>
                  <Select
                    name="documentTypes"
                    className="select-register-input"
                    options={this.documentTypes}
                    onChange={this.onChangeDocumentType}
                    classNamePrefix="Document Type"
                    placeholder="Document Type"
                    value={this.documentTypes.filter((option) => option.value === this.state.documentType)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Document:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="document"
                    onChange={this.onChangeDocument}
                    id="document"
                    type="text"
                    placeholder="Document"
                    defaultValue={this.state.document}
                    required
                  />
                </td>
              </tr>
              {this.showParticipation ? (
                <tr>
                  <td className="column-table">Participation:</td>
                  <td>
                    <input
                      className="account-register-input-text"
                      name="participation"
                      onChange={this.onChangeParticipation}
                      id="participation"
                      type="text"
                      placeholder="Participation"
                      defaultValue={this.state.participation}
                    />
                  </td>
                </tr>
              ) : (
                <React.Fragment />
              )}
              <tr>
                <td className="column-table">Document Issuer:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="documentIssuer"
                    onChange={this.onChangeDocumentIssuer}
                    id="documentIssuer"
                    type="text"
                    placeholder="Document Issuer"
                    defaultValue={this.state.documentIssuer}
                    required
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default PartnerInput
