import { useState, useEffect } from 'react'
import { createGuaranteeDepositReleaseCsv } from '../../../services/APIClient'

export default function GuaranteeDepositReleaseCsv() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const today = new Date()
  const maxReportDate = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0]

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  const handleDownloadClick = () => {
    setIsLoading(true)
    createGuaranteeDepositReleaseCsv(startDate, endDate)
      .catch(() => alert('Something went wrong. Report could not be downloaded. Please, contact system administrator.'))
      .finally(() => setIsLoading(false))
  }

  const onDateChange = (propertyName, newValue) => {
    if (propertyName === 'startDate') {
      setStartDate(newValue)
    } else if (propertyName === 'endDate') {
      setEndDate(newValue)
    }
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Find all guarantee deposit released in the selected period</label>
        </div>
        <div className="row w-100 mx-auto">
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">Initial date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={(event) => onDateChange('startDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">End date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              onChange={(event) => onDateChange('endDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-4 col-4">
            <button
              className="buttonSendDate"
              onClick={handleDownloadClick}
              disabled={startDate === null || endDate === null || isLoading}
            >
              Download csv
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
