import { NavLink } from 'react-router-dom'
import { convertToBrazilianTimeZone } from '../../services/FormatterService'
import BTable from 'react-bootstrap/Table'
import { Container } from 'react-bootstrap'

function PayeeMerchantTable({ payeeMerchantDetails }) {
  return (
    <Container className="white-table-border-radius">
      <h5>Payee merchant:</h5>
      <BTable striped borderless hover>
        <tbody>
          <tr>
            <td>Id</td>
            <td>
              <NavLink to={`/payee-merchant/${payeeMerchantDetails.id}`} end>
                {payeeMerchantDetails.id}
              </NavLink>
            </td>
          </tr>
          <tr>
            <td>Person type</td>
            <td>{payeeMerchantDetails.personType}</td>
          </tr>
          <tr>
            <td>Document id</td>
            <td>{payeeMerchantDetails.documentId}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{payeeMerchantDetails.name}</td>
          </tr>
          <tr>
            <td>Birth date</td>
            <td>{payeeMerchantDetails.birthDate}</td>
          </tr>
          <tr>
            <td>Customer result</td>
            <td>{payeeMerchantDetails.customerResult}</td>
          </tr>
          <tr>
            <td>Customer id</td>
            <td>
              <NavLink to={`/customers/${payeeMerchantDetails.customerId}`} end>
                {payeeMerchantDetails.customerId}
              </NavLink>
            </td>
          </tr>
          <tr>
            <td>Bank account type</td>
            <td>{payeeMerchantDetails.bankAccountType}</td>
          </tr>
          <tr>
            <td>Payee Bank Result</td>
            <td>{payeeMerchantDetails.payeeBankResult}</td>
          </tr>
          <PayeeAddressDetails phones={payeeMerchantDetails.phones} address={payeeMerchantDetails.address} />
          <tr>
            <td>Phones</td>
            <td>{payeeMerchantDetails.phones?.map((phone) => phone.rawNumber || phone.number).join(', ')}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{payeeMerchantDetails.email}</td>
          </tr>
          <PayeeShareHolderDetails shareHolders={payeeMerchantDetails.shareHolders} />
          {payeeMerchantDetails.payeeBankAccount.bankAccountDetails.bankInfoType === 'PIX' ? (
            <>
              <tr>
                <td>Key type</td>
                <td>{payeeMerchantDetails.payeeBankAccount.bankAccountDetails.keyType}</td>
              </tr>
              <tr>
                <td>Pix key</td>
                <td>{payeeMerchantDetails.payeeBankAccount.bankAccountDetails.pixKey}</td>
              </tr>
            </>
          ) : (
            <>
              <>
                <tr>
                  <td>Bank Code</td>
                  <td>{payeeMerchantDetails.payeeBankAccount.bankAccountDetails.bankAccount.bankCode}</td>
                </tr>
                <tr>
                  <td>Branch</td>
                  <td>{payeeMerchantDetails.payeeBankAccount.bankAccountDetails.bankAccount.branch}</td>
                </tr>
                <tr>
                  <td>Account Number</td>
                  <td>{payeeMerchantDetails.payeeBankAccount.bankAccountDetails.bankAccount.accountNumber}</td>
                </tr>
                <tr>
                  <td>Verifying Digit</td>
                  <td>{payeeMerchantDetails.payeeBankAccount.bankAccountDetails.bankAccount.verifyingDigit}</td>
                </tr>
              </>
            </>
          )}
          <tr>
            <td>Created At</td>
            <td>{convertToBrazilianTimeZone(payeeMerchantDetails.createdAt)}</td>
          </tr>
        </tbody>
      </BTable>
    </Container>
  )
}

function PayeeAddressDetails({ address }) {
  return (
    <tr>
      <td>Address</td>
      {address !== null && address !== undefined ? (
        <td>
          Street name: {address?.streetName}
          <br />
          Number: {address?.number}
          <br />
          Complement: {address?.complement}
          <br />
          District: {address?.district}
          <br />
          City: {address?.city}
          <br />
          State: {address?.state}
          <br />
          ZipCode: {address?.zipCode}
          <br />
          Type: {address?.type}
          <br />
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  )
}

function PayeeShareHolderDetails({ shareHolders }) {
  return (
    <tr>
      <td>Share Holders</td>
      <td>
        {shareHolders.map((shareHolder) => {
          return (
            <div key={shareHolder.documentId}>
              documentId: {shareHolder.documentId}
              <br />
              Person type: {shareHolder.personType}
              <br />
              name: {shareHolder.name}
              <br />
              email: {shareHolder.email}
              <br />
              birthdate: {shareHolder.birthDate}
              <br />
              Ownership percent: {shareHolder.ownershipPercent}
              <br />
              --
            </div>
          )
        })}
      </td>
    </tr>
  )
}

export default PayeeMerchantTable
