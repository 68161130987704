import React, { useEffect, useState } from 'react'
import { getAllChargesFromProvider } from '../../services/APIClient'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../matx'
import Loading from '../../components/Loading'
import ProviderChargeDetail from './details/ProviderChargeDetail'
import ProviderChargeEdit from './edit/ProviderChargeEdit'

export default function ProviderDetailsPage() {
  const { providerId } = useParams()

  const [allProviderChargeConfiguration, setAllProviderChargeConfiguration] = useState(null)
  const [showChargeCreation, setShowChargeCreation] = useState(false)

  useEffect(() => {
    getAllChargesFromProvider(providerId).then((response) => {
      setAllProviderChargeConfiguration(
        response.sort(
          (a, b) =>
            new Date(b.providerChargeConfiguration.effectiveDate) -
            new Date(a.providerChargeConfiguration.effectiveDate),
        ),
      )
    })
  }, [providerId])

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Providers', path: '/providers' }, { name: 'Details' }]} />
      </div>
      <div className="m-sm-30">
        <div className="First card">
          {allProviderChargeConfiguration === null ? (
            <Loading />
          ) : (
            allProviderChargeConfiguration.map(({ providerChargeConfiguration, charges }) => {
              return (
                <div key={providerChargeConfiguration.id}>
                  <ProviderChargeDetail providerChargeConfiguration={providerChargeConfiguration} charges={charges} />
                </div>
              )
            })
          )}
        </div>
        {showChargeCreation ? (
          <ProviderChargeEdit />
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-success center"
              style={{ display: 'block' }}
              onClick={() => setShowChargeCreation(!showChargeCreation)}
            >
              Create new fee
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
