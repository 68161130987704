import React from 'react'
import { Breadcrumb } from '../../../matx'
import { useParams } from 'react-router-dom'
import { SettlementDetails } from './SettlementDetails'

export default function SettlementDetailsPage(props) {
  const { id } = useParams()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Settlements', path: '/settlements' }, { name: 'Details' }]} />
      </div>
      <SettlementDetails id={id} />
    </div>
  )
}
