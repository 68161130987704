import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal } from 'react-bootstrap'
import { Fragment } from 'react'
import CredentialsCreator from './CredentialsCreator'

export function CredentialsModalsFragment({ accountId, externalizedId, softDescriptor }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <Button className="btn-success" onClick={handleOpen}>
        Manage Credentials
      </Button>
      <Modal size="lg" show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <CredentialsCreator accountId={accountId} externalizedId={externalizedId} softDescriptor={softDescriptor} />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
