import React from 'react'
import { SimpleCard } from '../../../../matx'
import { refundPagination } from '../../../services/APIClient'
import PayinRefundTableContent from './PayinRefundTableContent'
import PaginationTable from '../../../../matx/components/PaginationTable'

export default function RefundTable() {
  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        <PaginationTable
          queryItems={refundPagination}
          tableName="Refunds"
          tableContent={(filteredItems) => <PayinRefundTableContent items={filteredItems} />}
          attributesToFilter={['softDescriptor', 'customerName', 'status']}
        />
      </SimpleCard>
    </div>
  )
}
