import ProviderDetailsPage from './ProviderDetailsPage'
import Providers from './Providers'

const ProviderRoutes = [
  {
    path: '/providers',
    element: <Providers />,
  },
  {
    path: '/providers/:providerId',
    element: <ProviderDetailsPage />,
  },
]

export default ProviderRoutes
