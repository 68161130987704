import React, { Component } from 'react'
import { Breadcrumb, SimpleCard } from '../../../matx'
import { KycModal, ReceivableModal } from './ModalsFragment'
import { ReconciliationModal } from './ModalsFragment'
import { ChargebackModal } from './ModalsFragment'

class ReportMainPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  handleOpen() {
    this.setState({ modalOpen: true })
  }

  handleClose() {
    this.setState({ modalOpen: false })
  }

  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Reports' }]} />
        </div>
        <div className="m-sm-30">
          <SimpleCard title="Choose one and click to open more options">
            <div className="row">
              <div className="col-md-3 col-3">
                <ReceivableModal />
              </div>
              <div className="col-md-3 col-3">
                <ReconciliationModal />
              </div>
              <div className="col-md-3 col-3">
                <ChargebackModal />
              </div>
              <div className="col-md-3 col-3">
                <KycModal />
              </div>
            </div>
          </SimpleCard>
        </div>
      </div>
    )
  }
}

export default ReportMainPage
