import PayinTransaction from './PayinTransaction'
import PayinTransactionDetailsPage from './PayinTransactionDetailsPage'

const transactionsRoutes = [
  {
    path: '/payin/transaction',
    element: <PayinTransaction />,
    exact: true,
  },
  {
    path: '/payin/transaction/:id',
    element: <PayinTransactionDetailsPage />,
  },
]

export default transactionsRoutes
