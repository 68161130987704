export function saveStreamCSV(filename, text) {
  if (window.navigator.msSaveBlob) {
    // IE 10 and later, and Edge.
    var blobObjectForIe = new Blob([text], { type: 'text/csv' })
    window.navigator.msSaveBlob(blobObjectForIe, filename)
  } else {
    // Everthing else (except old IE).
    var anchor = document.createElement('a')
    anchor.download = filename
    if (window.URL.createObjectURL) {
      var blobObject = new Blob([text], { type: 'text/csv' })
      anchor.href = window.URL.createObjectURL(blobObject)
    } else {
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
    }
    if (document.createEvent) {
      var event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      anchor.dispatchEvent(event)
    } else {
      anchor.click()
    }
  }
}
