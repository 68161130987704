import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Form, Container } from 'react-bootstrap'
import { refundStatusConfirm } from '../../../services/APIClient'
import moment from 'moment'
import Select from 'react-select'
import BTable from 'react-bootstrap/Table'

export default function RefundStatusForm({ currentStatus, refundId, paymentMethod }) {
  const today = useMemo(() => getTodayDate(), [])
  const [comments, setComments] = useState('')
  const [status, setStatus] = useState(currentStatus)
  const [transitions, setTransitions] = useState(null)
  const [refundDate, setRefundDate] = useState(today)
  const [providerName, setProviderName] = useState(null)
  const [providerExternalId, setProviderExternalId] = useState(null)
  const [refundPaymentMethodType, setRefundPaymentMethodType] = useState(null)

  const [validated, setValidated] = useState(false)
  const formRef = useRef(null)

  const providersName = [
    { value: 'REDE', label: 'REDE' },
    { value: 'GETNET', label: 'GETNET' },
    { value: 'ITAU', label: 'ITAU' },
    { value: 'CIELO', label: 'CIELO' },
    { value: 'BRADESCO', label: 'BRADESCO' },
    { value: 'SANTANDER', label: 'SANTANDER' },
    { value: 'BS2', label: 'BS2' },
  ]

  const refundPaymentMethodTypes = [
    { value: 'PIX', label: 'PIX' },
    { value: 'CREDIT_CARD', label: 'CREDIT_CARD' },
    { value: 'BANK_TRANSFER', label: 'BANK_TRANSFER' },
  ]

  function getTodayDate() {
    const todayDate = new Date()
    var date = String(todayDate.getDate()).padStart(2, '0')
    var month = String(todayDate.getMonth() + 1).padStart(2, '0')
    var year = todayDate.getFullYear()
    return `${year}-${month}-${date}`
  }

  function refundDateIsAfterToday() {
    var now = moment()
    return moment(refundDate) > now
  }

  useEffect(() => {
    if (currentStatus === 'PENDING') setStatus('REQUESTED')
  }, [currentStatus])

  useEffect(() => {
    const findTransitions = () => {
      const approveWithoutCommentMessage = 'Would you like to APPROVE this refund?'
      const approveWithCommentMessage =
        'Atention: the comment will not be saved. Would you like to APPROVE this refund?'
      const approveMessage = comments === '' ? approveWithoutCommentMessage : approveWithCommentMessage

      const requestedTransitions = {
        APPROVED: {
          confirmationMessage: approveMessage,
          className: 'btn-success',
          message: 'Approve',
        },
        DENIED: {
          confirmationMessage: 'Would you like to DENY this refund?',
          className: 'btn-danger',
          message: 'Deny',
        },
      }

      if (paymentMethod === 'CREDIT_CARD') return { REQUESTED: requestedTransitions }

      return {
        REQUESTED: requestedTransitions,
        APPROVED: {
          PROCESSED: {
            confirmationMessage: 'Would you like to mark this refund as PROCESSED?',
            className: 'btn-success',
            message: 'Process',
          },
          INVALID_INPUT: {
            confirmationMessage: 'Would you like to mark this refund as INVALID INPUT?',
            className: 'btn-danger',
            message: 'Invalid Input',
          },
        },
      }
    }

    setTransitions(findTransitions()[status])
  }, [paymentMethod, comments, status])

  async function askRefundConfirmation(message, status, event) {
    const form = formRef.current
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidated(true)
    } else {
      if (window.confirm(message)) {
        await confirmRefundOnTransaction(status)
        window.location.reload()
      }
    }
    setValidated(false)
  }

  function confirmRefundOnTransaction(status) {
    const body = {
      status: status,
      reason: comments,
      providerEventDate: refundDate,
      providerName: providerName,
      providerExternalId: providerExternalId,
      refundPaymentMethodType: refundPaymentMethodType,
    }
    return refundStatusConfirm(refundId, body)
  }

  function findSelectedEnum(selectedField, allEnums) {
    return allEnums.find(({ value }) => value === selectedField)
  }

  function changeStatusButtons() {
    if (!transitions)
      return (
        <center>
          <h5>The refund is not open to changes anymore!</h5>
        </center>
      )

    return (
      <Form ref={formRef} validated={validated}>
        <Container>
          <BTable>
            <tbody>
              {status === 'APPROVED' && (
                <>
                  <tr>
                    <td colSpan="2">
                      <h4>Refund Process Form:</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>Refund provider event date:</td>
                    <td>
                      <input
                        className="form-control"
                        name="refundDate"
                        onChange={(event) => setRefundDate(event.target.value)}
                        step="any"
                        id="providerEventDate"
                        type="date"
                        defaultValue={today}
                        max={today}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Provider Name</td>
                    <td>
                      <Select
                        name="providerName"
                        id="providerName"
                        className="select-register-input"
                        onChange={(event) => setProviderName(event.value)}
                        options={providersName}
                        placeholder="Provider Name"
                        value={[findSelectedEnum(providerName, providersName)]}
                        required
                      />
                      {validated && !providerName && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          Please select an option.
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Refund payment method used</td>
                    <td>
                      <Select
                        name="refundPaymentMethodType"
                        id="refundPaymentMethodType"
                        className="select-register-input"
                        onChange={(event) => setRefundPaymentMethodType(event.value)}
                        options={refundPaymentMethodTypes}
                        placeholder="Refund payment method used"
                        value={[findSelectedEnum(refundPaymentMethodType, refundPaymentMethodTypes)]}
                        required
                      />
                      {validated && !refundPaymentMethodType && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                          Please select an option.
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Provider external ID:</td>
                    <td>
                      <input
                        className="account-register-input-text"
                        name="name"
                        id="name"
                        maxLength="100"
                        onChange={(event) => setProviderExternalId(event.target.value)}
                        type="text"
                        placeholder="Provider external ID"
                      />
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td>Comments:</td>
                <td>
                  <input
                    className="form-control"
                    name="reasons"
                    onChange={(event) => setComments(event.target.value)}
                    id="providerEventDate"
                    type="text"
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
        <div
          className="btns "
          style={{
            display: 'flex',
            minWidth: '100vh',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          {Object.entries(transitions).map(([newStatus, { className, confirmationMessage, message }], index) => {
            return (
              <button
                key={index}
                type="button"
                className={`btn ${className}`}
                onClick={async (event) => {
                  askRefundConfirmation(confirmationMessage, newStatus, event)
                }}
                disabled={refundDate === '' || refundDateIsAfterToday()}
              >
                {' '}
                {message}{' '}
              </button>
            )
          })}
        </div>
      </Form>
    )
  }

  return changeStatusButtons()
}
