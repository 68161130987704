import React, { Component } from 'react'
import { Breadcrumb } from '../../../../matx'
import { withRouter } from '../../../../utils'
import PayinTransactionDetails from './PayinTransactionDetails'

class PayinTransactionDetailsPage extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Transactions', path: '/payin/transaction' }, { name: 'Details' }]} />
        </div>
        <PayinTransactionDetails id={this.props.params.id} />
      </div>
    )
  }
}

export default withRouter(PayinTransactionDetailsPage)
