import React from 'react'
import { Breadcrumb } from '../../../matx'
import { withRouter } from '../../../utils'
import PayoutTransactionDetails from './PayoutTransactionDetails'

function PayoutTransactionDetailsPage(props) {
  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb
          routeSegments={[{ name: 'Payout Transactions', path: '/payout/transaction' }, { name: 'Details' }]}
        />
      </div>
      <PayoutTransactionDetails id={props.params.id} />
    </div>
  )
}

export default withRouter(PayoutTransactionDetailsPage)
