import React, { Component } from 'react'
import { Fragment } from 'react'
import JwtAuthService from '../../../services/JwtAuthService'
import { Form } from 'react-bootstrap'
import { accountAnalyses, updateAnalysisOperatorStatus, updateAnalysisStatus } from '../../../services/APIClient'

class AccountButtonsToApprove extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: props.accountId,
      accountAnalyses: null,
      showButtons: false,
      analyses: null,
      openOperators: [],
      analysisId: null,
      comment: null,
    }
  }

  componentDidMount() {
    accountAnalyses(this.state.accountId).then((resp) => {
      this.setState({
        accountAnalyses: resp,
        analysisId: resp.result[0].id,
      })
      this.showButtons(resp)
    })
  }

  showButtons(response) {
    let operatorId = JwtAuthService.getOperatorId()
    let openOperators = response.result[0].operators.filter((operator) => operator.status === 'OPEN')

    this.setState({
      openOperators: openOperators,
      showButtons: openOperators.some((operator) => operator.id === parseInt(operatorId)),
    })
  }

  async askAccountStatusUpdate(message, status) {
    if (window.confirm(message)) {
      this.confirmAccountStatusUpdate(status).then(() => window.location.reload())
    }
  }

  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value })
  }

  confirmAccountStatusUpdate(status) {
    const body = {
      comment: this.state.comment,
      status: status,
    }
    return updateAnalysisOperatorStatus(this.state.accountId, this.state.accountAnalyses.result[0].id, body)
  }

  updateComment = (event) => {
    this.setState({ comment: event.target.value })
  }

  async updateEndStatus(message, status) {
    if (window.confirm(message)) {
      await this.updateAccountStatus(status)
      window.location.reload()
    }
  }

  updateAccountStatus(status) {
    const body = {
      comment: this.state.comment,
      status: status,
    }
    return updateAnalysisStatus(this.state.accountId, this.state.accountAnalyses.result[0].id, body)
  }

  renderFinalButton() {
    if (typeof this.state.openOperators === 'undefined' || this.state.openOperators.length > 0)
      return <React.Fragment />

    return (
      <React.Fragment>
        <center>
          <div>
            <Form.Label>Comments:</Form.Label>
            <Form.Control type="text" className="form-control" name="reasons" onChange={this.updateComment} required />
          </div>

          <div className="row" style={{ paddingTop: '30px' }}>
            <div className="col-md-6 col-4">
              <button
                type="button"
                className="btn btn-success"
                onClick={async () => {
                  this.updateEndStatus('Are you sure you want to update to APPROVED?', 'APPROVED')
                }}
              >
                APPROVED
              </button>
            </div>

            <div className="col-md-6 col-4">
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  this.updateEndStatus('Are you sure you want to update to DISAPPROVED?', 'DISAPPROVED')
                }}
              >
                DISAPPROVED
              </button>
            </div>
          </div>
        </center>
      </React.Fragment>
    )
  }

  renderMissingOperatorsMessage() {
    if (typeof this.state.openOperators === 'undefined' || this.state.openOperators.length < 0)
      return <React.Fragment />

    return (
      <center>
        <h5>this account has already been approved by you, awaiting the approval of :</h5>
        {this.state.openOperators.map((operator) => (
          <h5>{operator.userName}</h5>
        ))}
      </center>
    )
  }

  renderButtons() {
    if (!this.state.showButtons) return <React.Fragment />

    return (
      <div>
        <Form.Label>Comments:</Form.Label>
        <Form.Control type="text" className="form-control" name="reasons" onChange={this.updateComment} required />

        <div className="row" style={{ paddingTop: '30px' }}>
          <div className="col-md-4 col-4">
            <button
              type="button"
              className="btn btn-success"
              onClick={async () => {
                this.askAccountStatusUpdate('Are you sure you want to update to APPROVED?', 'APPROVED')
              }}
            >
              APPROVED
            </button>
          </div>

          <div className="col-md-4 col-4">
            <button
              type="  button"
              className="btn btn-warning"
              onClick={async () => {
                this.askAccountStatusUpdate(
                  'Are you sure you want to update to PENDING_INFORMATION?',
                  'PENDING_INFORMATION',
                )
              }}
            >
              PENDING_INFORMATION
            </button>
          </div>

          <div className="col-md-4 col-4">
            <button
              type="button"
              className="btn btn-danger"
              onClick={async () => {
                this.askAccountStatusUpdate('Are you sure you want to update to DISAPPROVED?', 'DISAPPROVED')
              }}
            >
              DISAPPROVED
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        <center>
          {this.renderMissingOperatorsMessage()}
          {this.renderButtons()}
        </center>
        {this.renderFinalButton()}
      </Fragment>
    )
  }
}

export default AccountButtonsToApprove
