import { useState, useEffect } from 'react'
import { SimpleCard } from '../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import { payeeMerchantDetails, payoutTransactionWithFilters } from '../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { currencyWithTwoDecimals } from '../../services/FormatterService'
import { NavLink } from 'react-router-dom'
import PayeeMerchantTable from '../../components/payout/PayeeMerchantTable'

export function PayeeMerchantDetails({ payeeMerchantId }) {
  const [details, setDetails] = useState(null)
  const [payouts, setPayouts] = useState([])

  useEffect(() => {
    payeeMerchantDetails(payeeMerchantId).then((resp) => {
      setDetails(resp)
    })

    payoutTransactionWithFilters(0, 10, { payeeMerchantId }).then((resp) => {
      setPayouts(resp.content)
    })
  }, [payeeMerchantId])

  return (
    <div className="First card">
      <SimpleCard title="Payee Merchant Details">
        <div>
          {details === null ? (
            ''
          ) : (
            <Container className="white-table-border-radius">
              <PayeeMerchantTable payeeMerchantDetails={details} />
              <h5 style={{ paddingTop: '30px' }}>Last ten payout transactions:</h5>
              {payouts.length <= 0 ? (
                <div>
                  <p style={{ color: '#000000' }}>This payee merchant does not have payout transactions</p>
                </div>
              ) : (
                <BTable striped borderless hover>
                  <thead>
                    <tr>
                      <th>Payout ID</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payouts.map((payout) => (
                      <tr key={payout.id}>
                        <td>
                          <NavLink to={`/payout/transaction/${payout.id}`} end>
                            {payout.id}
                          </NavLink>
                        </td>
                        <td>{currencyWithTwoDecimals(payout.amount)}</td>
                        <td>{payout.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </BTable>
              )}
            </Container>
          )}
        </div>
      </SimpleCard>
    </div>
  )
}

export default PayeeMerchantDetails
