import React, { Component } from 'react'
import { Breadcrumb } from '../../../../matx'
import RefundTable from './RefundTable'

class Refunds extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Refunds' }]} />
        </div>
        <RefundTable />
      </div>
    )
  }
}

export default Refunds
