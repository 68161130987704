import { useState, useEffect } from 'react'
import Checkbox from '../../../components/Checkbox'
import { createLlOperationPaymentCollectionCsv } from '../../../services/APIClient'

export default function LlOperationPaymentCollection() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState(['PIX', 'BOLETO', 'CREDIT_CARD'])

  const today = new Date()
  const maxReportDate = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0]

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  const handleDownloadClick = () => {
    setIsLoading(true)
    createLlOperationPaymentCollectionCsv(startDate, endDate, paymentMethods)
      .catch(() => alert('Something went wrong. Report could not be downloaded. Please, contact system administrator.'))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDateChange = (propertyName, newValue) => {
    if (propertyName === 'startDate') {
      setStartDate(newValue)
    } else if (propertyName === 'endDate') {
      setEndDate(newValue)
    }
  }

  const handleCheckboxChange = (paymentMethod, newValue) => {
    if (newValue) {
      setPaymentMethods([...paymentMethods, paymentMethod])
    } else {
      setPaymentMethods(paymentMethods.filter((method) => method !== paymentMethod))
    }
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Find all chargeable transactions with provider fees</label>
        </div>
        <div className="row w-100 mx-auto">
          <div className="col-md-3 col-3">
            <label className="labelCalendarDistance">Initial date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={(event) => onDateChange('startDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-3 col-3">
            <label className="labelCalendarDistance">End date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              onChange={(event) => onDateChange('endDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-3 col-3">
            <div>
              <Checkbox label="Pix" checked={true} onChange={(newValue) => handleCheckboxChange('PIX', newValue)} />
            </div>
            <div>
              <Checkbox
                label="Boleto"
                checked={true}
                onChange={(newValue) => handleCheckboxChange('BOLETO', newValue)}
              />
            </div>
            <div>
              <Checkbox
                label="Credit card"
                checked={true}
                onChange={(newValue) => handleCheckboxChange('CREDIT_CARD', newValue)}
              />
            </div>
          </div>
          <div className="col-md-3 col-3">
            <button
              className="buttonSendDate"
              onClick={handleDownloadClick}
              disabled={startDate === null || endDate === null || isLoading}
            >
              Download csv
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
