import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { convertToBrazilianTimeZone, currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useNavigate } from 'react-router-dom'
import StatusLabel from '../../../components/StatusLabel'

export default function PayinRefundTableContent({ items }) {
  const navigate = useNavigate()

  function handleClick(_, id) {
    navigate(`/payin/refund/${id}`)
  }

  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          <TableCell className="px-0">Refund Id</TableCell>
          <TableCell className="px-0">Externalized Id</TableCell>
          <TableCell className="px-0">Customer Name</TableCell>
          <TableCell className="px-0">Soft descriptor</TableCell>
          <TableCell className="px-0">Payment Method</TableCell>
          <TableCell className="px-0">Status</TableCell>
          <TableCell className="px-0">Amount</TableCell>
          <TableCell className="px-0">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((refund) => (
          <TableRow key={refund.refundId} onClick={(event) => handleClick(event, refund.refundId)}>
            <TableCell className="px-0 capitalize" align="left">
              {refund.refundId}
            </TableCell>
            <TableCell className="px-0 capitalize" align="left">
              {refund.externalId}
            </TableCell>
            <TableCell
              className="px-0 capitalize"
              align="left"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
              {refund.customerName}
            </TableCell>
            <TableCell className="px-0 capitalize" align="left">
              {refund.softDescriptor}
            </TableCell>
            <TableCell className="px-0 capitalize" align="left">
              {refund.collectType}
            </TableCell>
            <TableCell className="px-0 capitalize" align="left">
              <StatusLabel color={defineStatusColorRefund(refund.status)} label={refund.status} />
            </TableCell>
            <TableCell className="px-0 capitalize" align="left">
              {currencyWithTwoDecimals(refund.amount)}
            </TableCell>
            <TableCell className="px-0 capitalize" align="left">
              {convertToBrazilianTimeZone(refund.createdAt)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const defineStatusColorRefund = (status) => {
  switch (status) {
    case 'PROCESSED':
      return 'bg-green'
    case 'PENDING':
    case 'REQUESTED':
    case 'PENDING_PAYMENT':
      return 'bg-secondary'
    default:
      return 'bg-error'
  }
}
