import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function TableContent({ items, columns, Row }) {
  const navigate = useNavigate()

  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={index} className="px-0">
              {column}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.length > 0 ? (
          items.map((item, index) => <Row key={index} item={item} index={index} navigate={navigate} />)
        ) : (
          <TableRow>
            <TableCell>No result</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
