import { useState } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'

export default function CommentInput({ item, onChange }) {
  const [request, setRequest] = useState(item)

  const onAnyChanged = (change) => {
    const newRequest = { ...request, ...change }
    setRequest(newRequest)
    onChange(newRequest)
  }

  const onChangeProperty = (property, value) => {
    onAnyChanged({ [property]: value })
  }

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">Comentário:</td>
              <td>
                <textarea
                  className="account-register-input-text"
                  name="comment"
                  onChange={(event) => onChangeProperty('comment', event.target.value)}
                  id="comment"
                  type="text"
                  placeholder="Comment"
                  required
                  defaultValue={request.comment}
                  disabled={!item.removable}
                />
              </td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </>
  )
}
