import { useState, useEffect } from 'react'
import { createReconciliationCsv, allAccountsInfo } from '../../../services/APIClient'
import Checkbox from '../../../components/Checkbox'
import { MenuItem, Select, Autocomplete, TextField } from '@mui/material'

export default function ReconciliationCsvV2() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [allMerchants, setAllMerchants] = useState([])
  const [paymentMethods, setPaymentMethods] = useState(['PIX', 'BOLETO', 'CREDIT_CARD'])
  const [businessLineReport, setBusinessLineReport] = useState('GENERAL')
  const [dateType, setDateType] = useState('TRANSACTION_UPDATED_DATE')
  const [dataMode, setDataMode] = useState('SIMPLIFIED_INCLUDING_CREDIT_CARD')
  const [merchant, setMerchant] = useState(null)
  const [currencyFilter, setCurrencyFilter] = useState('ALL')

  const [isLoading, setIsLoading] = useState(false)

  const today = new Date()
  const maxReportDate = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0]

  useEffect(() => {
    allAccountsInfo().then((response) => {
      const merchants = response.result.map((merchant) => {
        return { label: `${merchant.id} - ${merchant.softDescriptor}`, id: merchant.id }
      })
      setAllMerchants(merchants)
    })
  }, [])

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  const handleDownloadClick = () => {
    setIsLoading(true)
    createReconciliationCsv(
      startDate,
      endDate,
      paymentMethods,
      businessLineReport,
      dateType,
      dataMode,
      merchant?.id,
      currencyFilter,
    )
      .catch(() => alert('Something went wrong. Report could not be downloaded. Please, contact system administrator.'))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onDateChange = (propertyName, newValue) => {
    if (propertyName === 'startDate') {
      setStartDate(newValue)
    } else if (propertyName === 'endDate') {
      setEndDate(newValue)
    }
  }

  const handleCheckboxChange = (paymentMethod, newValue) => {
    if (newValue) {
      setPaymentMethods([...paymentMethods, paymentMethod])
    } else {
      setPaymentMethods(paymentMethods.filter((method) => method !== paymentMethod))
    }
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Reconciliation V2 - Find all chargeable transactions by payment method in the selected period</label>
        </div>
        <div className="row w-100 mx-auto">
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">Initial date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={(event) => onDateChange('startDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-4 col-4">
            <label className="labelCalendarDistance">End date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              onChange={(event) => onDateChange('endDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-4 col-4">
            <Select
              value={dateType}
              name="dateType"
              id="dateType"
              onChange={(e) => setDateType(e.target.value)}
              className="select"
            >
              <MenuItem value="TRANSACTION_UPDATED_DATE">Transaction updated date</MenuItem>
              <MenuItem value="AVAILABILITY_DATE">Availability date</MenuItem>
              <MenuItem value="REFUNDED_BETWEEN">Refunded between</MenuItem>
            </Select>
          </div>
        </div>
        <div className="row w-100 mx-auto" style={{ paddingTop: '15px' }}>
          <div className="col-md-6 col-6">
            <Autocomplete
              includeInputInList
              options={allMerchants}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Merchant" />}
              value={merchant}
              onChange={(_, newValue) => setMerchant(newValue)}
              isOptionEqualToValue={(option, value) => option.id + '' === value.id}
            />
          </div>
          <div className="col-md-3 col-3">
            <Select
              value={businessLineReport}
              name="isSailor"
              id="isSailor"
              onChange={(e) => setBusinessLineReport(e.target.value)}
              options={['GENERAL', 'SAILOR']}
              className="select"
            >
              <MenuItem value="GENERAL">Not sailor</MenuItem>
              <MenuItem value="SAILOR">Sailor</MenuItem>
            </Select>
          </div>
          <div className="col-md-3 col-3">
            <div>
              <Checkbox label="Pix" checked={true} onChange={(newValue) => handleCheckboxChange('PIX', newValue)} />
            </div>
            <div>
              <Checkbox
                label="Boleto"
                checked={true}
                onChange={(newValue) => handleCheckboxChange('BOLETO', newValue)}
              />
            </div>
            <div>
              <Checkbox
                label="Credit card"
                checked={true}
                onChange={(newValue) => handleCheckboxChange('CREDIT_CARD', newValue)}
              />
            </div>
          </div>
        </div>
        <div className="row w-100 mx-auto" style={{ paddingTop: '15px' }}>
          <div className="col-md-9 col-9">
            <Select
              value={dataMode}
              name="dataMode"
              id="dataMode"
              onChange={(e) => setDataMode(e.target.value)}
              className="select"
            >
              <MenuItem value="COMPLETE">COMPLETE</MenuItem>
              <MenuItem value="WITHOUT_SENSITIVE_DATA">WITHOUT SENSITIVE DATA</MenuItem>
              <MenuItem value="SIMPLIFIED_INCLUDING_CREDIT_CARD">SIMPLIFIED INCLUDING CREDIT CARD</MenuItem>
              <MenuItem value="SIMPLIFIED_WITHOUT_CREDIT_CARD_INCLUDING_GUARANTEE_DEPOSIT">
                SIMPLIFIED WITHOUT CREDIT CARD BUT INCLUDING GUARANTEE DEPOSIT
              </MenuItem>
              <MenuItem value="SIMPLIFIED_WITHOUT_CREDIT_CARD_AND_GUARANTEE_DEPOSIT">
                SIMPLIFIED WITHOUT CREDIT CARD AND GUARANTEE DEPOSIT
              </MenuItem>
            </Select>
          </div>
          <div className="col-md-9 col-9">
            <Select
              value={currencyFilter}
              name="currencyFilter"
              id="currencyFilter"
              onChange={(e) => setCurrencyFilter(e.target.value)}
              className="select"
            >
              <MenuItem value="ALL">ALL</MenuItem>
              <MenuItem value="LOCAL">LOCAL</MenuItem>
              <MenuItem value="FOREIGN">FOREIGN</MenuItem>
            </Select>
          </div>
          <div className="col-md-3 col-3">
            <button
              className="buttonSendDate"
              onClick={handleDownloadClick}
              disabled={startDate === null || endDate === null || isLoading}
            >
              Download csv
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
