import 'babel-polyfill'

import React from 'react'
import { createRoot } from 'react-dom/client'
import './_index.scss'

import * as serviceWorker from './serviceWorker'
import App from './app/App'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)

serviceWorker.unregister()
