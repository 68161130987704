import { useState } from 'react'

export default function Checkbox({ label, checked = false, onChange }) {
  const [isChecked, setIsChecked] = useState(checked)

  function handleChange() {
    setIsChecked(!isChecked)
    if (onChange !== undefined) {
      onChange(!isChecked)
    }
  }

  return (
    <label>
      <input type="checkbox" checked={isChecked} onChange={handleChange} />
      {label}
    </label>
  )
}
