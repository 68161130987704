import config from '../../config'
import jwtAuthService from './JwtAuthService'
import _ from 'lodash'
import {
  accountRegisterCallback,
  accountUpdateCallback,
  createToastAfterFileUpload,
  csvDownloadCallback,
  defaultServerErrorCallback,
  defaultUserErrorCallback,
  defaultForbiddenCallback,
  defaultUnauthorizedCallback,
  transferNetCreditCallback,
  createIntermediaryCallback,
  providerConfigurationRegisterCallback,
  approveSettlementCallback,
  executeFundSentSettlementCallback,
  listCauses,
  executeWithdrawnSettlementCallback,
} from './CallbackManager'

const transactionBaseUrl = config.TRANSACTION_BASE_URL
const accountingBaseUrl = config.ACCOUNTING_BASE_URL
const accountBaseUrl = config.ACCOUNT_BASE_URL
const receivableBaseUrl = config.RECEIVABLE_BASE_URL
const kycBaseUrl = config.KYC_BASE_URL
const authBaseUrl = config.AUTH_BASE_URL

function authenticatedRequest(
  url,
  method,
  {
    body = null,
    headers = { 'Content-Type': 'application/json' },
    queryParams = {},
    callback = (resp) => resp.json(),
    userErrorCallback = defaultUserErrorCallback,
    forbiddenCallback = defaultForbiddenCallback,
    serverErrorCallback = defaultServerErrorCallback,
    unauthorizedCallback = defaultUnauthorizedCallback,
  } = {},
) {
  const token = jwtAuthService.getToken()
  headers['Authorization'] = `Bearer ${token}`
  if (headers['Content-Type'] === 'application/json' && body !== null) body = JSON.stringify(body)

  if (headers['Content-Type'] === 'multipart/form-data') delete headers['Content-Type']

  return fetch(generateUrl(url, queryParams), {
    method: method,
    headers: headers,
    body: body,
  }).then((resp) => {
    if (resp.ok) return callback(resp)
    if (resp.status === 401) return unauthorizedCallback(resp)
    if (resp.status === 403) return forbiddenCallback(resp)
    if (resp.status >= 400 && resp.status < 500) return userErrorCallback(resp)
    if (resp.status >= 500) return serverErrorCallback(resp)
  })
}

function generateUrl(url, queryParams) {
  let filteredQueryParams = _.pickBy(
    queryParams,
    (param) => param !== null && param !== undefined && !isEmptyString(param),
  )
  if (Object.keys(filteredQueryParams).length === 0) {
    return url
  }

  let parameters = Object.entries(filteredQueryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  return `${url}?${parameters}`
}

function isEmptyString(value) {
  return typeof value === 'string' && value.trim() === ''
}

export function createReceivableCsv(startDate, endDate) {
  const url = `${receivableBaseUrl}/receivable`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams: { startDate: startDate, endDate: endDate },
    callback: (response) => csvDownloadCallback(response, `receivable-${startDate}----${endDate}.csv`),
  })
}

export function createCsv(startDate, endDate, businessLineReport) {
  const url = `${transactionBaseUrl}/dashboard/conciliation`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams: { startDate, endDate, businessLineReport },
    callback: (response) =>
      csvDownloadCallback(response, `conciliation-${startDate}----${endDate}-${businessLineReport}.csv`),
  })
}

export function createReconciliationCsv(
  startDate,
  endDate,
  paymentMethods,
  businessLineReport,
  dateTypeFilter,
  dataMode,
  merchantId,
  currencyFilter,
) {
  const queryParams = { startDate, endDate, businessLineReport, dateTypeFilter, dataMode, merchantId, currencyFilter }
  if (paymentMethods !== []) {
    queryParams.paymentMethod = paymentMethods.join(',')
  }
  const url = `${transactionBaseUrl}/dashboard/conciliation/v2`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams,
    callback: (response) =>
      csvDownloadCallback(response, `conciliation-${startDate}----${endDate}-${businessLineReport}.csv`),
  })
}

export function createPayoutReconciliationCsv(startDate, endDate, businessLineReport) {
  const queryParams = { startDate, endDate, businessLineReport }
  const url = `${transactionBaseUrl}/dashboard/conciliation/payout`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams,
    callback: (response) =>
      csvDownloadCallback(response, `conciliation-${startDate}----${endDate}-${businessLineReport}.csv`),
  })
}

export function createGuaranteeDepositReleaseCsv(startDate, endDate) {
  const queryParams = { startDate, endDate }
  const url = `${transactionBaseUrl}/dashboard/conciliation/guarantee-deposit-release`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams,
    callback: (response) => csvDownloadCallback(response, `guarantee-deposit-release-${startDate}----${endDate}.csv`),
  })
}

export function createChargebackCsv(startDate, endDate) {
  const url = `${transactionBaseUrl}/chargeback/report`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams: { startDate: startDate, endDate: endDate },
    callback: (response) => csvDownloadCallback(response, `chargeback-${startDate}----${endDate}.csv`),
  })
}

export function createKycCsv(startDate, endDate) {
  const url = `${kycBaseUrl}/kyc/report`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams: { startDate: startDate, endDate: endDate },
    callback: (response) => csvDownloadCallback(response, `kyc_report-${startDate}----${endDate}.csv`),
  })
}

export function createProviderTransactionCsv(startDate, endDate) {
  const url = `${receivableBaseUrl}/receivable/transaction`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams: { startDate: startDate, endDate: endDate },
    callback: (response) => csvDownloadCallback(response, `receivable-${startDate}----${endDate}.csv`),
  })
}

export function createReceivableDiffCsv(startDate, endDate) {
  const url = `${receivableBaseUrl}/receivable/diff`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams: { startDate: startDate, endDate: endDate },
    callback: (response) => csvDownloadCallback(response, `receivable-${startDate}----${endDate}.csv`),
  })
}

export function createLlOperationPaymentCollectionCsv(startDate, endDate, paymentMethods) {
  const queryParams = { startDate, endDate, paymentMethod: paymentMethods.join(',') }
  const url = `${accountingBaseUrl}/reports/ll-operation-payment-collection`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    queryParams,
    callback: (response) =>
      csvDownloadCallback(response, `ll-operation-payment-collection-${startDate}----${endDate}.csv`),
  })
}

export function createReconciliationByStatusTransaction(startDate, endDate, statusList) {
  const url = `${transactionBaseUrl}/dashboard/conciliation`
  return authenticatedRequest(url, 'GET', {
    queryParams: { startDate: startDate, endDate: endDate, status: statusList },
    callback: (response) => csvDownloadCallback(response, `conciliation-${startDate}----${endDate}.csv`),
  })
}

export function refundStatusConfirm(refundId, body) {
  const url = `${transactionBaseUrl}/dashboard/refunds/${refundId}/status`
  return authenticatedRequest(url, 'POST', { body: body })
}

export function refundDetails(refundId) {
  const url = `${transactionBaseUrl}/dashboard/refunds/${refundId}`
  return authenticatedRequest(url, 'GET')
}

export function refundPagination(page, rowsPerPage, filters = {}) {
  const url = `${transactionBaseUrl}/dashboard/refunds`
  return authenticatedRequest(url, 'GET', { queryParams: { page: page, size: rowsPerPage, ...filters } })
}

export function cercReceivablePagination(page, rowsPerPage) {
  const url = `${transactionBaseUrl}/dashboard/receivables`
  return authenticatedRequest(url, 'GET', { queryParams: { page: page, size: rowsPerPage } })
}

export function cercReceivableDetails(receivableId) {
  const url = `${transactionBaseUrl}/dashboard/receivables/${receivableId}`
  return authenticatedRequest(url, 'GET')
}

export function cercReceivableCalendar() {
  const url = `${transactionBaseUrl}/dashboard/receivables/calendar`
  return authenticatedRequest(url, 'GET')
}

export function cercReceivableStatusUpdate(receivableId) {
  const url = `${transactionBaseUrl}/dashboard/receivables/${receivableId}/status/PAID`
  return authenticatedRequest(url, 'POST', {
    callback: (resp) => resp.text(),
  })
}

export function transactionPagination(page, rowsPerPage, filters = {}) {
  const url = `${transactionBaseUrl}/dashboard/transactions`
  const queryParams = {
    page: page,
    size: rowsPerPage,
    ...filters,
  }
  return authenticatedRequest(url, 'GET', { queryParams })
}

export function transactionDetails(tid) {
  const url = `${transactionBaseUrl}/dashboard/transactions/${tid}`
  return authenticatedRequest(url, 'GET')
}

export function accountPagination(page, rowsPerPage, filters = {}) {
  const url = `${accountBaseUrl}/dashboard/accounts`
  return authenticatedRequest(url, 'GET', { queryParams: { page: page, size: rowsPerPage, ...filters } })
}

export function accountRegister(body) {
  const url = `${accountBaseUrl}/account`
  return authenticatedRequest(url, 'POST', {
    body: body,
    callback: (response) => accountRegisterCallback(response),
  })
}

export function accountUpdate(body, accountId) {
  const url = `${accountBaseUrl}/account/${accountId}`
  return authenticatedRequest(url, 'PUT', {
    body: body,
    callback: (response) => accountUpdateCallback(response),
  })
}

export function accountDetails(accountId) {
  const url = `${accountBaseUrl}/account/${accountId}`
  return authenticatedRequest(url, 'GET')
}

export function defaultAccountConfiguration(businessLine) {
  const url = `${accountBaseUrl}/account-configurations/default/${businessLine}`
  return authenticatedRequest(url, 'GET')
}

export function accountBalances(merchantId, date) {
  let queryParams = {}
  if (date !== undefined) {
    queryParams = { date }
  }
  const url = `${transactionBaseUrl}/dashboard/accounting/payin/balances/merchants/${merchantId}`
  return authenticatedRequest(url, 'GET', { queryParams })
}

export function accountAnalyses(accountId) {
  const url = `${accountBaseUrl}/account/${accountId}/analyses`
  return authenticatedRequest(url, 'GET')
}

export function accountToApprove() {
  const url = `${accountBaseUrl}/account/analyses/to-approve`
  return authenticatedRequest(url, 'GET')
}

export function allAccountsInfo() {
  const url = `${accountBaseUrl}/dashboard/accounts/info`
  return authenticatedRequest(url, 'GET')
}

export function findAllIntermediariesByAccountId(accountId) {
  const url = `${accountBaseUrl}/dashboard/accounts/${accountId}/providers`
  return authenticatedRequest(url, 'GET')
}

export function createIntermediary(aid, pid, body) {
  const url = `${accountBaseUrl}/account/${aid}/provider/${pid}`
  return authenticatedRequest(url, 'POST', { body: body, callback: (_) => createIntermediaryCallback() })
}

export function createCredentials(aid, pid) {
  const url = `${accountBaseUrl}/account/${aid}/provider/${pid}/credentials`
  return authenticatedRequest(url, 'POST')
}

export function updateAnalysisOperatorStatus(accountId, analysisId, body) {
  const url = `${accountBaseUrl}/account/${accountId}/analysis/${analysisId}`
  return authenticatedRequest(url, 'PUT', {
    body: body,
    userErrorCallback: (resp) => {
      defaultUserErrorCallback(resp)
      throw Error()
    },
  })
}

export function updateAnalysisStatus(accountId, analysisId, body) {
  const url = `${accountBaseUrl}/account/${accountId}/analysis/${analysisId}/status`
  return authenticatedRequest(url, 'PUT', { body: body })
}

export function fetchNetCredited(requestedDate) {
  let queryParams = {}
  if (requestedDate !== undefined) {
    queryParams = { date: requestedDate }
  }
  const url = `${transactionBaseUrl}/dashboard/accounting/payin/net-credit-amount`
  return authenticatedRequest(url, 'GET', { queryParams })
}

export function transferNetCredited(requestData) {
  const url = `${transactionBaseUrl}/dashboard/accounting/payin/net-credit-amount`
  return authenticatedRequest(url, 'POST', {
    body: requestData,
    callback: (_) => transferNetCreditCallback(),
  })
}

export function accountEnums() {
  const url = `${accountBaseUrl}/dashboard/enums`
  return authenticatedRequest(url, 'GET')
}

export function chargebackPagination(page, rowsPerPage) {
  const url = `${transactionBaseUrl}/chargeback`
  return authenticatedRequest(url, 'GET', { queryParams: { page: page, size: rowsPerPage } })
}

export function getChargebackDetails(chargebackId) {
  const url = `${transactionBaseUrl}/chargeback/${chargebackId}`
  return authenticatedRequest(url, 'GET')
}

export function customersWithFilters(page, rowsPerPage, filters = {}) {
  const url = `${transactionBaseUrl}/dashboard/customers`

  const queryParams = {
    page: page,
    size: rowsPerPage,
    ...filters,
  }
  return authenticatedRequest(url, 'GET', { queryParams })
}

export function payoutTransactionWithFilters(page, rowsPerPage, filters = {}) {
  const url = `${transactionBaseUrl}/dashboard/payout/transactions`

  const queryParams = {
    page: page,
    size: rowsPerPage,
    ...filters,
  }
  return authenticatedRequest(url, 'GET', { queryParams })
}

export function payeePagination(page, rowsPerPage, filters = {}) {
  const url = `${transactionBaseUrl}/dashboard/payout/payee`
  return authenticatedRequest(url, 'GET', { queryParams: { page: page, size: rowsPerPage, ...filters } })
}

export function payeeMerchantDetails(payeeMerchantId) {
  const url = `${transactionBaseUrl}/dashboard/payout/payeemerchant/${payeeMerchantId}`
  return authenticatedRequest(url, 'GET')
}

export function payoutTransactionDetails(tid) {
  const url = `${transactionBaseUrl}/dashboard/payout/transactions/${tid}`
  return authenticatedRequest(url, 'GET')
}

export function cutomerDetails(customerId) {
  const url = `${transactionBaseUrl}/dashboard/customers/${customerId}`
  return authenticatedRequest(url, 'GET')
}

export function createChargebacksUsingEmailFile(file, userErrorCallback) {
  return uploadFile(`${transactionBaseUrl}/chargeback`, file, userErrorCallback)
}

export function updateChargebackUsingRedePortalFile(status, file, userErrorCallback) {
  return uploadFile(`${transactionBaseUrl}/chargeback/update/${status}/rede-portal-file`, file, userErrorCallback)
}

export function updateChargebackUsingRedePortalFileWithEmailFile(portalFile, emailFile, userErrorCallback) {
  const data = new FormData()
  data.append('portalFile', portalFile)
  if (emailFile != null) {
    data.append('emailFile', emailFile)
  }
  return authenticatedRequest(`${transactionBaseUrl}/chargeback/update/rede-portal-file-with-email-file`, 'POST', {
    body: data,
    headers: { 'Content-Type': 'multipart/form-data' },
    callback: (response) => createToastAfterFileUpload(response),
    userErrorCallback: userErrorCallback,
  })
}

export function updateChargebackUsingFallbackFile(file, userErrorCallback) {
  return uploadFile(`${transactionBaseUrl}/chargeback/update/fallback-file`, file, userErrorCallback)
}

export function uploadSettlementFile(file, userErrorCallback) {
  return uploadFile(`${transactionBaseUrl}/settlements`, file, userErrorCallback)
}

export function getSettlementsByMechantId(merchantId) {
  const url = `${transactionBaseUrl}/settlements/merchant-id/${Number(merchantId)}`
  return authenticatedRequest(url, 'GET')
}

export function getSettlementDetails(settlementId) {
  const url = `${transactionBaseUrl}/settlements/${settlementId}`
  return authenticatedRequest(url, 'GET')
}

export function getSettlementFxDetailsByMerchantWithdrawalOperationId(merchantWithdrawalOperationId) {
  const url = `${accountingBaseUrl}/settlement/provider/merchant-withdrawal-operation/${merchantWithdrawalOperationId}`
  return authenticatedRequest(url, 'GET')
}

export function getSettlementFxDetailsByBatchWithdrawalId(batchWithdrawalId) {
  const url = `${accountingBaseUrl}/settlement/provider/batch-withdrawal/${batchWithdrawalId}`
  return authenticatedRequest(url, 'GET')
}

export function downloadAcamFileFromSettlementId(settlementId) {
  const url = `${transactionBaseUrl}/settlements/${settlementId}/acam`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    callback: (response) => csvDownloadCallback(response, `ACAM-settlementid-${settlementId}.csv`),
  })
}

export function downloadAcamFileBatchWithdrawalId(batchWithdrawalId) {
  const url = `${transactionBaseUrl}/settlements/batch/${batchWithdrawalId}/acam`
  return authenticatedRequest(url, 'GET', {
    headers: { Accept: 'text/csv' },
    callback: (response) => csvDownloadCallback(response, `ACAM-batchWithdrawalId-${batchWithdrawalId}.csv`),
  })
}

function uploadFile(url, file, userErrorCallback = defaultUserErrorCallback) {
  const data = new FormData()
  data.append('file', file)
  return authenticatedRequest(url, 'POST', {
    body: data,
    headers: { 'Content-Type': 'multipart/form-data' },
    callback: (response) => createToastAfterFileUpload(response),
    userErrorCallback: userErrorCallback,
  })
}

export function getAllProviders(page, rowsPerPage) {
  const url = `${accountingBaseUrl}/providers`
  return authenticatedRequest(url, 'GET', { queryParams: { page: page, size: rowsPerPage } })
}

export function getAllChargesFromProvider(providerId) {
  const url = `${accountingBaseUrl}/providers/${providerId}/provider-charge-configurations`
  return authenticatedRequest(url, 'GET')
}

export function getProvider(providerId) {
  const url = `${accountingBaseUrl}/providers/${providerId}`
  return authenticatedRequest(url, 'GET')
}

export function createProviderCharge(providerId, providerChargeRequest) {
  const url = `${accountingBaseUrl}/providers/${providerId}/provider-charge-configurations`
  return authenticatedRequest(url, 'POST', {
    body: providerChargeRequest,
    callback: (resp) => providerConfigurationRegisterCallback(resp),
    userErrorCallback: (resp) => providerConfigurationRegisterCallback(resp),
  })
}

export function getAllAccountingEnums() {
  const url = `${accountingBaseUrl}/dashboard/enums`
  return authenticatedRequest(url, 'GET')
}

export function getBalanceAmountsToSettle() {
  const url = `${transactionBaseUrl}/dashboard/accounting/payin/merchants/balances/to-settle`
  return authenticatedRequest(url, 'GET')
}

export function approveSettleAmounts(body) {
  const url = `${transactionBaseUrl}/settlements/batch/approve`
  return authenticatedRequest(url, 'POST', { body: body, callback: (response) => approveSettlementCallback(response) })
}

export function getSettlementsInBatchByStatus(status) {
  const queryParams = { status }
  const url = `${transactionBaseUrl}/settlements/batch`
  return authenticatedRequest(url, 'GET', { queryParams })
}

export function executeFundSentInBatch(body) {
  const url = `${transactionBaseUrl}/settlements/batch/fund-sent`
  return authenticatedRequest(url, 'POST', {
    body: body,
    callback: (response) => executeFundSentSettlementCallback(response),
    userErrorCallback: listCauses,
  })
}

export function executeWithdrawalInBatch(body) {
  const url = `${transactionBaseUrl}/settlements/batch/withdrawal`
  return authenticatedRequest(url, 'POST', {
    body: body,
    callback: (response) => executeWithdrawnSettlementCallback(response),
    userErrorCallback: listCauses,
  })
}

export function getCredentialsInfoByAccountId(accountId) {
  const url = `${authBaseUrl}/accounts/${accountId}/credentials`
  return authenticatedRequest(url, 'GET')
}
