import React, { Component } from 'react'
import { Fragment } from 'react'
import { createReceivableCsv } from '../../../services/APIClient'

class ReceivableCsv extends Component {
  handleDownloadReceivablesClick = (event) => {
    var startDate = document.getElementById('startDate').value
    var endDate = document.getElementById('endDate').value
    createReceivableCsv(startDate, endDate)
  }

  render() {
    return (
      <Fragment>
        <div className="card-modal-report">
          <div style={{ paddingLeft: '30px' }}>
            <div>
              <label>All receivables that we will receive in the selected period</label>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <label className="labelCalendarDistance">Initial date:</label>
                <input type="date" id="startDate" name="startDate" />
              </div>
              <div className="col-md-4 col-4">
                <label className="labelCalendarDistance">End date:</label>
                <input type="date" id="endDate" name="endDate" />
              </div>
              <div className="col-md-4 col-4">
                <button className="buttonSendDate" onClick={this.handleDownloadReceivablesClick}>
                  Download csv
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ReceivableCsv
