import Refunds from './Refunds'
import RefundDetailsPage from './RefundDetailsPage'

const refundRoutes = [
  {
    path: '/payin/refund',
    element: <Refunds />,
    exact: true,
  },
  {
    path: '/payin/refund/:id',
    element: <RefundDetailsPage />,
  },
]

export default refundRoutes
