import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'

export default function FxBanksThatApprovedMerchantDetails({ fxBanksThatApprovedMerchant }) {
  return (
    <>
      <h5>Fx banks that approved merchant:</h5>
      <div className="my-4">
        <Table style={{ whiteSpace: 'pre' }}>
          <TableHead>
            <TableRow>
              <TableCell className="px-0">Fx bank</TableCell>
              <TableCell className="px-0">Submission date</TableCell>
              <TableCell className="px-0">Approval date</TableCell>
            </TableRow>
          </TableHead>
          <FxBankThatApprovedMerchantRows fxBanksThatApprovedMerchant={fxBanksThatApprovedMerchant} />
          <TableBody></TableBody>
        </Table>
      </div>
    </>
  )
}

function FxBankThatApprovedMerchantRows({ fxBanksThatApprovedMerchant }) {
  if (fxBanksThatApprovedMerchant.length === 0) {
    return (
      <TableRow>
        <TableCell>No result</TableCell>
      </TableRow>
    )
  }

  return fxBanksThatApprovedMerchant.map((item) => (
    <TableRow>
      <TableCell className="px-0 capitalize">{item.fxBank}</TableCell>
      <TableCell className="px-0 capitalize">{item.requestedDate}</TableCell>
      <TableCell className="px-0 capitalize">{item.approvalDate}</TableCell>
    </TableRow>
  ))
}
