import '../styles/_app.scss'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import MatxTheme from './MatxLayout/MatxTheme/MatxTheme'
import AppContext from './appContext'

import routes from './RootRoutes'
import { Store } from './redux/Store'
import MatxLayout from './MatxLayout/MatxLayout'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => (
  <AppContext.Provider value={{ routes }}>
    <Provider store={Store}>
      <MatxTheme>
        <BrowserRouter>
          <MatxLayout />
        </BrowserRouter>
      </MatxTheme>
    </Provider>
    <ToastContainer />
  </AppContext.Provider>
)

export default App
