import { useState, useEffect } from 'react'
import { createPayoutReconciliationCsv } from '../../../services/APIClient'
import { MenuItem, Select } from '@mui/material'

export default function PayoutReconciliationCsv() {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [businessLineReport, setBusinessLineReport] = useState('GENERAL')
  const [isLoading, setIsLoading] = useState(false)

  const today = new Date()
  const maxReportDate = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0]

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  const handleDownloadClick = () => {
    setIsLoading(true)
    createPayoutReconciliationCsv(startDate, endDate, businessLineReport)
      .catch(() => alert('Something went wrong. Report could not be downloaded. Please, contact system administrator.'))
      .finally(() => setIsLoading(false))
  }

  const onDateChange = (propertyName, newValue) => {
    if (propertyName === 'startDate') {
      setStartDate(newValue)
    } else if (propertyName === 'endDate') {
      setEndDate(newValue)
    }
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Find all chargeable payout transactions in the selected period</label>
        </div>
        <div className="row w-100 mx-auto">
          <div className="col-md-6 col-6">
            <label className="labelCalendarDistance">Initial date:</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={(event) => onDateChange('startDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
          <div className="col-md-6 col-6">
            <label className="labelCalendarDistance">End date:</label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              onChange={(event) => onDateChange('endDate', event.target.value)}
              max={maxReportDate}
            />
          </div>
        </div>
        <div className="row w-100 mx-auto" style={{ paddingTop: '15px' }}>
          <div className="col-md-6 col-6">
            <Select
              value={businessLineReport}
              name="isSailor"
              id="isSailor"
              onChange={(e) => setBusinessLineReport(e.target.value)}
              options={['GENERAL', 'SAILOR']}
              className="select"
            >
              <MenuItem value="GENERAL">Not sailor</MenuItem>
              <MenuItem value="SAILOR">Sailor</MenuItem>
            </Select>
          </div>
          <div className="col-md-6 col-6">
            <button
              className="buttonSendDate"
              onClick={handleDownloadClick}
              disabled={startDate === null || endDate === null || isLoading}
            >
              Download csv
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
