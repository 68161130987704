import Select from 'react-select'
import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import { toast } from 'react-toastify'
import validateCNPJ from '../../../../services/ValidatorService'

class DocumentInput extends Component {
  constructor(props) {
    super(props)
    this.state = props.item || {}
    this.documentTypes = props.documentTypes
    this.onChange = props.onChange
    this.documentInput = React.createRef()
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangeDocument = (event) => {
    this.onAnyChanged({ document: event.target.value })
  }

  onChangeType = (event) => {
    this.onAnyChanged({ type: event.value })
  }

  onBlurDocument = (event) => {
    this.documentInput.current.setCustomValidity('')
    if (this.state.type !== undefined && this.state.type === 'BRAZIL_CNPJ') {
      if (!validateCNPJ(this.state.document)) {
        toast.warn(`The document is invalid!`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        this.documentInput.current.setCustomValidity(`The document is invalid`)
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td className="column-table">Document Type:</td>
                <td>
                  <Select
                    name="type"
                    id="type"
                    onChange={this.onChangeType}
                    options={this.documentTypes}
                    className="select-register-input"
                    classNamePrefix="documentType"
                    placeholder="Document Type"
                    value={this.documentTypes.filter((option) => option.value === this.state.type)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Document Number:</td>
                <td>
                  <input
                    className="input-inside-card account-register-input-text "
                    name="document"
                    maxLength="50"
                    onChange={this.onChangeDocument}
                    onBlur={this.onBlurDocument}
                    id="document"
                    type="text"
                    ref={this.documentInput}
                    placeholder="Document Number"
                    required
                    defaultValue={this.state.document}
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default DocumentInput
