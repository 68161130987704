import Account from './accounts-manager/Account'
import AccountDetailsPage from './accounts-manager/AccountDetailsPage'
import AccountRegister from './accounts-manager/AccountRegister'
import AccountUpdatePage from './accounts-manager/AccountUpdatePage'
import AccountToApprove from './account-to-approve/AccountToApprove'
import AccountToApproveDetailsPage from './account-to-approve/AccountToApproveDetailsPage'
import NetCredited from './net-credited/NetCreditedPage'

const accountRoutes = [
  {
    path: '/account',
    element: <Account />,
    exact: true,
  },
  {
    path: '/account/:id',
    element: <AccountDetailsPage />,
  },
  {
    path: '/register/account',
    element: <AccountRegister />,
  },
  {
    path: '/update/account/:id',
    element: <AccountUpdatePage />,
  },
  {
    path: '/account_to_approve',
    element: <AccountToApprove />,
    exact: true,
  },
  {
    path: '/account_to_approve/:id',
    element: <AccountToApproveDetailsPage />,
  },
  {
    path: '/net-credited',
    element: <NetCredited />,
  },
]

export default accountRoutes
