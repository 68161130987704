import React, { Component } from 'react'
import { Fragment } from 'react'
import { createProviderTransactionCsv } from '../../../services/APIClient'

class ReceivableByTransactionDateCsv extends Component {
  handleDownloadProvidersTransactionClick = (event) => {
    var startDate = document.getElementById('startDateProvider').value
    var endDate = document.getElementById('endDateProvider').value
    createProviderTransactionCsv(startDate, endDate)
  }

  render() {
    return (
      <Fragment>
        <div className="card-modal-report">
          <div style={{ paddingLeft: '30px' }}>
            <div>
              <label>All transactions registered by the acquirer that were carried out in the selected period</label>
            </div>
            <div className="row">
              <div className="col-md-4 col-4">
                <label className="labelCalendarDistance">Initial date:</label>
                <input type="date" id="startDateProvider" name="startDateProvider" />
              </div>
              <div className="col-md-4 col-4">
                <label className="labelCalendarDistance">End date:</label>
                <input type="date" id="endDateProvider" name="endDateProvider" />
              </div>
              <div className="col-md-4 col-4">
                <button className="buttonSendDate" onClick={this.handleDownloadProvidersTransactionClick}>
                  Download csv
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ReceivableByTransactionDateCsv
