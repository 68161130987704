import PayoutTransaction from './PayoutTransaction'
import PayoutTransactionDetailsPage from './PayoutTransactionDetailsPage'

const transactionsRoutes = [
  {
    path: '/payout/transaction',
    element: <PayoutTransaction />,
    exact: true,
  },
  {
    path: '/payout/transaction/:id',
    element: <PayoutTransactionDetailsPage />,
  },
]

export default transactionsRoutes
