import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'

class GlobalBankInput extends Component {
  constructor(props) {
    super(props)

    this.state = props.item || {}
    this.onChange = props.onChange
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangeBankName = (event) => {
    this.onAnyChanged({ bankName: event.target.value })
  }

  onChangeAccountNumber = (event) => {
    this.onAnyChanged({ accountNumber: event.target.value })
  }

  onChangeSwiftCode = (event) => {
    this.onAnyChanged({ swiftCode: event.target.value })
  }

  onChangeBeneficiaryName = (event) => {
    this.onAnyChanged({ beneficiaryName: event.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td colSpan="2">
                  <h4>Global bank info:</h4>
                </td>
              </tr>
              <tr>
                <td className="column-table">Bank Name:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="bankName"
                    maxLength="100"
                    onChange={this.onChangeBankName}
                    id="bankName"
                    type="text"
                    placeholder="Bank Name"
                    required
                    defaultValue={this.state.bankName}
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Account Number:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="accountNumber"
                    maxLength="30"
                    onChange={this.onChangeAccountNumber}
                    id="accountNumber"
                    type="text"
                    placeholder="Account Number"
                    required
                    defaultValue={this.state.accountNumber}
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Swift Code:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="swiftCode"
                    maxLength="30"
                    onChange={this.onChangeSwiftCode}
                    id="swiftCode"
                    type="text"
                    placeholder="Swift Code"
                    required
                    defaultValue={this.state.swiftCode}
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Beneficiary name:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="beneficiaryName"
                    maxLength="30"
                    onChange={this.onChangeBeneficiaryName}
                    id="beneficiaryName"
                    type="text"
                    placeholder="Beneficiary name"
                    required
                    defaultValue={this.state.beneficiaryName}
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default GlobalBankInput
