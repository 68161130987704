import React from 'react'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../../matx'
import RefundDetails from './RefundDetails'

export default function RefundDetailsPage() {
  const { id } = useParams()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Refunds', path: '/payin/refund' }, { name: 'Details' }]} />
      </div>
      <RefundDetails id={id} />
    </div>
  )
}
