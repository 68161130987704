import React from 'react'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../../matx'
import AccountDetails from './AccountToApproveDetails'

export default function AccountToApproveDetailsPage() {
  const { id } = useParams()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Account', path: '/account' }, { name: 'Details' }]} />
      </div>
      <AccountDetails id={id} />
    </div>
  )
}
