import React, { Component } from 'react'
import { Card, Grid, Button } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { loginWithEmailAndPassword } from '../../redux/actions/LoginActions'

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

class SignIn extends Component {
  state = {
    email: '',
    password: '',
  }

  handleLoginFieldsChange = (event) => {
    event.persist()
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleFormSubmit = (event) => {
    this.props.loginWithEmailAndPassword({ ...this.state })
  }

  render() {
    let { email, password } = this.state
    let { classes } = this.props
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img src="/assets/images/illustrations/dreamer.svg" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 card-login position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleLoginFieldsChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={['required', 'isEmail']}
                      errorMessages={['Email é obrigatório', 'Este email não é válido']}
                    />
                    <TextValidator
                      className="mb-16 w-100"
                      label="Senha"
                      variant="outlined"
                      onChange={this.handleLoginFieldsChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={['required']}
                      errorMessages={['Senha é obrigatória']}
                    />
                    <div className="flex flex-middle mb-8">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#1A2038', color: '#f8f8f8' }}
                          type="submit"
                        >
                          Login
                        </Button>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
})

export default withStyles(connect(mapStateToProps, { loginWithEmailAndPassword })(SignIn), styles, { withTheme: true })
