import { useEffect, useState } from 'react'
import { uploadSettlementFile } from '../../services/APIClient'
import { Breadcrumb, SimpleCard } from '../../../matx'

import Loading from '../../components/Loading'

export function SettlementFile() {
  const [file, setFile] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [csvError, setCsvError] = useState(undefined)

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = 'wait'
    } else {
      document.body.style.cursor = 'default'
    }
  }, [isLoading])

  async function errorCallback(response) {
    const responseBody = await response.json()
    setCsvError(responseBody)
  }

  async function onSubmit(event) {
    event.preventDefault()
    setCsvError(undefined)
    setIsLoading(true)
    uploadSettlementFile(file, errorCallback)
      .catch((e) => setCsvError({ errorMessage: 'unexpected error', errorCauses: [] }))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Settlements', path: '/settlement-file' }, { name: 'File' }]} />
      </div>
      <div className="m-sm-30">
        <SimpleCard title="Settlement">
          <div>
            <label>Upload the settlement csv</label>
            <form method="post" encType="multipart/form-data" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-6 col-4">
                  <input
                    type="file"
                    className="form-control-file"
                    id="file"
                    name="file"
                    accept=".csv"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="col-md-6 col-4">
                  <button type="submit" className="btn btn-info" disabled={file === undefined}>
                    Upload file
                  </button>
                </div>
              </div>
            </form>
            {isLoading ? <Loading /> : <></>}
            {csvError === undefined ? (
              <></>
            ) : (
              <>
                <h3>{csvError.errorMessage}</h3>
                <ul style={{ color: '#e53935' }}>
                  {csvError.errorCauses.map((error) => (
                    <li key={error.cause}>{error.cause}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </SimpleCard>
      </div>
    </div>
  )
}
