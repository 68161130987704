import React, { Component, Fragment } from 'react'
import Scrollbar from 'react-perfect-scrollbar'

import { navigations } from '../../navigations'
import { MatxVerticalNav } from '../../../matx'

class Sidenav extends Component {
  state = {}

  updateSidebarMode = (sidebarSettings) => {
    let { settings, setLayoutSettings } = this.props
    let activeLayoutSettingsName = settings.activeLayout + 'Settings'
    let activeLayoutSettings = settings[activeLayoutSettingsName]

    setLayoutSettings({
      ...settings,
      [activeLayoutSettingsName]: {
        ...activeLayoutSettings,
        leftSidebar: {
          ...activeLayoutSettings.leftSidebar,
          ...sidebarSettings,
        },
      },
    })
  }

  renderOverlay = () => <div onClick={() => this.updateSidebarMode({ mode: 'close' })} className="sidenav__overlay" />
  render() {
    return (
      <Fragment>
        <Scrollbar options={{ suppressScrollX: true }} className="scrollable position-relative">
          {this.props.children}
          <MatxVerticalNav navigation={navigations} />
        </Scrollbar>
        {this.renderOverlay()}
      </Fragment>
    )
  }
}

export default Sidenav
