import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { convertToBrazilianTimeZone } from '../../../../services/FormatterService'
import { getCredentialsInfoByAccountId } from '../../../../services/APIClient'
import Loading from '../../../../components/Loading'

export function ViewCredentials({ accountId }) {
  const [isLoading, setIsLoading] = React.useState(true)
  const [credentialsInfo, setCredentialsInfo] = React.useState(null)
  const columns = ['Client ID', 'Intermediary ID', 'Permissions', 'Created At']

  React.useEffect(() => {
    getCredentialsInfoByAccountId(accountId)
      .then((resp) => {
        setCredentialsInfo(resp.credentialsInfo)
      })
      .catch(() => alert('Something went wrong. Please, contact system administrator.'))
      .finally(setIsLoading(false))
  }, [accountId])

  return (
    <>
      {isLoading || credentialsInfo == null ? (
        <Loading />
      ) : (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((item) => (
                  <TableCell key={item} className="px-0">
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {credentialsInfo.map((item) => (
                <TableRow key={item}>
                  <TableCell className="px-0 capitalize">{item.clientId}</TableCell>
                  <TableCell className="px-0 capitalize">{item.intermediaryId}</TableCell>
                  <TableCell className="px-0 capitalize">{item.roles.join(', ')}</TableCell>
                  <TableCell className="px-0 capitalize">{convertToBrazilianTimeZone(item.createdAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  )
}
