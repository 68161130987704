import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import TouchRipple from '@mui/material/ButtonBase'
import { classList } from '../../../utils'
import { useLocation } from 'react-router-dom'

const styles = (theme) => {
  return {
    expandIcon: {
      transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
      transform: 'rotate(90deg)',
      // marginRight: "16px"
    },
    collapseIcon: {
      transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
      transform: 'rotate(0deg)',
      // marginRight: "16px"
    },
    'expansion-panel': {
      overflow: 'hidden',
      transition: 'max-height 0.3s cubic-bezier(0, 0, 0.2, 1)',
    },
    highlight: {
      background: theme.palette.primary.main,
    },
  }
}

function MatxVerticalNavExpansionPanel({ classes, children, item: { name, icon, badge } }) {
  let location = useLocation()

  let [collapsed, setCollapsed] = useState(true)
  let [componentHeight, setComponentHeight] = useState(0)

  let elementRef = useRef(null)

  let handleClick = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    let calculateHeight = (node) => {
      if (node.name === 'child') return node.clientHeight

      return node.clientHeight + [...node.childNodes].reduce((acc, curr) => acc + calculateHeight(curr), 0)
    }

    let current = elementRef.current

    setComponentHeight(calculateHeight(current))

    // OPEN DROPDOWN IF CHILD IS ACTIVE
    for (let child of current.children) {
      if (child.getAttribute('href') === location.pathname) {
        setCollapsed(false)
      }
    }
  }, [location])

  return (
    <div>
      <TouchRipple
        className={classList({
          'nav-item flex-middle h-48 w-100 has-submenu': true,
          open: !collapsed,
        })}
        onClick={handleClick}
      >
        <div>
          <Icon className="text-middle item-icon">{icon}</Icon>
          <span className="text-middle pl-20 item-text">{name}</span>
        </div>
        {badge && <div className={`badge bg-${badge.color}`}>{badge.value}</div>}
        <div className={collapsed ? classes.collapseIcon + ' item-arrow' : classes.expandIcon + ' item-arrow'}>
          <Icon className="text-middle">chevron_right</Icon>
        </div>
      </TouchRipple>

      <div
        ref={elementRef}
        className={classes['expansion-panel'] + ' submenu'}
        style={collapsed ? { maxHeight: '0px' } : { maxHeight: componentHeight + 'px' }}
      >
        {children}
      </div>
    </div>
  )
}

export default withStyles(MatxVerticalNavExpansionPanel, styles)
