import { useEffect, useState } from 'react'
import { Breadcrumb, SimpleCard } from '../../../matx'
import { getSettlementsByMechantId, allAccountsInfo } from '../../services/APIClient'
import Loading from '../../components/Loading'
import TableContent from '../../components/TableContent'
import SettlementTableRow from './SettlementTableRow'
import { Autocomplete, TextField } from '@mui/material'

export default function Settlements() {
  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Settlements' }]} />
      </div>
      <SettlementTable />
    </div>
  )
}

function SettlementTable() {
  const [settlements, setSettlements] = useState([])
  const [choosedMerchant, setChoosedMerchant] = useState(null)
  const [allMerchants, setAllMerchants] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const columns = [
    'Id',
    'Externalized Id',
    'Request at',
    'Approved at',
    'Execution at',
    'Settlement amount',
    'Mer. FX tax amount',
    'Net amount',
    'Status',
  ]

  useEffect(() => {
    allAccountsInfo().then((response) => {
      const merchants = response.result.map((merchant) => {
        return { label: `${merchant.id} - ${merchant.softDescriptor}`, id: merchant.id }
      })
      setAllMerchants(merchants)
    })
  }, [])

  useEffect(() => {
    setSettlements([])
    if (choosedMerchant === null) {
      return
    }
    setIsLoading(true)
    getSettlementsByMechantId(choosedMerchant.id).then((response) => {
      setSettlements(response.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)))
      setIsLoading(false)
    })
  }, [choosedMerchant])

  return (
    <div className="m-sm-30">
      <SimpleCard title="Settlements">
        <div>
          <label>Choose merchant:</label>
          <Autocomplete
            includeInputInList
            options={allMerchants}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Merchant" />}
            value={choosedMerchant}
            onChange={(event, newValue) => {
              setChoosedMerchant(newValue)
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>

        {choosedMerchant === null ? (
          <></>
        ) : isLoading ? (
          <Loading />
        ) : (
          <TableContent items={settlements} columns={columns} Row={SettlementTableRow} />
        )}
      </SimpleCard>
    </div>
  )
}
