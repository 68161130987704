import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { SimpleCard, Breadcrumb } from '../../../../matx'
import PaginationTable from '../../../../matx/components/PaginationTable'
import { currencyWithTwoDecimals } from '../../../services/FormatterService'
import { useNavigate } from 'react-router-dom'
import StatusLabel from '../../../components/StatusLabel'
import { chargebackPagination } from '../../../services/APIClient'

export default function ChargebackTable() {
  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Chargebacks' }]} />
      </div>
      <div className="m-sm-30">
        <div className="py-12" />
        <SimpleCard>
          <PaginationTable
            queryItems={chargebackPagination}
            tableName="Chargebacks"
            tableContent={(filteredItems) => <ChargebackTableContent items={filteredItems} />}
            attributesToFilter={['merchantSoftDescriptor', 'providerNsu', 'providerTid', 'status']}
            hasSailorSwitch={false}
          />
        </SimpleCard>
      </div>
    </div>
  )
}

function ChargebackTableContent({ items }) {
  const navigate = useNavigate()

  function handleClick(_, id) {
    navigate(`/payin/chargeback/${id}`)
  }

  return (
    <Table style={{ whiteSpace: 'pre' }}>
      <TableHead>
        <TableRow>
          <TableCell className="px-0">Chargeback Id</TableCell>
          <TableCell className="px-0">Provider TID</TableCell>
          <TableCell className="px-0">Transaction id</TableCell>
          <TableCell className="px-0">Merchant soft descriptor</TableCell>
          <TableCell className="px-0">Amount</TableCell>
          <TableCell className="px-0">Solution net amount</TableCell>
          <TableCell className="px-0">Opening date</TableCell>
          <TableCell className="px-0">Deadline date</TableCell>
          <TableCell className="px-0">Solution date</TableCell>
          <TableCell className="px-0">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((chargeback) => (
          <TableRow key={chargeback.chargebackId} onClick={(event) => handleClick(event, chargeback.chargebackId)}>
            <TableCell className="px-0 capitalize" align="left">
              {chargeback.chargebackId}
            </TableCell>
            <TableCell>{chargeback.providerTid}</TableCell>
            <TableCell>{chargeback.transactionId}</TableCell>
            <TableCell align="left">{chargeback.merchantSoftDescriptor}</TableCell>
            <TableCell align="left">{currencyWithTwoDecimals(chargeback.amount)}</TableCell>
            <TableCell align="left">{currencyWithTwoDecimals(chargeback.solutionNetAmount)}</TableCell>
            <TableCell align="left">{chargeback.openingDate}</TableCell>
            <TableCell align="left">{chargeback.deadlineDate}</TableCell>
            <TableCell align="left">{chargeback.solutionDate}</TableCell>
            <TableCell align="left">
              <StatusLabel color={defineStatusColorRefund(chargeback.status)} label={chargeback.status} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function defineStatusColorRefund(status) {
  switch (status) {
    case 'CANCELED_BY_CONSUMER':
    case 'CANCELED_BY_ACQUIRER':
    case 'DISPUTE_WON':
      return 'bg-green'
    case 'CHARGEBACK_REFUNDED':
      return 'bg-error'
    case 'OPENED':
      return 'bg-secondary'
    case 'DISPUTED_BY_MERCHANT':
      return 'bg-chargeback-attention'
    default:
      return 'bg-error'
  }
}
