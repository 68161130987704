import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { accountRegister } from '../../../services/APIClient'
import { isEmpty } from 'lodash'
import { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import AccountUpdateForm from './form/AccountUpdateForm'

class AccountRegistrationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountInfo: {
        type: null,
        name: null,
        tradeName: null,
        website: null,
        productType: null,
        productTypeDescription: null,
        businessModelDescription: null,
        softDescriptor: null,
        mcc: null,
        address: {
          countryCode: null,
          state: null,
          city: null,
          zipCode: null,
          street: null,
          number: null,
          complement: null,
          neighbourhood: null,
        },
        bank: {
          type: null,
          brazil: {},
          global: {},
        },
        comments: [],
        cnae: null,
        taxRegime: null,
        legalNature: null,
        branchActivity: null,
        billing: null,
        webhooks: [],
        documents: [{}],
        contacts: [],
        ubos: [],
        partners: [],
        fees: [],
        ecommercePlatformName: null,
        businessLine: null,
        settlementCurrency: null,
        settlementPeriods: [],
        fxBanksThatApprovedMerchant: [],
      },
    }
  }

  registerAccount = async () => {
    const accountInfo = this.state.accountInfo

    accountRegister({
      ...accountInfo,
      contacts: accountInfo.contacts.filter((item) => !isEmpty(item)),
      documents: accountInfo.documents.filter((item) => !isEmpty(item)),
      webhooks: accountInfo.webhooks.filter((item) => !isEmpty(item)),
      partners: accountInfo.partners.filter((item) => !isEmpty(item)),
      fees: accountInfo.fees.filter((item) => !isEmpty(item)),
      ubos: accountInfo.ubos.filter((item) => !isEmpty(item)),
      settlementPeriods: accountInfo.settlementPeriods.filter((item) => !isEmpty(item)),
    })
  }

  render() {
    return (
      <AccountUpdateForm
        accountInfo={this.state.accountInfo}
        onSubmit={() => this.registerAccount()}
        onChange={(accountInfo) => this.setState({ accountInfo: accountInfo })}
        isEdit={false}
      />
    )
  }
}

export default AccountRegistrationPage
