import React, { Fragment } from 'react'
import { Icon } from '@mui/material'
import { NavLink } from 'react-router-dom'

const Breadcrumb = ({ routeSegments, title }) => {
  let choosenTitle = defineTitle(routeSegments, title)

  return (
    <div className="flex flex-middle position-relative">
      {choosenTitle ? (
        <>
          <span className="capitalize text-muted">{choosenTitle}</span>
          <span className="ml-8 mr-8 text-muted">|</span>
        </>
      ) : null}
      <NavLink to="/payin/transaction" className="home-nav">
        <Icon className="" color="secondary">
          home
        </Icon>
      </NavLink>
      {routeSegments
        ? routeSegments.map((route, index) => (
            <Fragment key={index}>
              <Icon className="text-middle">navigate_next</Icon>
              {route.path != null ? (
                <NavLink to={route.path}>
                  <span className="capitalize text-muted">{route.name}</span>
                </NavLink>
              ) : (
                <span className="capitalize text-muted">{route.name}</span>
              )}
            </Fragment>
          ))
        : null}
    </div>
  )
}

const defineTitle = (routeSegments, title) => {
  if (title) return title
  if (routeSegments) return routeSegments[routeSegments.length - 1]['name']
  return null
}

export default Breadcrumb
