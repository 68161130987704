import { useState, useEffect } from 'react'
import { SimpleCard } from '../../../matx'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import React from 'react'
import {
  getSettlementDetails,
  getSettlementFxDetailsByBatchWithdrawalId,
  downloadAcamFileFromSettlementId,
  getSettlementFxDetailsByMerchantWithdrawalOperationId,
} from '../../services/APIClient'
import 'bootstrap/dist/css/bootstrap.min.css'
import { convertToBrazilianTimeZone, currency } from '../../services/FormatterService'
import Loading from '../../components/Loading'

export function SettlementDetails({ id }) {
  const [details, setDetails] = useState(null)
  const [queryHasFailed, setQueryHasFailed] = useState(false)
  const [isInBatch, setIsInBatch] = useState(false)

  useEffect(() => {
    const getSettlementDetailsContent = async (settlementId) => {
      try {
        const settlementInfo = await getSettlementDetails(settlementId)

        let settlementFxInfo = null
        if (settlementInfo.batchWithdrawalId !== null && settlementInfo.batchWithdrawalId !== undefined) {
          setIsInBatch(true)
          settlementFxInfo = await getSettlementFxDetailsByBatchWithdrawalId(settlementInfo.batchWithdrawalId)
        } else {
          settlementFxInfo = await getSettlementFxDetailsByMerchantWithdrawalOperationId(settlementId)
          setIsInBatch(false)
        }

        setDetails({
          settlementInfo,
          settlementFxInfo,
        })
      } catch (error) {
        setQueryHasFailed(true)
      }
    }

    getSettlementDetailsContent(id)
  }, [id])

  if (queryHasFailed || details === undefined) {
    return <div>Something went wrong</div>
  }

  function foreignCurrencySymbol() {
    if (details.settlementInfo.foreignCurrency === 'USD') {
      return '$'
    }
    if (details.settlementInfo.foreignCurrency === 'EUR') {
      return '€'
    }
    return ''
  }

  return (
    <div className="First card">
      <SimpleCard title="Settlement Details">
        <div>
          {details === null ? (
            <Loading />
          ) : (
            <>
              <Container>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h5>Settlement info:</h5>
                  {details.settlementInfo.transactionsAvailabilityCutOffDateForACAM != null &&
                    details.settlementInfo.batchWithdrawalId === undefined && (
                      <button
                        type="button"
                        className="btn btn-success"
                        style={{ float: 'left' }}
                        onClick={() => downloadAcamFileFromSettlementId(id)}
                      >
                        Download ACAM file
                      </button>
                    )}
                </div>
                <BTable striped bordered hover>
                  <tbody>
                    <tr>
                      <td>Id</td>
                      <td>{details.settlementInfo.id}</td>
                    </tr>
                    <tr>
                      <td>Batch withdrawal id</td>
                      <td>{details.settlementInfo.batchWithdrawalId}</td>
                    </tr>
                    <tr>
                      <td>Externalized Id</td>
                      <td>{details.settlementInfo.externalId}</td>
                    </tr>
                    <tr>
                      <td>Settlement status</td>
                      <td>{details.settlementInfo.status}</td>
                    </tr>
                    <tr>
                      <td>Intermediary withdrawal id</td>
                      <td>{details.settlementInfo.intermediaryWithdrawalId}</td>
                    </tr>
                    <tr>
                      <td>Merchant withdrawal id</td>
                      <td>{details.settlementInfo.merchantWithdrawalId}</td>
                    </tr>
                    <tr>
                      <td>Merchant soft descriptor</td>
                      <td>{details.settlementInfo.merchantSoftDescriptor}</td>
                    </tr>
                    <tr>
                      <td>Requested at</td>
                      <td>{convertToBrazilianTimeZone(details.settlementInfo.requestedDate)}</td>
                    </tr>
                    <tr>
                      <td>Approved at</td>
                      <td>{convertToBrazilianTimeZone(details.settlementInfo.approvedDate)}</td>
                    </tr>
                    <tr>
                      <td>Executed at</td>
                      <td>{convertToBrazilianTimeZone(details.settlementInfo.executionDate)}</td>
                    </tr>
                    <tr>
                      <td>Fund sent at</td>
                      <td>{convertToBrazilianTimeZone(details.settlementInfo.fundSentDate)}</td>
                    </tr>
                    <tr>
                      <td>Receipt date</td>
                      <td>{convertToBrazilianTimeZone(details.settlementInfo.receiptDate)}</td>
                    </tr>
                    <tr>
                      <td>Transactions availability cut off date for ACAM</td>
                      <td>{details.settlementInfo.transactionsAvailabilityCutOffDateForACAM}</td>
                    </tr>
                  </tbody>
                </BTable>
              </Container>
              <Container>
                <h5>Merchant Amount's:</h5>
                <BTable striped bordered hover>
                  <tbody>
                    <tr>
                      <td>Base FX rate</td>
                      <td>{details.settlementFxInfo?.baseFxRate}</td>
                    </tr>
                    <tr>
                      <td>LL FX rate</td>
                      <td>{details.settlementInfo.llFxRate}</td>
                    </tr>
                    <tr>
                      <td>Merchant FX spread</td>
                      <td>{details.settlementInfo.merchantFxSpread}</td>
                    </tr>
                    <tr>
                      <td>Merchant FX tax rate</td>
                      <td>{details.settlementInfo.fxTaxRate}</td>
                    </tr>
                    <tr>
                      <td>Local currency</td>
                      <td>{details.settlementFxInfo?.localCurrency}</td>
                    </tr>
                    <tr>
                      <td>Foreign currency</td>
                      <td>{details.settlementFxInfo?.foreignCurrency}</td>
                    </tr>
                    <tr>
                      <td>Merchant contracted settlement fee in foreign currency</td>
                      <td>{details.settlementInfo.merchantContractedWithdrawalFeeInForeignCurrency}</td>
                    </tr>
                    <tr>
                      <td>Merchant contracted settlement fee type</td>
                      <td>{details.settlementInfo.merchantContractedWithdrawalFeeType}</td>
                    </tr>
                    <tr>
                      <td>Merchant settlement fee in foreign currency</td>
                      <td>
                        {currency(
                          details.settlementInfo.merchantWithdrawalFeeInForeignCurrency,
                          foreignCurrencySymbol(),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Merchant settlement amount</td>
                      <td>{currency(details.settlementInfo.settlementAmount)}</td>
                    </tr>
                    <tr>
                      <td>Merchant settlement amount after deducting FX charges</td>
                      <td>{currency(details.settlementInfo.settlementAmountAfterDeductingFxCharges)}</td>
                    </tr>
                    <tr>
                      <td>Merchant settlement fee</td>
                      <td>{currency(details.settlementInfo.merchantSettlementFee)}</td>
                    </tr>
                    <tr>
                      <td>Merchant FX tax amount</td>
                      <td>{currency(details.settlementInfo.merchantFxTaxAmount)}</td>
                    </tr>
                    <tr>
                      <td>Amount received by merchant in foreign currency</td>
                      <td>
                        {currency(
                          details.settlementInfo.amountReceivedByMerchantInForeignCurrency,
                          foreignCurrencySymbol(),
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Amount sent in foreign currency</td>
                      <td>{currency(details.settlementInfo.amountSentInForeignCurrency, foreignCurrencySymbol())}</td>
                    </tr>
                  </tbody>
                </BTable>
              </Container>
              <Container>
                <h5>Bank Amount's:</h5>
                <BTable striped bordered hover>
                  <tbody>
                    <tr>
                      <td>FX bank</td>
                      <td>{details.settlementFxInfo?.fxBank}</td>
                    </tr>
                    <tr>
                      <td>FX contract id </td>
                      <td>{details.settlementFxInfo?.fxContractId}</td>
                    </tr>
                    <tr>
                      <td>FX settlement cycle</td>
                      <td>{details.settlementFxInfo?.fxSettlementCycle}</td>
                    </tr>
                    <tr>
                      <td>UETR in swift message</td>
                      <td>{details.settlementFxInfo?.uetrInSwiftMessage}</td>
                    </tr>
                    <tr>
                      <td>Bank FX tax rate</td>
                      <td>{details.settlementFxInfo?.fxTaxRate}</td>
                    </tr>
                    <tr>
                      <td>Bank FX rate</td>
                      <td>{details.settlementFxInfo?.providerFxRate}</td>
                    </tr>
                    <tr>
                      <td>Bank FX spread</td>
                      <td>{details.settlementFxInfo?.providerFxSpread}</td>
                    </tr>
                    <tr>
                      <td>Settlement amount sent to FX bank</td>
                      {isInBatch ? <td></td> : currency(details.settlementFxInfo?.fxAmountSentToProvider)}
                    </tr>
                    <tr>
                      <td>Bank FX tax amount</td>
                      {isInBatch ? <td></td> : currency(details.settlementFxInfo?.dueFxTaxAmount)}
                    </tr>
                    <tr>
                      <td>Bank settlement fee</td>
                      {isInBatch ? <td></td> : currency(details.settlementFxInfo?.dueFxFee)}
                    </tr>
                    <tr>
                      <td>Settlement amount sent to FX bank after deducting FX Charges</td>
                      {isInBatch ? <td></td> : currency(details.settlementFxInfo?.fxAmountAfterDeductingFxCharges)}
                    </tr>
                    <tr>
                      <td>Bank settlement fee in foreign currency</td>
                      {isInBatch ? (
                        <td></td>
                      ) : (
                        currency(details.settlementFxInfo?.dueFxFeeInForeignCurrency, foreignCurrencySymbol())
                      )}
                    </tr>
                  </tbody>
                </BTable>
              </Container>
              <Container>
                <h5>Additional info:</h5>
                <pre style={{ border: '1px solid rgb(222, 226, 230)' }}>
                  {JSON.stringify(details.settlementInfo.additionalInfo, null, 2)}
                </pre>
              </Container>
            </>
          )}
        </div>
      </SimpleCard>
    </div>
  )
}
