import React, { Component } from 'react'
import Select from 'react-select'
import { createReconciliationByStatusTransaction } from '../../../services/APIClient'
import { Fragment } from 'react'

class ReconciliationSearchCsv extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statusType: [
        { value: 'ANALYSIS_FAILED', label: 'ANALYSIS_FAILED' },
        { value: 'AUTHORIZED', label: 'AUTHORIZED' },
        { value: 'CANCELED', label: 'CANCELED' },
        { value: 'CAPTURED', label: 'CAPTURED' },
        { value: 'CHARGEBACK_DISPUTE', label: 'CHARGEBACK_DISPUTE' },
        { value: 'CHARGEBACK_NOT_PROCESSED', label: 'CHARGEBACK_NOT_PROCESSED' },
        { value: 'CHARGEBACK_PROCESSED', label: 'CHARGEBACK_PROCESSED' },
        { value: 'CREATED', label: 'CREATED' },
        { value: 'DENIED', label: 'DENIED' },
        { value: 'FAILED', label: 'FAILED' },
        { value: 'OPERATIONAL_SETTLEMENT', label: 'OPERATIONAL_SETTLEMENT' },
        { value: 'PAID', label: 'PAID' },
        { value: 'PARTIALLY_REFUNDED', label: 'PARTIALLY_REFUNDED' },
        { value: 'PROCESSING', label: 'PROCESSING' },
        { value: 'REFUNDED', label: 'REFUNDED' },
      ],
      statusToSearch: [],
    }
  }

  onChangeStatusList = (events) => {
    if (events) {
      this.setState({
        statusToSearch: events.map((event) => event.value),
      })
    }
  }

  handleDownloadClick = (event) => {
    var startDate = document.getElementById('searchStartDate').value
    var endDate = document.getElementById('searchEndDate').value
    createReconciliationByStatusTransaction(startDate, endDate, this.state.statusToSearch)
  }

  render() {
    return (
      <Fragment>
        <div className="card-modal-report">
          <div style={{ paddingLeft: '30px' }}>
            <div>
              <label>Reconciliation V0 - Find all transactions by status in the selected period</label>
            </div>
            <div className="row w-100 mx-auto">
              <div className="col-md-6 col-6">
                <label className="labelCalendarDistance">Initial date:</label>
                <input type="date" id="searchStartDate" name="searchStartDate" />
              </div>
              <div className="col-md-6 col-6">
                <label className="labelCalendarDistance">End date:</label>
                <input type="date" id="searchEndDate" name="searchEndDate" />
              </div>
            </div>
            <div className="row w-100 mx-auto" style={{ paddingTop: '15px' }}>
              <div className="col-md-6 col-6">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="status"
                  id="status"
                  onChange={this.onChangeStatusList}
                  options={this.state.statusType}
                  className="basic-multi-select"
                  classNamePrefix="statusType"
                  placeholder="statusType"
                />
              </div>
              <div className="col-md-6 col-6">
                <button className="buttonSendDate" onClick={this.handleDownloadClick}>
                  Download csv
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ReconciliationSearchCsv
