import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'

export default function BoletoProviderChargeInsert({ item, onChange, boletoChargedStatuses, chargeValueTypes }) {
  const [chargeBeingInserted, setChargeBeingInserted] = useState({
    chargeValue: null,
    chargedOnStatus: null,
    chargeValueType: null,
    serviceType: 'BOLETO',
    ...item,
  })

  const onChangeProperty = (propertyName, value) => {
    chargeBeingInserted[propertyName] = value
    setChargeBeingInserted({ ...chargeBeingInserted })
    onChange(chargeBeingInserted)
  }

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">Charge value:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="chargeValue"
                  onChange={(event) => onChangeProperty('chargeValue', event.target.value)}
                  id="chargeValue"
                  type="number"
                  placeholder="charge value"
                  defaultValue={chargeBeingInserted.chargeValue}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Charge value type:</td>
              <td>
                <Select
                  name="chargeValueType"
                  id="chargeValueType"
                  onChange={(event) => onChangeProperty('chargeValueType', event.value)}
                  options={chargeValueTypes}
                  placeholder="charge value type"
                  value={chargeValueTypes.filter((option) => option.value === chargeBeingInserted.chargeValueType)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Charge on:</td>
              <td>
                <Select
                  name="chargedOnStatus"
                  id="chargedOnStatus"
                  onChange={(event) => onChangeProperty('chargedOnStatus', event.value)}
                  options={boletoChargedStatuses}
                  placeholder="Charge on"
                  value={boletoChargedStatuses.filter((option) => option.value === chargeBeingInserted.chargedOnStatus)}
                  required
                />
              </td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </>
  )
}
