import React, { Component } from 'react'
import { Breadcrumb } from '../../../../matx'
import TransactionTable from './PayinTransactionTable'
import { ErrorBoundary } from 'react-error-boundary'

export default class PayinTransaction extends Component {
  render() {
    return (
      <div className="m-sm-30">
        <div className="mb-sm-30">
          <Breadcrumb routeSegments={[{ name: 'Transactions' }]} />
        </div>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <TransactionTable />
        </ErrorBoundary>
      </div>
    )
  }
}
