import ReportMainPage from './ReportMainPage'

const ReportRoutes = [
  {
    path: '/reports',
    element: <ReportMainPage />,
  },
]

export default ReportRoutes
