import React from 'react'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../matx'
import ReceivableDetails from './CercReceivableDetails'

const CercReceivableDetailsPage = () => {
  let { id } = useParams()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb
          title="Receivable Details"
          routeSegments={[{ name: 'CERC' }, { name: 'Receivables', path: '/cerc/receivable' }, { name: `#${id}` }]}
        />
        <ReceivableDetails id={id} />
      </div>
    </div>
  )
}

export default CercReceivableDetailsPage
