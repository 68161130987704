import { SimpleCard } from '../../../matx'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import PayeeMerchantTable from '../../components/payout/PayeeMerchantTable'

export function PayeeMerchantInPayoutDetails(props) {
  function checkStatus(status) {
    switch (status) {
      case 'DENIED':
        return '#ff5a5f'
      case 'APPROVED':
        return '#C6ECAE'
      default:
        return '#f9ea9a'
    }
  }

  return (
    <div className="First card">
      {props.details === null ? (
        ''
      ) : (
        <SimpleCard title="Payee merchant Details" bgcolor={checkStatus(props.details.result)}>
          <div>
            <PayeeMerchantTable payeeMerchantDetails={props.details} />
          </div>
        </SimpleCard>
      )}
    </div>
  )
}

export default PayeeMerchantInPayoutDetails
