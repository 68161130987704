import CercReceivableDetailsPage from './CercReceivableDetailsPage'
import CercReceivablePage from './CercReceivablesPage'
import CercReceivablesCalendar from './CercReceivablesCalendar'

const cercRoutes = [
  {
    path: '/cerc/receivable',
    element: <CercReceivablePage />,
    exact: true,
  },
  {
    path: '/cerc/receivable/calendar',
    element: <CercReceivablesCalendar />,
  },
  {
    path: '/cerc/receivable/:id',
    element: <CercReceivableDetailsPage />,
  },
]

export default cercRoutes
