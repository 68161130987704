import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'

class CreditPeriodInput extends Component {
  constructor(props) {
    super(props)

    this.state = props.item || { isBusinessDay: false }
    this.onChange = props.onChange
    this.index = props.index
  }

  onAnyChanged = (change) => {
    let newState = { ...this.state, ...change }
    this.setState(newState)
    this.onChange(newState)
  }

  onChangeProperty = (property, value) => {
    this.onAnyChanged({ [property]: value })
  }

  render() {
    const { accountingEntryTypes, paymentMethods } = this.props

    return (
      <React.Fragment>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td className="column-table">Accounting Entry Type:</td>
                <td>
                  <Select
                    name="accountingEntryType"
                    id="accountingEntryType"
                    onChange={(event) => this.onChangeProperty('accountingEntryType', event.value)}
                    options={accountingEntryTypes}
                    className="select-register-input"
                    classNamePrefix="accountingEntryType"
                    placeholder="Accounting Entry Type"
                    isDisabled={!this.state.removable}
                    value={accountingEntryTypes.filter((option) => option.value === this.state.accountingEntryType)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Payment Method:</td>
                <td>
                  <Select
                    name="paymentMethod"
                    id="paymentMethod"
                    onChange={(event) => this.onChangeProperty('paymentMethod', event.value)}
                    options={paymentMethods}
                    className="select-register-input"
                    classNamePrefix="paymentMethod"
                    placeholder="Payment Method"
                    isDisabled={!this.state.removable}
                    value={paymentMethods.filter((option) => option.value === this.state.paymentMethod)}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Days:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="days"
                    maxLength="2"
                    onChange={(event) => this.onChangeProperty('days', event.target.value)}
                    id="days"
                    type="number"
                    placeholder="Days"
                    disabled={!this.state.removable}
                    defaultValue={this.state.days}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Effective date:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="effectiveDate"
                    onChange={(event) => this.onChangeProperty('effectiveDate', event.target.value)}
                    step="any"
                    id="effectiveDate"
                    type="date"
                    placeholder="Effective Date"
                    defaultValue={this.state.effectiveDate}
                    disabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>

              <tr>
                <td className="column-table">Business Day:</td>
                <td>
                  <div>
                    <input
                      type="radio"
                      value="true"
                      name={`isBusinessDay-${this.index}`}
                      defaultChecked={this.state.isBusinessDay}
                      disabled={!this.state.removable}
                      onChange={(event) => this.onChangeProperty('isBusinessDay', event.target.value)}
                      required
                    />{' '}
                    Yes
                    <input
                      type="radio"
                      value="false"
                      name={`isBusinessDay-${this.index}`}
                      defaultChecked={!this.state.isBusinessDay}
                      disabled={!this.state.removable}
                      onChange={(event) => this.onChangeProperty('isBusinessDay', event.target.value)}
                      required
                    />{' '}
                    No
                  </div>
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </React.Fragment>
    )
  }
}

export default CreditPeriodInput
