import { SimpleCard } from '../../../../../matx'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import { accountEnums, defaultAccountConfiguration } from '../../../../services/APIClient'
import { Component } from 'react'
import { Container, Form } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css'
import BrazilBankInput from '../inputs/BrazilBankInput'
import GlobalBankInput from '../inputs/GlobalBankInput'
import DocumentInput from '../inputs/DocumentInput'
import ContactInput from '../inputs/ContactInput'
import PartnerInput from '../inputs/PartnerInput'
import CommentInput from '../inputs/CommentInput'
import FeeInput from '../inputs/FeeInput'
import WebhookInput from '../inputs/WebhookInput'
import MultipleItemsInput from '../inputs/MultipleItemsInput'
import { countryList } from '../../../../services/SelectEnumRepository'
import AddressSection from './AddressSection'
import MerchantInfoSection from './MerchantInfoSection'
import CreditPeriodInput from '../inputs/CreditPeriodInput'
import FxBankThatApprovedMerchantInput from '../inputs/FxBankThatApprovedMerchantInput'

class AccountUpdateForm extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = (event) => {
      this.setState({ isLoading: true })
      event.preventDefault()
      props.onSubmit().finally(() => {
        this.setState({ isLoading: false })
      })
    }
    this.propagateChange = props.onChange
  }

  componentDidMount() {
    if (this.state?.enums == null) {
      accountEnums().then((resp) => {
        const toEnumSelector = (items) => items.map((item) => ({ value: item, label: item }))

        this.setState({
          enums: {
            installmentCategories: toEnumSelector(resp.installmentsCategory),
            accountTypes: toEnumSelector(resp.accountsType),
            productTypes: toEnumSelector(resp.productsType),
            webhooksType: toEnumSelector(resp.webhooksType),
            documentsType: toEnumSelector(resp.documentsType),
            feeSelector: toEnumSelector(resp.feeSelector),
            feeCostTypes: toEnumSelector(resp.feeCostType),
            feeCategoryTypes: toEnumSelector(resp.feeCategory),
            feeFlowTypes: toEnumSelector(resp.feeFlowType),
            currencyTypes: toEnumSelector(resp.currency),
            businessLines: toEnumSelector(resp.businessLine),
            sectors: toEnumSelector(resp.sectors),
            accountingEntryTypes: toEnumSelector(resp.accountingEntryType),
            paymentMethods: toEnumSelector(resp.paymentsMethod),
            brand: toEnumSelector(resp.brand),
            fxBanks: toEnumSelector(resp.fxBanks),
          },
        })
      })
    }
  }

  findSelectedEnum(selectedField, allEnums) {
    return allEnums.find(({ value }) => value === selectedField)
  }

  chooseByRadiobutton() {
    const bankInfo = this.props.accountInfo.bank
    const value = bankInfo.type

    if (value === 'BRAZIL') {
      return <BrazilBankInput onChange={(item) => this.onChangeBank(item, null)} item={bankInfo.brazil} />
    } else if (value === 'GLOBAL') {
      return <GlobalBankInput onChange={(item) => this.onChangeBank(null, item)} item={bankInfo.global} />
    } else return <React.Fragment />
  }

  onChange(type, items) {
    const content = this.onChangeNested([].concat(type), items)
    this.propagateChange(content)
  }

  onChangeMultiple(items) {
    const content = items.reduce((acc, { levels, items }) => this.onChangeNested(levels, items, acc), undefined)

    this.propagateChange(content)
  }

  onChangeNested(levels, items, current = this.props.accountInfo) {
    return this.applyChanges(levels, items, current)
  }

  applyChanges(levels, items, current) {
    if (levels.length === 0) return items

    const level = levels[0]
    const remaining = levels.slice(1)

    return { ...current, [level]: this.applyChanges(remaining, items, current[level]) }
  }

  onChangeCountry = (event) => {
    const countryCode = event.value
    var bankType

    if (countryCode === '076') {
      bankType = 'BRAZIL'
    } else if (countryCode === '156') {
      bankType = 'GLOBAL'
    }

    this.onChangeMultiple([
      { levels: ['address', 'countryCode'], items: countryCode },
      { levels: ['bank', 'type'], items: bankType },
    ])
  }

  onChangeBank = (brazilBank, globalBank) => {
    this.onChangeMultiple([
      { levels: ['bank', 'brazil'], items: brazilBank },
      { levels: ['bank', 'global'], items: globalBank },
    ])
  }

  onUpdateAccountInfo = (event) => {
    this.onChange(event.target.name, event.target.value || event.value)
  }

  onUpdateAddress = (event) => {
    this.onChange(['address', event.target.name], event.target.value || event.value)
  }

  onSettlementCurrencyChange = (event) => {
    const newValidFeeCurrency = ['BRL', event.value]
    const hasDifferentCurrency = this.props.accountInfo.fees.some((fee) => {
      if (fee == null) {
        return false
      }
      const hasInvalifFeeCurrency = fee.feeCurrency != null && !newValidFeeCurrency.includes(fee.feeCurrency)
      const hasInvalidMinimumFeeCurrency =
        fee.minimumFeeCurrency != null && !newValidFeeCurrency.includes(fee.minimumFeeCurrency)
      return hasInvalifFeeCurrency || hasInvalidMinimumFeeCurrency
    })
    if (hasDifferentCurrency) {
      alert(`${event.value} is a invalid value because there is fees with another currency`)
      return
    }
    this.onChange('settlementCurrency', event.value)
  }

  onFxBanksThatApprovedMerchantChange = (event) => {
    this.onChange(
      'fxBanksThatApprovedMerchant',
      event.map((event) => event.value),
    )
  }

  onBusinessLineChange = async (event) => {
    const businessLine = event.value
    if (!this.props.isEdit) {
      const defaultConfiguration = await defaultAccountConfiguration(businessLine)
      const defaultCreditPeriods = defaultConfiguration.creditPeriods.map((creditPeriod) => {
        return { removable: true, ...creditPeriod }
      })
      const defaultFees = defaultConfiguration.fees.map((fee) => {
        return { removable: true, active: true, ...fee }
      })
      let defaultCountryCode = null
      let defaultBankType = null
      let defaultGlobalBankAccount = null

      if (defaultConfiguration.countryCode != null) {
        defaultCountryCode = defaultConfiguration.countryCode
        defaultBankType = defaultConfiguration.countryCode === '156' ? 'GLOBAL' : null
        defaultGlobalBankAccount = defaultConfiguration.globalBankAccount
      }
      this.onChangeMultiple([
        { levels: ['businessLine'], items: businessLine },
        { levels: ['settlementPeriods'], items: defaultCreditPeriods },
        { levels: ['fees'], items: defaultFees },
        { levels: ['address', 'countryCode'], items: defaultCountryCode },
        { levels: ['bank', 'type'], items: defaultBankType },
        { levels: ['bank', 'brazil'], items: null },
        { levels: ['bank', 'global'], items: defaultGlobalBankAccount },
      ])
    }
  }

  render() {
    if (this.props?.accountInfo == null || this.state?.enums == null) return <React.Fragment />

    return (
      <div>
        <SimpleCard title="Account register">
          <Form onSubmit={this.onSubmit}>
            <Container>
              <BTable className="center">
                <tbody>
                  <MerchantInfoSection
                    accountTypes={this.state.enums.accountTypes}
                    productTypes={this.state.enums.productTypes}
                    businessLines={this.state.enums.businessLines}
                    accountInfo={this.props.accountInfo}
                    onAccountTypeChange={(event) => this.onChange('type', event.value)}
                    onBusinessLineChange={this.onBusinessLineChange}
                    onProductTypeChange={(event) => this.onChange('productType', event.value)}
                    onUpdateAccountInfo={this.onUpdateAccountInfo}
                    currencyTypes={this.state.enums.currencyTypes}
                    onSettlementCurrencyChange={(event) => this.onSettlementCurrencyChange(event)}
                    fxBanks={this.state.enums.fxBanks}
                    isEdit={this.props.isEdit}
                  />

                  <AddressSection
                    countryList={countryList}
                    countryCode={this.props.accountInfo.address.countryCode}
                    state={this.props.accountInfo.address.state}
                    city={this.props.accountInfo.address.city}
                    zipCode={this.props.accountInfo.address.zipCode}
                    street={this.props.accountInfo.address.street}
                    complement={this.props.accountInfo.address.complement}
                    number={this.props.accountInfo.address.number}
                    neighbourhood={this.props.accountInfo.address.neighbourhood}
                    onChangeCountry={this.onChangeCountry}
                    onUpdateAddress={this.onUpdateAddress}
                  />
                </tbody>
              </BTable>
            </Container>
            <div> {this.chooseByRadiobutton()} </div>

            <MultipleItemsInput
              items={this.props.accountInfo.documents}
              title="Document"
              onChange={(documents) => this.onChange('documents', documents)}
            >
              <DocumentInput documentTypes={this.state.enums.documentsType} />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.fxBanksThatApprovedMerchant}
              title="FX bank that approved merchant"
              onChange={(fxBanksThatApprovedMerchant) =>
                this.onChange('fxBanksThatApprovedMerchant', fxBanksThatApprovedMerchant)
              }
            >
              <FxBankThatApprovedMerchantInput fxBanks={this.state.enums.fxBanks} />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.settlementPeriods}
              title="Credit Period"
              onChange={(settlementPeriods) => this.onChange('settlementPeriods', settlementPeriods)}
            >
              <CreditPeriodInput
                accountingEntryTypes={this.state.enums.accountingEntryTypes}
                paymentMethods={this.state.enums.paymentMethods}
              />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.fees}
              title="Fee"
              onChange={(fees) => this.onChange('fees', fees)}
            >
              <FeeInput
                installmentCategories={this.state.enums.installmentCategories}
                feeSelector={this.state.enums.feeSelector}
                feeCostTypes={this.state.enums.feeCostTypes}
                feeCategoryTypes={this.state.enums.feeCategoryTypes}
                feeFlowTypes={this.state.enums.feeFlowTypes}
                currencyTypes={this.state.enums.currencyTypes}
                accountInfo={this.props.accountInfo}
                brandTypes={this.state.enums.brand}
              />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.webhooks}
              title="Webhook"
              onChange={(webhooks) => this.onChange('webhooks', webhooks)}
            >
              <WebhookInput webhookTypes={this.state.enums.webhooksType} />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.contacts}
              title="Contact"
              onChange={(contacts) => this.onChange('contacts', contacts)}
            >
              <ContactInput sectors={this.state.enums.sectors} />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.ubos}
              title="UBO"
              onChange={(ubos) => this.onChange('ubos', ubos)}
            >
              <PartnerInput documentTypes={this.state.enums.documentsType} showParticipation={false} />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.partners}
              title="Partner"
              onChange={(partners) => this.onChange('partners', partners)}
            >
              <PartnerInput documentTypes={this.state.enums.documentsType} showParticipation={true} />
            </MultipleItemsInput>

            <MultipleItemsInput
              items={this.props.accountInfo.comments}
              title="Comment"
              onChange={(comment) => this.onChange('comments', comment)}
            >
              <CommentInput />
            </MultipleItemsInput>
            <div>
              <button type="submit" className="btn-submit center" disabled={this.state.isLoading}>
                Registrar
              </button>
            </div>
          </Form>
        </SimpleCard>
      </div>
    )
  }
}

export default AccountUpdateForm
