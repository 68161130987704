import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import Select from 'react-select'

export default function IdentityProviderChargeInsert({ item, onChange, queryTypes }) {
  const [chargeBeingInserted, setChargeBeingInserted] = useState({
    tierMin: null,
    tierMax: null,
    chargeValue: null,
    queryType: null,
    serviceType: 'IDENTITY',
    ...item,
  })

  const onChangeProperty = (propertyName, value) => {
    chargeBeingInserted[propertyName] = value
    setChargeBeingInserted({ ...chargeBeingInserted })
    onChange(chargeBeingInserted)
  }

  return (
    <>
      <Container>
        <BTable className="center">
          <tbody>
            <tr>
              <td className="column-table">Charge value:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="chargeValue"
                  onChange={(event) => onChangeProperty('chargeValue', event.target.value)}
                  id="chargeValue"
                  type="number"
                  placeholder="charge value"
                  defaultValue={chargeBeingInserted.chargeValue}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Tier Minimum:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="tierMin"
                  onChange={(event) => onChangeProperty('tierMin', event.target.value)}
                  id="tierMin"
                  type="number"
                  placeholder="tier minimum value"
                  defaultValue={chargeBeingInserted.tierMin}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Tier Maximum:</td>
              <td>
                <input
                  className="accounting-register-input-text"
                  name="tierMax"
                  onChange={(event) => onChangeProperty('tierMax', event.target.value)}
                  id="tierMax"
                  type="number"
                  placeholder="tier maximum value"
                  defaultValue={chargeBeingInserted.tierMax}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="column-table">Query type:</td>
              <td>
                <Select
                  name="queryType"
                  id="queryType"
                  onChange={(event) => onChangeProperty('queryType', event.value)}
                  options={queryTypes}
                  placeholder="query type"
                  value={queryTypes.filter((option) => option.value === chargeBeingInserted.queryType)}
                  required
                />
              </td>
            </tr>
          </tbody>
        </BTable>
      </Container>
    </>
  )
}
