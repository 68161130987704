import React from 'react'
import { Breadcrumb } from '../../../matx'
import { withRouter } from '../../../utils'
import CustomerDetails from './CustomerDetails'
import { useParams } from 'react-router-dom'

function CustomerDetailsPage(props) {
  const { id } = useParams()

  return (
    <div className="m-sm-30">
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: 'Customers', path: '/customers' }, { name: 'Details' }]} />
      </div>
      <CustomerDetails id={id} />
    </div>
  )
}

export default withRouter(CustomerDetailsPage)
