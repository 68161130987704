import React, { useState } from 'react'
import { createCsv } from '../../../services/APIClient'
import { MenuItem, Select } from '@mui/material'

export default function ReconciliationCsv() {
  const [businessLineReport, setBusinessLineReport] = useState('GENERAL')

  const handleDownloadClick = (event) => {
    var startDate = document.getElementById('startDate').value
    var endDate = document.getElementById('endDate').value
    createCsv(startDate, endDate, businessLineReport)
  }

  return (
    <div className="card-modal-report">
      <div style={{ paddingLeft: '30px' }}>
        <div>
          <label>Reconciliation V0 - All transactions with any status in the selected period</label>
        </div>
        <div className="row">
          <div className="col-md-3 col-3">
            <Select
              value={businessLineReport}
              name="isSailor"
              id="isSailor"
              onChange={(e) => setBusinessLineReport(e.target.value)}
              options={['GENERAL', 'SAILOR']}
              className="select"
            >
              <MenuItem value="GENERAL">Not sailor</MenuItem>
              <MenuItem value="SAILOR">Sailor</MenuItem>
            </Select>
          </div>
          <div className="col-md-3 col-3">
            <label className="labelCalendarDistance">Initial date:</label>
            <input type="date" id="startDate" name="startDate" />
          </div>
          <div className="col-md-3 col-3">
            <label className="labelCalendarDistance">End date:</label>
            <input type="date" id="endDate" name="endDate" />
          </div>
          <div className="col-md-3 col-3">
            <button className="buttonSendDate" onClick={handleDownloadClick}>
              Download csv
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
