import PayeeMerchantTableContent from './PayeeMerchantTableContent'
import { payeePagination } from '../../services/APIClient'
import PaginationTable from '../../../matx/components/PaginationTable'

function PayeeMerchantPaginationTable() {
  return (
    <PaginationTable
      queryItems={payeePagination}
      tableName="Payee merchants"
      tableContent={(filteredItems) => <PayeeMerchantTableContent items={filteredItems} />}
      attributesToFilter={['merchantSoftDescriptor', 'name']}
    />
  )
}

export default PayeeMerchantPaginationTable
