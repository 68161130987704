import Select from 'react-select'
import React from 'react'
import { Component } from 'react'
import { Container } from 'react-bootstrap'
import BTable from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css'

const chargebackFeeFilterTypes = [
  { value: 'ONLY_DISPUTED', label: 'ONLY_DISPUTED' },
  { value: 'ALL', label: 'ALL' },
]

const chargeTypes = [
  { value: 'BY_INSTALLMENT', label: 'BY_INSTALLMENT' },
  { value: 'BY_TRANSACTION', label: 'BY_TRANSACTION' },
]

class FeeInput extends Component {
  constructor(props) {
    super(props)

    this.state = { active: true, isTax: false, ...props.item }
    this.index = props.index
  }

  onAnyChanged = (change) => {
    const { onChange } = this.props
    this.setState((oldState) => {
      const newState = { ...oldState, ...change }
      onChange(newState)
      return newState
    })
  }

  onChangeProperty = (property, value) => {
    this.onAnyChanged({ [property]: value })
  }

  onChangeChargeback = (property, value) => {
    this.onAnyChanged({ chargeback: { ...this.state.chargeback, [property]: value } })
  }

  onUpdateFeeCurrency = (property, value) => {
    const settlementCurrency = this.props.accountInfo.settlementCurrency
    if (!settlementCurrency) {
      alert(`Please fill the settlement currency first!`)
      return
    }

    const validFeeCurrencies = ['BRL', settlementCurrency]
    if (!validFeeCurrencies.includes(value)) {
      alert(`${value} is a invalid fee currency because it is different from BRL and settlement currency!`)
      return
    }
    this.onChangeProperty(property, value)
  }

  onFeeCostTypeChange = (newValue) => {
    if (newValue === 'RATE') {
      this.onChangeProperty('feeCurrency', null)
    } else if (newValue === 'FIXED') {
      this.onChangeProperty('minimumFixedFeeCurrency', null)
      this.onChangeProperty('minimumFixedFee', null)
    }
    this.onChangeProperty('feeCostType', newValue)
  }

  render() {
    const {
      feeSelector,
      currencyTypes,
      feeCostTypes,
      installmentCategories,
      feeCategoryTypes,
      feeFlowTypes,
      brandTypes,
    } = this.props

    const shouldHaveMinimumFee = this.state.feeCostType === 'RATE'

    return (
      <>
        <Container>
          <BTable className="center">
            <tbody>
              <tr>
                <td className="column-table">Fee Selector:</td>
                <td>
                  <Select
                    defaultValue={[]}
                    name="feeSelector"
                    className="select-register-input"
                    onChange={(event) => this.onChangeProperty('feeSelector', event.value)}
                    options={feeSelector}
                    classNamePrefix="feeSelector"
                    placeholder="Fee Selector"
                    value={feeSelector.filter((option) => option.value === this.state.feeSelector)}
                    isDisabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Fee Category:</td>
                <td>
                  <Select
                    defaultValue={[]}
                    name="feeCategory"
                    className="select-register-input"
                    options={feeCategoryTypes}
                    onChange={(event) => this.onChangeProperty('feeCategory', event.value)}
                    classNamePrefix="feeCategory"
                    placeholder="Fee Category"
                    value={feeCategoryTypes.filter((option) => option.value === this.state.feeCategory)}
                    isDisabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Fee Flow Type:</td>
                <td>
                  <Select
                    defaultValue={[]}
                    name="flowType"
                    className="select-register-input"
                    options={feeFlowTypes}
                    onChange={(event) => this.onChangeProperty('flowType', event.value)}
                    classNamePrefix="flowType"
                    placeholder="Fee Flow Type"
                    value={feeFlowTypes.filter((option) => option.value === this.state.flowType)}
                    isDisabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Fee Value:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="feeValue"
                    onChange={(event) => this.onChangeProperty('fee', event.target.value)}
                    step="any"
                    id="fee"
                    type="number"
                    placeholder="Fee Value"
                    defaultValue={this.state.fee}
                    disabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">Fee Cost Type:</td>
                <td>
                  <Select
                    defaultValue={[]}
                    name="feeCostType"
                    className="select-register-input"
                    options={feeCostTypes}
                    onChange={(event) => this.onFeeCostTypeChange(event.value)}
                    classNamePrefix="feeCostType"
                    placeholder="Fee Cost Type"
                    value={feeCostTypes.filter((option) => option.value === this.state.feeCostType)}
                    isDisabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>

              {this.state.feeSelector === 'CREDIT_CARD' && this.state.feeCategory !== 'GUARANTEE_DEPOSIT' ? (
                <tr>
                  <td className="column-table">Installment Category:</td>
                  <td>
                    <Select
                      defaultValue={[]}
                      name="installmentCategory"
                      className="select-register-input"
                      options={installmentCategories}
                      onChange={(event) => this.onAnyChanged({ creditCard: { installmentsCategory: event.value } })}
                      classNamePrefix="installmentCategory"
                      value={installmentCategories.filter(
                        (option) => option.value === this.state.creditCard?.installmentsCategory,
                      )}
                      isDisabled={!this.state.removable}
                      required
                    />
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {this.state.feeCategory === 'GUARANTEE_DEPOSIT' ? (
                <tr>
                  <td className="column-table">Days until expiration:</td>
                  <td>
                    <input
                      className="account-register-input-text"
                      name="daysUntilExpiration"
                      onChange={(event) =>
                        this.onAnyChanged({ guaranteeDeposit: { daysUntilExpiration: event.target.value } })
                      }
                      id="daysUntilExpiration"
                      type="number"
                      placeholder="Days until expiration"
                      defaultValue={this.state.guaranteeDeposit?.daysUntilExpiration}
                      disabled={!this.state.removable}
                      required
                    />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {this.state.feeSelector === 'CREDIT_CARD_CHARGEBACK' && this.state.feeCategory === 'CHARGEBACK' ? (
                <>
                  <tr>
                    <td className="column-table">Filter type:</td>
                    <td>
                      <Select
                        defaultValue={[]}
                        name="activeOn"
                        className="select-register-input"
                        options={chargebackFeeFilterTypes}
                        onChange={(event) => this.onChangeChargeback('activeOn', event.value)}
                        classNamePrefix="activeOn"
                        value={chargebackFeeFilterTypes.filter(
                          (option) => option.value === this.state.chargeback?.activeOn,
                        )}
                        isDisabled={!this.state.removable}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="column-table">Charge type:</td>
                    <td>
                      <Select
                        defaultValue={[]}
                        name="chargeType"
                        className="select-register-input"
                        options={chargeTypes}
                        onChange={(event) => this.onChangeChargeback('chargeType', event.value)}
                        classNamePrefix="chargeType"
                        value={chargeTypes.filter((option) => option.value === this.state.chargeback?.chargeType)}
                        isDisabled={!this.state.removable}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="column-table">Brand:</td>
                    <td>
                      <Select
                        defaultValue={[]}
                        name="brand"
                        className="select-register-input"
                        options={brandTypes}
                        onChange={(event) => this.onChangeChargeback('brand', event.value)}
                        classNamePrefix="chargeType"
                        value={brandTypes.filter((option) => option.value === this.state.chargeback?.brand)}
                        isDisabled={!this.state.removable}
                        required
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}

              {this.state.feeCostType === 'FIXED' ? (
                <tr>
                  <td className="column-table">Fee Currency:</td>
                  <td>
                    <Select
                      defaultValue={[]}
                      name="feeCurrency"
                      className="select-register-input"
                      onChange={(event) => this.onUpdateFeeCurrency('feeCurrency', event.value)}
                      step="any"
                      id="feeCurrency"
                      type="text"
                      placeholder="Fee Currency"
                      options={currencyTypes}
                      value={currencyTypes.filter((option) => option.value === this.state.feeCurrency)}
                      isDisabled={!this.state.removable}
                      required
                    />
                  </td>
                </tr>
              ) : (
                <></>
              )}

              {this.state.feeCategory !== 'GUARANTEE_DEPOSIT' && shouldHaveMinimumFee ? (
                <>
                  <tr>
                    <td className="column-table">Minimum Fee Value:</td>
                    <td>
                      <input
                        className="account-register-input-text"
                        name="minimumFixedFee"
                        onChange={(event) => this.onChangeProperty('minimumFixedFee', event.target.value)}
                        step="any"
                        id="fee"
                        type="minimumFeeValue"
                        placeholder="Minimum Fee Value"
                        defaultValue={this.state.minimumFixedFee}
                        disabled={!this.state.removable}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="column-table">Minimum Fee Currency:</td>
                    <td>
                      <Select
                        defaultValue={[]}
                        className="select-register-input"
                        name="minimumFixedFeeCurrency"
                        onChange={(event) => this.onUpdateFeeCurrency('minimumFixedFeeCurrency', event.value)}
                        step="any"
                        id="fee"
                        type="minimumFeeCurrency"
                        placeholder="Minimum Fee Currency"
                        options={currencyTypes}
                        value={currencyTypes.filter((option) => option.value === this.state.minimumFixedFeeCurrency)}
                        isDisabled={!this.state.removable}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <></>
              )}

              <tr>
                <td className="column-table">Effective date:</td>
                <td>
                  <input
                    className="account-register-input-text"
                    name="effectiveDate"
                    onChange={(event) => this.onChangeProperty('effectiveDate', event.target.value)}
                    step="any"
                    id="effectiveDate"
                    type="date"
                    placeholder="Effective Date"
                    defaultValue={this.state.effectiveDate}
                    disabled={!this.state.removable}
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="column-table">
                  Active:
                  <div>
                    <input
                      type="radio"
                      value="true"
                      name={`active-${this.index}`}
                      defaultChecked={this.state.active}
                      disabled={!this.state.removable}
                      onChange={(event) => this.onChangeProperty('active', event.target.value)}
                      required
                    />{' '}
                    Yes
                    <input
                      type="radio"
                      value="false"
                      name={`active-${this.index}`}
                      defaultChecked={!this.state.active}
                      disabled={!this.state.removable}
                      onChange={(event) => this.onChangeProperty('active', event.target.value)}
                      required
                    />{' '}
                    No
                  </div>
                </td>
              </tr>
              <tr>
                <td className="column-table">
                  Is tax:
                  <div>
                    <input
                      type="radio"
                      value="true"
                      name={`isTax-${this.index}`}
                      defaultChecked={this.state.isTax}
                      disabled={!this.state.removable}
                      onChange={(event) => this.onChangeProperty('isTax', event.target.value)}
                      required
                    />{' '}
                    Yes
                    <input
                      type="radio"
                      value="false"
                      name={`isTax-${this.index}`}
                      defaultChecked={!this.state.isTax}
                      disabled={!this.state.removable}
                      onChange={(event) => this.onChangeProperty('isTax', event.target.value)}
                      required
                    />{' '}
                    No
                  </div>
                </td>
              </tr>
              <tr>
                <td className="column-table">Comment:</td>
                <td>
                  <input
                    className="account-register-input-text-area"
                    name="comment"
                    onChange={(event) => this.onChangeProperty('comment', event.target.value)}
                    step="any"
                    id="comment"
                    type="text"
                    placeholder="Comment"
                    defaultValue={this.state.comment}
                    disabled={!this.state.removable}
                  />
                </td>
              </tr>
            </tbody>
          </BTable>
        </Container>
      </>
    )
  }
}

export default FeeInput
