import { SimpleCard } from '../../../../matx'
import PayinTransactionTableContent from './PayinTransactionTableContent'
import { transactionPagination } from '../../../services/APIClient'
import PaginationTable from '../../../../matx/components/PaginationTable'

function TransactionTable() {
  return (
    <div className="m-sm-30">
      <div className="py-12" />
      <SimpleCard>
        <PaginationTable
          queryItems={transactionPagination}
          tableName="Payin Transactions"
          tableContent={(filteredItems) => <PayinTransactionTableContent items={filteredItems} />}
          attributesToFilter={['merchantSoftDescriptor', 'paymentMethod', 'customerName', 'status']}
        />
      </SimpleCard>
    </div>
  )
}

export default TransactionTable
