import SettlementBatchPage from './batch/SettlementBatchPage'
import SettlementDetailsPage from './SettlementDetailsPage'
import { SettlementFile } from './SettlementFile'
import Settlements from './Settlements'

const SettlementRoutes = [
  {
    path: '/settlements',
    element: <Settlements />,
  },
  {
    path: '/settlements/:id',
    element: <SettlementDetailsPage />,
  },
  {
    path: '/settlement-file',
    element: <SettlementFile />,
  },
  {
    path: '/settlement-batch',
    element: <SettlementBatchPage />,
  },
]

export default SettlementRoutes
