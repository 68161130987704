import { TableRow, TableCell } from '@mui/material'
import { Component } from 'react'
import { withNavigate } from '../../../utils'

class ProviderTableRow extends Component {
  handleClick = (_) => {
    this.props.navigate(`/providers/${this.props.item.id}`)
  }

  render() {
    let { item, index } = this.props
    return (
      <TableRow key={index}>
        <TableCell key={item.id} className="px-0 capitalize" align="left" onClick={this.handleClick}>
          {item.id}
        </TableCell>
        <TableCell key={`${item.id}-${item.name}`} className="px-0 capitalize" align="left" onClick={this.handleClick}>
          {item.name}
        </TableCell>
        <TableCell key={`${item.id}-${item.type}`} className="px-0 capitalize" align="left" onClick={this.handleClick}>
          {item.serviceType}
        </TableCell>
      </TableRow>
    )
  }
}

export default withNavigate(ProviderTableRow)
